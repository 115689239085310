import {StyleSheet} from 'react-native';
import React from 'react';
import { View, Image } from 'react-native';

import useCardSize from '../../hooks/useCardSize';
import { ImageMetadata } from '../../types/types';
import { ImageOverlay } from '../ImageOverlay';

export default function EventCardImage (props: {
  imageInstance: ImageMetadata,
  detailMode: Boolean,
}) {

  const borderRadius = 16;

  const styles = StyleSheet.create({
    container: {
      overflow: 'hidden',
      borderRadius: props.detailMode? 0 : borderRadius,
    },
    image: {
    },
  });

  function getRenderVariables () {

    const cardSize = useCardSize(props.imageInstance.width, props.imageInstance.height, !props.detailMode);

    const containerStyle = [
      styles.container,
      {
        width: cardSize.cardWidth,
        height: cardSize.cardHeight,
      },
    ];

    const imageStyle = [
      styles.image,
      {
        width: cardSize.cardWidth,
        height: cardSize.cardHeight,
      },
    ];

    // components
    const components = [(
      <Image
        key="image"
        source={props.imageInstance.uri!='' ? {uri: props.imageInstance.uri} : require('../../assets/images/empty_image.jpg')}
        style={imageStyle}
        resizeMode="cover"
      />
    )];

    components.push(
      <ImageOverlay
        key='overlay'
        width={cardSize.cardWidth}
        height={cardSize.cardHeight}
        borderRadius={props.detailMode? 0 : borderRadius}
      />
    );

    return {
      containerStyle,
      components,
    };
  }

  const {
    containerStyle,
    components,
  } = getRenderVariables();

  return (
    <View style={containerStyle}>
      {components}
    </View>
  );
};
