import { FlatList, StyleSheet } from 'react-native';
import { useContext, useEffect, useState } from 'react';

import { AppContext } from "../../contexts";
import axios from 'axios';
import { getPath, path } from '../../env/env';
import usePlatform, { imagePadding } from '../../hooks/usePlatform';
import { PostType } from '../../types/types';
import { BusinessListCard } from './BusinessListCard';
import { BusinessActionType, BusinessStateType } from '../../contexts/reducers/businessListReducer';
import { BusinessListRefreshActionType } from '../../contexts/reducers/businessListRefreshReducer';
import { SearchHeader } from '../SearchHeader';

export default function BusinessList (props: {focusOnUuid: string}) {
  const { state, dispatch } = useContext(AppContext);
  const [ page, setPage ] = useState(0);
  const [ query, setQuery ] = useState('');
  const [ searchActive, setSearchActive ] = useState(false);

  const styles = StyleSheet.create({
    listContent: {
      paddingHorizontal: imagePadding,
      width: usePlatform().windowWidth,
    },
  });

  const pageSize = 0; // 0 = use server defaults

  const type = PostType.BUSINESS;

  const keyExtractor = (item: BusinessStateType) => `BusinessCard-${item.uuid}`;
  const renderItem = ({ item }: { item: BusinessStateType }) => <BusinessListCard item={item} focus={item.uuid==props.focusOnUuid?true:false} />;
  
  const path = getPath(type);

  function refresh () {
    setPage(0);
  };

  function nextPage () {
    setPage(page => page+1);
  };

  useEffect(
    () => {
      const queryPath = searchActive ? path+'/search/'+query : path;

      dispatch({
        type: BusinessListRefreshActionType.BUSINESS_REFRESH,
        payload: null,
      });
      axios({
        method: 'GET',
        url: queryPath,
        params: {
          page: page,
          ...(pageSize>0 && {pagesize: pageSize}), // only add when not 0, careful with casing
        },
      })
      .then(function (response) {
        if (response.data.items) {
          dispatch({
            type: page==0 ? BusinessActionType.BUSINESS_FETCHED_ON_MOUNT : BusinessActionType.BUSINESS_FETCHED,
            payload: response.data.items,
          });
        } else {
          console.log('No more found');
        }
      })
      .catch(function (error) {
        console.log('Error fetching with error: ');
        console.log(error);
      })
      .then(function () {
        // always executed
        dispatch({
          type: BusinessListRefreshActionType.BUSINESS_REFRESHED,
          payload: null,
        });
      });
    }, [page, query],
  );

  return (
    <FlatList
      data={state.businessList}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      pinchGestureEnabled={false}
      contentContainerStyle={styles.listContent}
      onRefresh={refresh}
      refreshing={state.businessListRefresh.refreshing}
      onEndReached={nextPage}
      onEndReachedThreshold={1}
      ListHeaderComponent={<SearchHeader type={type} setPage={setPage} searchActive={searchActive} setSearchActive={setSearchActive} query={query} setQuery={setQuery} />}
    />
  );
};
