import {StyleSheet} from 'react-native';
import Svg, {
  Stop,
  Text as SvgText,
  LinearGradient as SvgLinearGradient,
} from 'react-native-svg';

import {LogoColors} from './Themed';

export function Logo() {
  return (
    <Svg
      height="100"
      width="300"
      style={styles.alignSelfCenter}>
      <SvgLinearGradient
        id="gradientLogo"
        x1="0%"
        y1="0%"
        x2="100%"
        y2="60%">
        <Stop
          offset="0"
          stopColor={LogoColors().left}
          stopOpacity="1"
        />
        <Stop
          offset="1"
          stopColor={LogoColors().right}
          stopOpacity="1"
        />
      </SvgLinearGradient>
      <SvgText
        fontSize='90px'
        fontFamily='Heebo Bold'
        fontWeight='900'
        textAnchor="middle"
        fill="url(#gradientLogo)"
        x="50%"
        y="70%">
        GRILL
      </SvgText>
    </Svg>
  );
};

const styles = StyleSheet.create({
  alignSelfCenter: {
    alignSelf: 'center'
  },
});
