import { Text, TextMuted, TextProps } from './Themed';

export function HeeboRegularText(props: TextProps) {
  return <Text {...props} style={[props.style, { fontFamily: 'Heebo Regular', fontSize: 18 }]} />;
}

export function HeeboBoldText(props: TextProps) {
  return <Text {...props} style={[props.style, { fontFamily: 'Heebo Bold', fontSize: 18 }]} />;
}

export function HeeboRegularTextBig(props: TextProps) {
  return <Text {...props} style={[props.style, { fontFamily: 'Heebo Regular', fontSize: 18 }]} />;
}

export function HeeboRegularTextSmall(props: TextProps) {
  return <Text {...props} style={[props.style, { fontFamily: 'Heebo Regular', fontSize: 14 }]} />;
}

export function HeeboRegularTextTiny(props: TextProps) {
  return <Text {...props} style={[props.style, { fontFamily: 'Heebo Regular', fontSize: 11 }]} />;
}

export function HeeboRegularTextLarge(props: TextProps) {
  return <Text {...props} style={[props.style, { fontFamily: 'Heebo Regular', fontSize: 20 }]} />;
}

export function HeeboRegularTextHuge(props: TextProps) {
  return <Text {...props} style={[props.style, { fontFamily: 'Heebo Regular', fontSize: 30 }]} />;
}

export function HeeboBoldTextLarge(props: TextProps) {
  return <Text {...props} style={[props.style, { fontFamily: 'Heebo Bold', fontSize: 24 }]} />;
}

export function HeeboBoldTextSmall(props: TextProps) {
  return <Text {...props} style={[props.style, { fontFamily: 'Heebo Bold', fontSize: 14 }]} />;
}

export function HeeboBoldTextTiny(props: TextProps) {
  return <Text {...props} style={[props.style, { fontFamily: 'Heebo Bold', fontSize: 11 }]} />;
}

export function HeeboBoldTextHuge(props: TextProps) {
  return <Text {...props} style={[props.style, { fontFamily: 'Heebo Bold', fontSize: 30 }]} />;
}

export function HeeboRegularTextMuted(props: TextProps) {
  return <TextMuted {...props} style={[props.style, { fontFamily: 'Heebo Regular' }]} />;
}

export function HeeboBoldTextMuted(props: TextProps) {
  return <TextMuted {...props} style={[props.style, { fontFamily: 'Heebo Bold' }]} />;
}

export function SignPainterBoldText(props: TextProps) {
  return <Text {...props} style={[props.style, { fontFamily: 'SignPainter Regular', fontSize: 30, fontWeight: 'bold' }]} />;
}

export function LobsterRegularText(props: TextProps) {
  return <Text {...props} style={[props.style, { fontFamily: 'Lobster-Regular', fontSize: 30 }]} />;
}
