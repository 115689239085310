import { Animated } from "react-native";
import { PostStateType, PostDetailCardInfoType } from "./postListReducer";

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      }
};

export type PostDetailStateType = {
  isImageLoaded: boolean,
  item: PostStateType | null,
  info: PostDetailCardInfoType | null | undefined,
};

export type PostDetailShowedType = {
  isImageLoaded: boolean,
  item: PostStateType | null,
  info: PostDetailCardInfoType | null | undefined,
};

export const initialPostDetailState: PostDetailStateType = {
  isImageLoaded: false,
  item: null,
  info: null,
};

export enum PostDetailActionType {
  POST_HIDE = 'POST_HIDE',
  POST_SHOWED = 'POST_SHOWED',
  POST_SET_IS_IMAGE_LOADED = 'POST_SET_IS_IMAGE_LOADED',
}

type PostDetailActionPayload = {
  [PostDetailActionType.POST_HIDE]: null;
  [PostDetailActionType.POST_SHOWED]: PostDetailShowedType;
  [PostDetailActionType.POST_SET_IS_IMAGE_LOADED]: boolean;
};

export type PostDetailAction = ActionMap<PostDetailActionPayload>[keyof ActionMap<
  PostDetailActionPayload
>];

export const postDetailReducer = (
  state: PostDetailStateType,
  action: PostDetailAction
) => {
  switch (action.type) {
    case PostDetailActionType.POST_HIDE:
      return {...state, hasDimmedLayer: false, item: null};
    case PostDetailActionType.POST_SET_IS_IMAGE_LOADED:
      return {...state, isImageLoaded: action.payload};
    case PostDetailActionType.POST_SHOWED:
      return {...state, isImageLoaded: action.payload.isImageLoaded, item: action.payload.item, info: action.payload.info };
    default:
      return state;
  }
};
