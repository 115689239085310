type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      }
};

export type UserStateType = {
  admin: boolean,
  fetched: boolean,
  firstName: string,
  lastName: string,
  following: string[],
  mutedUsers: string[],
  blockedUsers: string[],
  profileImage: string,
};

export const initialUserState: UserStateType = {
  admin: false,
  fetched: false,
  firstName: '',
  lastName: '',
  following: [],
  mutedUsers: [],
  blockedUsers: [],
  profileImage: '',
};

export enum UserActionType {
  SET_USER = 'SET_USER',
  CLEAR_USER = 'CLEAR_USER',
}

type UserActionPayload = {
  [UserActionType.SET_USER]: UserStateType;
  [UserActionType.CLEAR_USER]: UserStateType;
};

export type UserAction = ActionMap<UserActionPayload>[keyof ActionMap<
  UserActionPayload
>];

export const userReducer = (
  state: UserStateType,
  action: UserAction
) => {
  switch (action.type) {
    case UserActionType.SET_USER:
      return action.payload;
    case UserActionType.CLEAR_USER:
      return {...initialUserState};
    default:
      return state;
  }
};
