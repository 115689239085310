import { Alert, Platform, ScrollView, StyleSheet } from 'react-native';
import React, { useContext, useState } from 'react';
import AwesomeAlert from 'react-native-awesome-alerts';

import { HeeboBoldTextHuge, HeeboRegularText, HeeboRegularTextHuge, HeeboRegularTextMuted } from './StyledText';
import { AppContext } from '../contexts';
import { ColoredButton } from './ColoredButton';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { RootTabScreenProps } from '../types';
import { useNavigation } from '@react-navigation/native';
import { useThemeColor } from './Themed';
import { WhiteButton } from './WhiteButton';
import axios from 'axios';
import { getPath, grillImageServerUrl, path } from '../env/env';
import { AuthActionType, initialAuthState } from '../contexts/reducers/authReducer';
import { initialUserState, UserActionType } from '../contexts/reducers/userReducer';
import { PostType } from '../types/types';

export default function AuthUserDetails (props: { logOut: () => void }) {
  const { state, dispatch } = useContext(AppContext);
  const [ showAlert, setShowAlert ] = useState(false);
  
  const navigation = useNavigation<RootTabScreenProps<'Feed'>['navigation']>();

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    linkText: {
      color: useThemeColor({}, 'link'),
    },
    text: {
      textAlign: 'center',
      maxWidth: '75%',
    },
  });

  const deleteAccountRequest = () => {
    if (Platform.OS === 'web') {
      setShowAlert(true);
    } else {
      Alert.alert('Remove your account?', 'All your posts, images and user details will be deleted. This cannot be undone.', [
        { text: 'No', style: 'cancel', },
        { text: 'Yes', onPress: deleteAccount },
      ]);
    }
  };

  function deleteAccount () {
    if (!state.auth.uuid) return;
    
    // Delete images
    axios({
      method: 'DELETE',
      url: grillImageServerUrl+'/user/'+state.auth.uuid,
    })
    .then(function (response) {

      // Delete posts
      axios({
        method: 'DELETE',
        url: getPath(PostType.POST)+'/user/'+state.auth.uuid,
      })
      .then(function (response) {
  
        // Delete user
        axios({
          method: 'DELETE',
          url: path.users+'/'+state.auth.uuid,
        })
        .then(function (response) {
    
          // Delete user
          axios.defaults.headers.common['Authorization'] = '';
      
          dispatch({
            type: AuthActionType.LOGGED_OUT,
            payload: initialAuthState,
          });
      
          dispatch({
            type: UserActionType.CLEAR_USER,
            payload: initialUserState,
          });
        })
        .catch(function (error) {
          console.log(error.response.data.message);
        });
      })
      .catch(function (error) {
        console.log(error.response.data.message);
      });
    })
    .catch(function (error) {
      console.log(error.response.data.message);
    });
  };

  return (
    <SafeAreaProvider>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.container}
      >
        <HeeboRegularTextHuge>Hi {state.user.firstName}</HeeboRegularTextHuge>
        {state.user && state.user.admin && <HeeboBoldTextHuge>You are admin</HeeboBoldTextHuge>}
        <HeeboRegularText style={styles.text}>Thank your for joining GRILL! Feel free to share, like and comment with your friends in our classic car community.</HeeboRegularText>
        <ColoredButton onPress={() => navigation.replace('GRILL')} text='To the feed' />
        <WhiteButton onPress={props.logOut} text='Log out' />
        <WhiteButton onPress={deleteAccountRequest} text='Delete my account' />
        <HeeboRegularTextMuted style={styles.text}>If you have any questions or requests regarding your account and your&nbsp;
          <HeeboRegularTextMuted onPress={() => navigation.replace('PrivacyPolicy')} style={styles.linkText}>privacy policy</HeeboRegularTextMuted>
          &nbsp;or the&nbsp;
          <HeeboRegularTextMuted onPress={() => navigation.replace('TermsOfService')} style={styles.linkText}>terms of service</HeeboRegularTextMuted>
         , please contact info@grill-community.com</HeeboRegularTextMuted>
      </ScrollView>
      <AwesomeAlert
        show={showAlert}
        showProgress={false}
        title='Remove your account?'
        message='All your posts, images and user details will be deleted. This cannot be undone.'
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText='No'
        confirmText='Remove'
        confirmButtonColor='darkred'
        onCancelPressed={() => {
          setShowAlert(false);
        }}
        onConfirmPressed={() => {
          deleteAccount();
          setShowAlert(false);
        }}
      />
    </SafeAreaProvider>
  );
};