import { ActivityIndicator, StyleSheet, ScrollView, Switch, TouchableOpacity } from 'react-native';
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { CommonActions } from '@react-navigation/native';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import { AppContext } from "../contexts";
import { BackgroundGradient, Paragraph, Text, ThemedTextInput, useThemeColor, View } from '../components/Themed';
import { initialUserState, UserActionType } from '../contexts/reducers/userReducer';
import { AuthActionType, AuthStateType, initialAuthState } from '../contexts/reducers/authReducer';
import AuthUserDetails from '../components/AuthUserDetails';
import { HeeboBoldText, HeeboBoldTextLarge, HeeboRegularText, HeeboRegularTextLarge, HeeboRegularTextMuted } from '../components/StyledText';
import { WhiteButton } from '../components/WhiteButton';
import { LogoImage } from '../components/LogoImage';

import { RootStackScreenProps, RootTabScreenProps } from '../types';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { path } from '../env/env';

const AUTH_COMPONENT_STATE = {
  NONE: 'NONE',
  REGISTER: 'REGISTER',
  LOGIN: 'LOGIN',
  FORGOTPW: 'FORGOTPW',
};

export default function AuthScreen({ navigation, route }: RootStackScreenProps<'Profile'>) {
  const { state, dispatch } = useContext(AppContext);

  function loadStyles (Colors: any, colorScheme: any) {
    return StyleSheet.create({
      container: {
        flex: 1,
        alignItems: 'center',
      },
      linkText: {
        color: useThemeColor({}, 'linkinverse'),
      },
      inputText: {
      },
      centerText: {
        textAlign: 'center',
      },
    });
  }

  const colorScheme = useColorScheme();
  const styles = loadStyles(Colors, colorScheme);

  const [ authComponentState, setAuthComponentState ] = useState(AUTH_COMPONENT_STATE.NONE);

  const [ requestState, setRequestState ] = useState({
    loading: false,
    status: 0,
    message: '',
    showMessage: false,
  });
  
  const [ email, setEmail ] = useState(state.user.firstName);
  const [ password, setPassword ] = useState('');
  const [ firstName, setFirstName ] = useState(state.user.firstName);
  const [ lastName, setLastName ] = useState(state.user.lastName);
  const [ checked, setChecked] = useState(false);

  // Add back button when coming here directly (empty navigation history)
  useEffect(
    () => {
      if (navigation.getState().routes.length>1) return;

      navigation.dispatch(navigationState => {
        const currentNavigationState = navigationState; // This can also directly be accessed with navigation.getState()
    
        const routes = [{name: 'GRILL'}, ...currentNavigationState.routes];
    
        const newNavigationState = {
          ...navigationState,
          routes,
          index: routes.length - 1,
        };
      
        return CommonActions.reset(newNavigationState);
      });
    }, [],
  );

  if (state.auth.uuid) {
    return <AuthUserDetails logOut={logOut} />
  } else if (authComponentState == AUTH_COMPONENT_STATE.NONE) {
    return (
      <SafeAreaProvider>
        <BackgroundGradient />
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.container}
        >
          <LogoImage />
          <HeeboBoldTextLarge>Your GRILL account</HeeboBoldTextLarge>
          <HeeboRegularTextLarge>is ready for you</HeeboRegularTextLarge>
          <Paragraph>
            <HeeboRegularText style={styles.centerText}>
              Join the GRILL community, share updates on your car and follow other cars
            </HeeboRegularText>
          </Paragraph>
          <WhiteButton onPress={setRegisterState} text='Register' />
          <WhiteButton onPress={setLoginState} text='Log in' />
        </ScrollView>
      </SafeAreaProvider>
    );
  } else {
    return (
      <SafeAreaProvider>
        <BackgroundGradient />
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.container}
        >
          <Paragraph>
            <HeeboRegularText onPress={e => setInitialState()}>
              Back
            </HeeboRegularText>
          </Paragraph>
          {renderTitle()}
          <ThemedTextInput
            editable={true}
            onChangeText={email => setEmail(email)}
            autoCapitalize="none"
            autoCorrect={false}
            placeholder="E-mail"
            // ref="email"
            returnKeyType="next"
            keyboardType="email-address"
            autoFocus={true}
            style={styles.inputText}
            textContentType={'username'} // for automatic storage of login info
            value={email}
          />
          {maybeRenderPasswordInput()}
          {maybeRenderForgotPwLink()}
          {maybeRenderRegisterOrLoginInputs()}
          {renderButton()}
          {maybeRenderMesssage()}
          {maybeRenderLoadingIndicator()}
        </ScrollView>
      </SafeAreaProvider>
    );
  }

  function setInitialState () {
    setAuthComponentState(AUTH_COMPONENT_STATE.NONE);
    setRequestState({
      loading: false,
      status: 0,
      message: '',
      showMessage: false,
    });
  };

  function setRegisterState () {
    setAuthComponentState(AUTH_COMPONENT_STATE.REGISTER);
    setRequestState({
      loading: false,
      status: 0,
      message: '',
      showMessage: false,
    });
  };

  function setLoginState () {
    setAuthComponentState(AUTH_COMPONENT_STATE.LOGIN);
    setRequestState({
      loading: false,
      status: 0,
      message: '',
      showMessage: false,
    });
  };

  function setForgotPwState () {
    setAuthComponentState(AUTH_COMPONENT_STATE.FORGOTPW);
    setRequestState({
      loading: false,
      status: 0,
      message: '',
      showMessage: false,
    });
  };

  function renderTitle () {
    if (authComponentState == AUTH_COMPONENT_STATE.FORGOTPW) {
      return (
        <HeeboBoldTextLarge>
          Forgot password
        </HeeboBoldTextLarge>
      );
    } else if (authComponentState == AUTH_COMPONENT_STATE.LOGIN) {
      return (
        <HeeboBoldTextLarge>
          Log in
        </HeeboBoldTextLarge>
      );
    } else if (authComponentState == AUTH_COMPONENT_STATE.REGISTER) {
      return (
        <HeeboBoldTextLarge>
          Register
        </HeeboBoldTextLarge>
      );
    }
  };

  function maybeRenderPasswordInput () {
    if (authComponentState != AUTH_COMPONENT_STATE.FORGOTPW) {
      return (
        <ThemedTextInput
          editable={true}
          onChangeText={password => setPassword(password)}
          placeholder="Password"
          // ref="password"
          returnKeyType="next"
          secureTextEntry={true}
          textContentType={authComponentState == AUTH_COMPONENT_STATE.LOGIN ? 'password' : 'newPassword'} // for automatic storage of login info
          style={styles.inputText}
          value={password}
        />
      );
    }
  };

  function maybeRenderForgotPwLink () {
    if (authComponentState == AUTH_COMPONENT_STATE.LOGIN) {
      return (
        <Paragraph>
          <HeeboRegularText onPress={e => setForgotPwState()}>
            Forgot password
          </HeeboRegularText>
        </Paragraph>
      );
    }
  };
  

  function maybeRenderRegisterOrLoginInputs () {
    if (authComponentState == AUTH_COMPONENT_STATE.REGISTER) {
      return (
        <>
          <ThemedTextInput
            editable={true}
            onChangeText={firstName => setFirstName(firstName)}
            placeholder="First name"
            // ref="firstName"
            returnKeyType="next"
            style={styles.inputText}
            value={firstName}
          />

          <ThemedTextInput
            editable={true}
            onChangeText={lastName => setLastName(lastName)}
            placeholder="Last name"
            // ref="lastName"
            returnKeyType="next"
            style={styles.inputText}
            value={lastName}
          />

          <Paragraph>
            <HeeboRegularTextMuted>
              <Switch value={checked} onValueChange={(newValue) => setChecked(newValue)} />
              &nbsp; I agree with&nbsp;
              <HeeboRegularTextMuted onPress={() => navigation.replace('PrivacyPolicy')} style={styles.linkText}>privacy policy</HeeboRegularTextMuted>
              &nbsp;or the&nbsp;
              <HeeboRegularTextMuted onPress={() => navigation.replace('TermsOfService')} style={styles.linkText}>terms of service</HeeboRegularTextMuted>
              .
            </HeeboRegularTextMuted>
          </Paragraph>
        </>
      );
    }
  };

  function renderButton () {
    if (authComponentState == AUTH_COMPONENT_STATE.FORGOTPW) {
      return (
        <WhiteButton onPress={resetPw} text='Reset password'/>
      );
    } else if (authComponentState == AUTH_COMPONENT_STATE.LOGIN) {
      return (
        <WhiteButton onPress={logIn} text='Log in'/>
      );
    } else if (authComponentState == AUTH_COMPONENT_STATE.REGISTER) {
      return (
        <WhiteButton onPress={registerUser} text='Register'/>
      );
    }
  };

  function maybeRenderMesssage () {
    if (requestState.showMessage) {
      if (
        requestState.status == 200 ||
        requestState.status == 204
      ) {
        // success
        if (authComponentState == AUTH_COMPONENT_STATE.REGISTER) {
          return (
            <>
              <HeeboBoldText>
                Check your email!
              </HeeboBoldText>
              <HeeboRegularText>
                Click the activation link in your inbox, check your spam folder
                if can’t find it.
              </HeeboRegularText>
            </>
          );
        }
        return (
          <HeeboBoldText>
            {requestState.message}
          </HeeboBoldText>
        );
      } else if (requestState.status == 422) {
        return (
          <HeeboRegularText lightColor='red' darkColor='red'>
            Failed: Please fill in all information
          </HeeboRegularText>
        );
      } else if (requestState.status == 409) {
        return (
          <HeeboRegularText lightColor='red' darkColor='red'>
            Failed: Account already exists, try logging in
          </HeeboRegularText>
        );
      } else if (requestState.status == 401) {
        if ( requestState.message == 'User account is not verified, please check your e-mail!' ) {
          return (
            <>
              <HeeboRegularText lightColor='red' darkColor='red'>
                Failed: Account not verified
              </HeeboRegularText>
              <HeeboBoldText lightColor='red' darkColor='red' onPress={e => sendActivationMail()}>
                Resend verification email
              </HeeboBoldText>
            </>
          );
        } else {
          return (
            <HeeboRegularText lightColor='red' darkColor='red'>
              Failed: Wrong email or password
            </HeeboRegularText>
          );
        }
      } else if (requestState.status == 404) {
        return (
          <HeeboRegularText lightColor='red' darkColor='red'>
            Failed: Not found (404)
          </HeeboRegularText>
        );
      } else if (requestState.status != 0) {
        // other error
        return (
          <HeeboRegularText lightColor='red' darkColor='red'>
            Error: {requestState.message}
          </HeeboRegularText>
        );
      }
    }
  };

  function maybeRenderLoadingIndicator () {
    if (requestState.loading) {
      return <ActivityIndicator animating size="large" />;
    }
  };

  function logIn () {
    if (requestState.loading) {
      return;
    }

    setRequestState({
      loading: true,
      status: 0,
      message: '',
      showMessage: false,
    });

    axios({
      method: 'POST',
      url: path.login,
      data: {
        email: email.toLowerCase(),
        password: password,
      },
    })
    .then(function (response) {

      setRequestState({
        loading: false,
        status: response.status,
        message: response.data.message,
        showMessage: true,
      });

      const authState : AuthStateType = {
        __act: response.data.__act,
        __rt: response.data.__rt,
        uuid: response.data.user.uuid,
      };

      // Store in persistent storage
      try {
        AsyncStorage.setItem('authState', JSON.stringify(authState));
      } catch (error) {
        console.error("Failed to store authentication in persistent storage");
      }

      // set Authorization headers
      axios.defaults.headers.common['Authorization'] = "__act "+response.data.__act;

      dispatch({
        type: AuthActionType.LOGGED_IN,
        payload: authState,
      });

      dispatch({
        type: UserActionType.SET_USER,
        payload: {
          admin: response.data.user.admin,
          fetched: true,
          firstName: response.data.user.firstName,
          lastName: response.data.user.lastName,
          following: response.data.user.following,
          blockedUsers: response.data.user.blockedUsers,
          mutedUsers: response.data.user.mutedUsers,
          profileImage: response.data.user.profileImage,
        },
      });
    })
    .catch(function (error) {
      setRequestState({
        loading: false,
        status: error.response.status,
        message: error.response.data.message,
        showMessage: true,
      });
    })
    .then(function () {
      // always executed
    });
  };

  function registerUser () {
    if (requestState.loading) {
      return;
    }

    if (!checked) {
      setRequestState({
        loading: false,
        status: 400,
        message: 'You must agree with the term and privacy policy and terms of service to create a GRILL account.',
        showMessage: true,
      });
      return;
    }
    
    setRequestState({
      loading: true,
      status: 0,
      message: '',
      showMessage: false,
    });

    axios({
      method: 'POST',
      url: path.register,
      data: {
        email: email.toLowerCase(),
        password: password,
        firstName: firstName,
        lastName: lastName,
      },
    })
    .then(function (response) {

      setRequestState({
        loading: false,
        status: response.status,
        message: response.data.message,
        showMessage: true,
      });

      const authState : AuthStateType = {
        __act: response.data.__act,
        __rt: response.data.__rt,
        uuid: response.data.user.uuid,
      };

      // Store in persistent storage
      try {
        AsyncStorage.setItem('authState', JSON.stringify(authState));
      } catch (error) {
        console.error("Failed to store authentication in persistent storage");
      }

      // set Authorization headers
      axios.defaults.headers.common['Authorization'] = "__act "+response.data.__act;

      dispatch({
        type: AuthActionType.LOGGED_IN,
        payload: authState,
      });

      dispatch({
        type: UserActionType.SET_USER,
        payload: {
          admin: response.data.user.admin,
          fetched: true,
          firstName: response.data.user.firstName,
          lastName: response.data.user.lastName,
          following: response.data.user.following,
          blockedUsers: response.data.user.blockedUsers,
          mutedUsers: response.data.user.mutedUsers,
          profileImage: response.data.user.profileImage,
        },
      });
    })
    .catch(function (error) {
      console.log(error.response.data.message);
      setRequestState({
        loading: false,
        status: error.status,
        message: error.response.data.message,
        showMessage: true,
      });
    })
    .then(function () {
      // always executed
    });
  };

  function resetPw () {
    if (requestState.loading) {
      return;
    }
    
    setRequestState({
      loading: true,
      status: 0,
      message: '',
      showMessage: false,
    });

    axios({
      method: 'POST',
      url: path.resetpasswordrequest,
      data: {
        email: email.toLowerCase(),
      },
    })
    .then(function (response) {
      setRequestState({
        loading: false,
        status: response.status,
        message: response.data.message,
        showMessage: true,
      });
    })
    .catch(function (error) {
      console.log(error.response.data.message);
      setRequestState({
        loading: false,
        status: error.status,
        message: error.response.data.message,
        showMessage: true,
      });
    })
    .then(function () {
      // always executed
    });
  };

  function sendActivationMail () {
    if (requestState.loading) {
      return;
    }
    
    setRequestState({
      loading: true,
      status: 0,
      message: '',
      showMessage: false,
    });

    axios({
      method: 'POST',
      url: path.sendactivationmail,
      data: {
        email: email.toLowerCase(),
      },
    })
    .then(function (response) {

      setRequestState({
        loading: false,
        status: response.status,
        message: response.data.message,
        showMessage: true,
      });
    })
    .catch(function (error) {
      setRequestState({
        loading: false,
        status: error.status,
        message: error.response.data.message,
        showMessage: true,
      });
    })
    .then(function () {
      // always executed
    });
  };

  function logOut () {
    setRequestState({
      loading: true,
      status: 0,
      message: '',
      showMessage: false,
    });

    const authState : AuthStateType = initialAuthState;

    // Store in persistent storage
    try {
      AsyncStorage.setItem('authState', JSON.stringify(authState));
    } catch (error) {
      console.error("Failed to store authentication in persistent storage");
    }

    // Reset cookie accept
    try {
      AsyncStorage.setItem('cookiesAccepted', JSON.stringify({cookiesAccepted: false}));
    } catch (error) {
      console.error("Failed to store authentication in persistent storage");
    }

    // set Authorization headers
    axios.defaults.headers.common['Authorization'] = '';

    dispatch({
      type: AuthActionType.LOGGED_OUT,
      payload: initialAuthState,
    });

    dispatch({
      type: UserActionType.CLEAR_USER,
      payload: initialUserState,
    });
  };
};
