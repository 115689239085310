import { CommonActions } from '@react-navigation/native';
import { useEffect } from 'react';
import { ScrollView, StyleSheet, TouchableOpacity } from 'react-native';
import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js';

import { Text, useThemeColor, View } from '../components/Themed';
import { RootStackScreenProps } from '../types';
import { HeeboBoldText, HeeboRegularText, HeeboRegularTextHuge, HeeboRegularTextLarge, HeeboRegularTextMuted } from '../components/StyledText';
import { SafeAreaProvider } from 'react-native-safe-area-context';

export default function PrivacyPolicy({ navigation, route }: RootStackScreenProps<'PrivacyPolicy'>) {

  const styles = StyleSheet.create({
    container: {
      paddingHorizontal: 20,
    },
    content: {
      flex: 1,
      gap: 20,
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
    },
    link: {
      paddingVertical: 20,
    },
    linkText: {
      fontSize: 14,
      color: useThemeColor({}, 'link'),
    },
  });

  // Add back button when coming here directly (empty navigation history)
  useEffect(
    () => {
      if (navigation.getState().routes.length>1) return;

      navigation.dispatch(navigationState => {
        const currentNavigationState = navigationState; // This can also directly be accessed with navigation.getState()
    
        const routes = [{name: 'GRILL'}, ...currentNavigationState.routes];
    
        const newNavigationState = {
          ...navigationState,
          routes,
          index: routes.length - 1,
        };
      
        return CommonActions.reset(newNavigationState);
      });
    }, [],
  );

  return (
    <SafeAreaProvider>
      <ScrollView contentContainerStyle={styles.container}>
        <View style={styles.content}>
          <TouchableOpacity onPress={() => navigation.replace('GRILL')} style={styles.link}>
            <Text style={styles.linkText}>{i18n.t('back')}</Text>
          </TouchableOpacity>
          <HeeboRegularTextHuge style={styles.title}>{i18n.t('title')}</HeeboRegularTextHuge>
          <HeeboRegularTextMuted>{i18n.t('p0001')}</HeeboRegularTextMuted>
          <HeeboRegularText>{i18n.t('p0002')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0003')}</HeeboRegularText>
          <HeeboRegularTextLarge style={styles.title}>{i18n.t('p01')}</HeeboRegularTextLarge>
          <HeeboRegularText>{i18n.t('p0101')}</HeeboRegularText>
          <HeeboRegularTextLarge style={styles.title}>{i18n.t('p02')}</HeeboRegularTextLarge>
          <HeeboRegularText>{i18n.t('p0201')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0202')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0203')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0204')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0205')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0207')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0208')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0209')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0210')}</HeeboRegularText>
          <HeeboRegularTextLarge style={styles.title}>{i18n.t('p03')}</HeeboRegularTextLarge>
          <HeeboRegularText>{i18n.t('p0300')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0301')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0302')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0303')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0304')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0305')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0307')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0308')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0309')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0310')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0311')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0312')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0313')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0314')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0315')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0316')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0317')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0318')}</HeeboRegularText>
          <HeeboRegularTextLarge style={styles.title}>{i18n.t('p04')}</HeeboRegularTextLarge>
          <HeeboRegularText>{i18n.t('p0401')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0402')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0403')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0404')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0405')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0406')}</HeeboRegularText>
          <HeeboRegularTextLarge style={styles.title}>{i18n.t('p05')}</HeeboRegularTextLarge>
          <HeeboRegularText>{i18n.t('p0501')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0502')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0503')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0504')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0505')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0506')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0507')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0508')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0509')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0510')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0511')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0512')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0513')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0514')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0515')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0516')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0517')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0518')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0519')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0520')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0521')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0522')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0523')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0524')}</HeeboRegularText>
          <HeeboRegularTextLarge style={styles.title}>{i18n.t('p06')}</HeeboRegularTextLarge>
          <HeeboRegularText>{i18n.t('p0601')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0602')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0603')}</HeeboRegularText>
          <HeeboRegularTextLarge style={styles.title}>{i18n.t('p07')}</HeeboRegularTextLarge>
          <HeeboRegularText>{i18n.t('p0701')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0702')}</HeeboRegularText>
          <HeeboRegularTextLarge style={styles.title}>{i18n.t('p08')}</HeeboRegularTextLarge>
          <HeeboRegularText>{i18n.t('p0801')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0802')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0803')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0804')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0805')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0806')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0807')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0808')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0809')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0810')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0811')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0812')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0813')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0814')}</HeeboRegularText>
          <TouchableOpacity onPress={() => navigation.replace('GRILL')} style={styles.link}>
            <Text style={styles.linkText}>{i18n.t('back')}</Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </SafeAreaProvider>
  );
}

const i18n = new I18n({
  nl: {
    title: 'Privacybeleid',
    back: 'Terug naar de feed',
    p0001: 'Laatst bijgewerkt op 1 september 2022',
    p0002: 'Deze “Privacy Policy” regelt de verwerking van uw persoonsgegevens door de verantwoordelijke voor de verwerking, te bereiken via info@grill-community.com, in het kader van het product GRILL, classic car community (hierna: GRILL).',
    p0003: 'Lees deze Privacy Policy aandachtig door, want het bevat essentiële informatie over hoe uw persoonsgegevens worden verwerkt en welke cookies worden gebruikt. Door het verstrekken van uw persoonsgegevens op de website https://grill-community.com/ of in de GRILL app, verklaart u kennis te hebben genomen van deze Privacy Policy en gaat u er eveneens uitdrukkelijk mee akkoord, alsook met de verwerking zelf.',
    p01: 'Artikel 1 – Algemeen',
    p0101: '1.1. GRILL stelt zich middels huidige Privacy Policy als verantwoordelijke voor de verwerking van persoonsgegevens in regel met de Europese Verordening 2016/679 van 27 april 2016 betreffende de bescherming van persoonsgegevens.',
    p02: 'Artikel 2 – Persoonsgegevens',
    p0201: '2.1. Persoonsgegevens die u ons meedeelt:',
    p0202: 'Categorie 1: zonder registratie bij een bezoek aan onze website: uw IP-adres, browser, surfgedrag op onze website, zoekwoorden die u gebruikte om ons te vinden;',
    p0203: 'Categorie 2: bij het aanmaken van een GRILL-app-gebruikersprofiel of GRILL-organisatie-beheerdersprofiel in onze app of een profiel op onze website: uw voor- en achternaam, wachtwoord, e-mailadres, logingegevens, profielafbeelding;',
    p0204: 'Categorie 3: bij het zelf publiceren van  informatie waaronder maar niet uitsluitend een post, event, foto, wagen: de inhoud van de post, de datum van die post, de afbeeldingen, locatie-informatie indien opgegeven of gedeeld, informatie over uw voertuig',
    p0205: 'Categorie 4: bij commerciële interacties zoals aankopen of advertenties: uw adresgegevens voor facturatie en desgevallend voor levering, uw naam, e-mailadres, telefoonnummer, betaalgegevens;',
    p0207: '2.2. GRILL kan gegevens van u (persoonsgegevens) op verschillende manieren vergaren:',
    p0208: '- door gebruik van cookies;',
    p0209: '- rechtstreeks van u verkregen;',
    p0210: '- verkregen van andere gebruikers van eenzelfde GRILL-organisatie waartoe ook u behoort.',
    p03: 'Artikel 3 – Doeleinden van de verwerking',
    p0300: '3.1. Algemene doeleinden:',
    p0301: 'De GRILL zal de bij u vergaarde persoonsgegevens uitsluitend voor de volgende doeleinden gebruiken:',
    p0302: 'Categorie 1: het verzorgen en verbeteren van onze website en het opnemen van persoonsgegevens in anonieme statistieken, waaruit de identiteit van specifieke personen of bedrijven niet kunnen achterhaald worden, met als rechtsgrond de legitieme belangen van GRILL om haar website en dienstverlening voortdurend te verbeteren;',
    p0303: 'Categorie 2: het beheer van uw account met het oog op het gebruik van de GRILL website en uw GRILL-app, met als rechtsgrond de uitvoering van een overeenkomst die door u werd gevraagd, en, indien aangegeven door u, eveneens het toezenden van direct marketing, met als rechtsgrond uw expliciete, voorafgaande toestemming waar nodig of desgevallend ons gerechtvaardigd belang;',
    p0304: 'Categorie 3: het beheer van uw account alsook het beheer van uw GRILL-posts die u aanmaakt met het oog op het gebruik van de GRILL website enuw GRILL-app, met als rechtsgrond de uitvoering van een overeenkomst die door u werd gevraagd, en, indien aangegeven door u, eveneens het toezenden van direct marketing, met als rechtsgrond uw expliciete, voorafgaande toestemming waar nodig of desgevallend ons gerechtvaardigd belang, de levering van de GRILL-diensten binnen de app en de website conform onze gebruiksvoorwaarden;',
    p0305: 'Categorie 4: de levering en facturatie van door u bestelde of aangeboden producten, met als rechtsgrond het verstrekken van een dienst die door u gevraagd werd;',
    p0307: 'U bent niet verplicht om uw persoonsgegevens vrij te geven, maar begrijpt dat het verlenen van bepaalde diensten onmogelijk wordt wanneer u de verwerking weigert.',
    p0308: '3.2. Direct marketing:',
    p0309: 'Uw persoonsgegevens kunnen worden gebruikt voor direct marketing, mits u hiervoor een bijkomende uitdrukkelijke toestemming heeft gegeven (“opt-in”) of mits u een bestaande klant bent, aan wie wij uitsluitend reclame sturen voor gerelateerde goederen of diensten, in welk geval wij over een gerechtvaardigd belang beschikken.',
    p0310: 'Indien u reeds bent opgenomen in onze verzendlijst voor het ontvangen van marketingmateriaal in papieren en/of elektronische vorm, kan GRILL uw gegevens gebruiken voor het verzenden van marketing en ander materiaal met betrekking tot GRILL, haar producten en/of diensten. GRILL kan de door u verschafte gegevens gebruiken voor het bijwerken van door GRILL bewaarde documenten.',
    p0311: 'Deze toestemming kan op elk moment worden ingetrokken, zonder motivering en gratis door bijvoorbeeld op de daartoe voorziene uitschrijflink te klikken, onderaan ieder promotioneel e-mailbericht.',
    p0312: '3.3. Doorgifte aan derden:',
    p0313: 'GRILL is in bepaalde gevallen gehouden uw gegevens door te geven aan derden. GRILL zal in zulke gevallen in alle redelijkheid pogen u van tevoren op de hoogte te stellen van het feit dat GRILL uw gegevens aan genoemde derde bekend maakt, maar u erkent tevens dat dit niet onder alle omstandigheden technisch of commercieel haalbaar is. In elk geval kan GRILL gebeurlijk uw gegevens delen met onze dienstenleveranciers waaronder Hetzner, …, alsook met eventuele administratieve partners zoals bijvoorbeeld boekhouder, advocaat, ….',
    p0314: 'Indien één van onze partners zich buiten de EU zou bevinden of indien om een of andere reden uw gegevens de EU dienen te verlaten, zal GRILL er zorg voor dragen deze data-export enkel uit te voeren met respect voor de wettelijke Europese en Belgische regels ter zake en met name waar nodig gebruik te maken van de Standaard Contractsclausules van de Europese Commissie inzake dataveiligheid bij export.',
    p0315: 'GRILL zal uw persoonsgegevens niet verkopen, noch verhuren, verdelen of anderszins commercieel ter beschikking stellen aan derden, behalve zoals bovenstaand beschreven of tenzij met uw voorafgaande toestemming.',
    p0316: 'Bij gehele of gedeeltelijk reorganisatie of overdracht van activiteiten van GRILL, waarbij zij haar activiteiten reorganiseert, overdraagt, staakt of indien GRILL failliet gaat, kan zulks betekenen dat uw gegevens aan nieuwe entiteiten of derden middels welke de bedrijfsactiviteiten van GRILL geheel of gedeeltelijk worden uitgevoerd, worden overgedragen.',
    p0317: '3.4. Wettelijke vereisten:',
    p0318: 'In zeldzame gevallen kan het voorkomen dat GRILL uw persoonsgegevens ingevolge een gerechtelijk bevel of om te voldoen aan andere dwingende wet- of regelgeving moet onthullen. GRILL zal in redelijkheid pogen u van tevoren dienaangaande te informeren, tenzij een en ander aan wettelijke beperkingen onderhevig is.',
    p04: 'Artikel 4 – Duur van de verwerking',
    p0401: 'De persoonsgegevens worden door ons bewaard en verwerkt voor een periode die noodzakelijk is in functie van de doeleinden van de verwerking en in functie van de contractuele relatie tussen de GRILL en u.',
    p0402: 'Concreet betekent dit dat uw gegevens als volgt bewaard worden:',
    p0403: 'Categorie 1, voor de duur van uw surfsessie en tot 12 maanden daarna;',
    p0404: 'Categorie 2: voor de duur van uw GRILL-gebruikersprofiel en tot maximum 5 jaar daarna;',
    p0405: 'Categorie 3: voor de duur van uw GRILL-gebruikersprofiel en tot maximum 5 jaar daarna;',
    p0406: 'Categorie 4: voor de duur van uw GRILL-gebruikersprofiel en tot maximum 5 jaar daarna;',
    p05: 'Artikel 5 – Uw rechten',
    p0501: '5.1. Recht van toegang en inzage:',
    p0502: 'U heeft het recht om op elk moment gratis kennis te nemen van uw persoonsgegevens, alsook van het gebruik dat wij van uw persoonsgegevens maken.',
    p0503: '5.2. Recht van verbetering, verwijdering en beperking:',
    p0504: 'U bent vrij om uw persoonsgegevens al dan niet mee te delen aan GRILL. Daarnaast heeft u steeds het recht om ons te verzoeken uw persoonsgegevens te verbeteren, aan te vullen of te verwijderen. U erkent dat bij weigering van mededeling of verzoek tot verwijdering van persoonsgegevens, bepaalde diensten en producten niet leverbaar zijn.',
    p0505: 'U mag eveneens vragen om de verwerking van uw persoonsgegevens te beperken.',
    p0506: '5.3. Recht van verzet:',
    p0507: 'U beschikt eveneens over een recht van verzet tegen de verwerking van uw persoonsgegevens om ernstige en legitieme redenen.',
    p0508: 'Daarnaast heeft u steeds het recht om u te verzetten tegen het gebruik van persoonsgegevens voor doeleinden van direct marketing. In dergelijk geval hoeft u geen redenen op te geven.',
    p0509: '5.4. Recht van vrije gegevensoverdracht:',
    p0510: 'U beschikt over het recht om uw persoonsgegevens die door ons verwerkt worden in gestructureerde, gangbare en machineleesbare vorm te verkrijgen en/of aan andere verantwoordelijken over te dragen.',
    p0511: '5.5. Recht van intrekking van de toestemming:',
    p0512: 'Voor zover de verwerking gebaseerd is op uw voorafgaande toestemming, beschikt u over het recht om die toestemming in te trekken.',
    p0513: '5.6. Uitoefening van uw rechten:',
    p0514: 'U kunt uw rechten uitoefenen door ons daartoe te contacteren, hetzij per e-mail naar onze Data Protection Officer op info@grill-community.com, per post naar GRILL op het in de aanhef van huidige Privacy Policy vermelde adres of door gebruik te maken van het onderdeel “Contacteer ons” op de website, mits bijvoeging van een kopie van uw identiteitskaart.',
    p0515: '5.7. Automatische beslissingen en profiling:',
    p0516: 'De verwerking van uw persoonsgegevens omvat geen profiling en u zult door ons evenmin aan geautomatiseerde beslissingen worden onderworpen.',
    p0517: '5.8. Recht om klacht in te dienen:',
    p0518: 'U beschikt over het recht om een klacht in te dienen bij de Belgische Gegevensbeschermingsautoriteit:',
    p0519: 'Drukpersstraat 35, 1000 Brussel',
    p0520: 'Tel: +32 (0)2 274 48 00',
    p0521: 'Fax: +32 (0)2 274 48 35',
    p0522: 'contact@apd-gba.be',
    p0523: 'Dit laat een voorziening voor een burgerlijke rechtbank onverlet.',
    p0524: 'Indien u schade zou lijden als gevolg van de verwerking van uw persoonsgegevens, kunt u een vordering tot schadevergoeding instellen.',
    p06: 'Artikel 6 – Veiligheid en vertrouwelijkheid',
    p0601: '6.1. Wij hebben veiligheidsmaatregelen ontwikkeld die aangepast zijn op technisch en organisatorisch vlak, om de vernietiging, het verlies, de vervalsing, de wijziging, de niet-toegestane toegang of de kennisgeving per vergissing aan derden van persoonsgegevens te vermijden, alsook elke andere niet toegestane verwerking van deze gegevens.',
    p0602: '6.2. In geen geval kan GRILL aansprakelijk worden geacht voor enige directe of indirecte schade die voortvloeit uit een foutief of onrechtmatig gebruik door een derde van de persoonsgegevens.',
    p0603: '6.3. U dient te allen tijde de veiligheidsvoorschriften na te leven, onder andere door elke niet toegestane toegang tot uw login en code te voorkomen. U bent dus als enige verantwoordelijk voor het gebruik dat wordt gemaakt vanaf de website van uw computer, IP-adres en van uw identificatiegegevens, alsook voor de vertrouwelijkheid ervan.',
    p07: 'Artikel 7 – Toegang door derden',
    p0701: '7.1. Teneinde uw persoonsgegevens te kunnen verwerken, verlenen wij toegang tot uw persoonsgegevens aan onze werknemers.',
    p0702: '7.2. Wij garanderen een gelijkaardig niveau van bescherming door contractuele verplichtingen tegenstelbaar te maken aan deze werknemers en aangestelden, die gelijkaardig zijn aan deze Privacy Policy.',
    p08: 'Artikel 8 – Cookies',
    p0801: '8.1. Wat zijn cookies?',
    p0802: 'Een “cookie” is een klein bestand uitgezonden door de server van GRILL en geplaatst op de harde schijf van uw computer. De inlichtingen opgeslagen op deze cookies kunnen enkel door ons gelezen worden en enkel gedurende de duur van het bezoek aan de website.',
    p0803: '8.2. Waarom gebruiken we cookies?',
    p0804: 'Onze website gebruikt cookies en gelijkaardige technologieën om uw gebruiksvoorkeuren te onderscheiden van die van andere gebruikers van onze website. Dit helpt ons om u een betere gebruikerservaring te bieden wanneer u onze website bezoekt en laat ons ook toe onze website te optimaliseren.',
    p0805: 'Ten gevolge van recente wetswijzigingen worden alle websites die gericht zijn op bepaalde delen van de Europese Unie verplicht om uw toestemming te vragen voor het gebruik of opslaan van cookies en gelijkaardige technologieën op uw computers of mobiele apparaten. Deze cookie policy geeft u duidelijke en volledige informatie over de cookies die wij gebruiken en hun doel.',
    p0806: '8.3. Soorten cookies:',
    p0807: 'Hoewel er verschillende soorten cookies bestaan, onderscheiden qua functionaliteit, herkomst of bewaartermijn, maakt de wetgeving hoofdzakelijk een onderscheid tussen functionele of technisch noodzakelijke cookies enerzijds en alle andere cookies anderzijds.',
    p0808: 'De website gebruikt uitsluitend de cookies verbonden aan het Shopify platform. Voor meer informatie kunt u terecht op https://www.shopify.com/legal/cookies.',
    p0809: 'Voor cookies geplaatst door derde partijen (o.a. Google Analytics) verwijzen wij u graag naar de verklaringen die deze partijen op hun respectieve websites daarover geven. Let op: wij oefenen geen enkele invloed uit op de inhoud van die verklaringen, noch op de inhoud van de cookies van deze derde partijen.',
    p0810: '8.4. Uw toestemming:',
    p0811: 'Bij uw eerste bezoek aan onze website wordt u gevraagd om onze verschillende soorten cookies te aanvaarden. U kunt categorie per categorie cookies toestaan of weigeren. U kunt te allen tijde de cookie instellingen voor onze website wijzigen via de hyperlink onderaan onze website en aldus uw toestemming intrekken.',
    p0812: 'U kunt de installatie van deze cookies weigeren door bij het eerste gebruik van de website in het daartoe aangeboden pop-up scherm te kiezen voor “Cookies weigeren”.',
    p0813: 'U kunt tevens cookies weigeren of blokkeren door de configuratieparameters van uw navigatiesysteem te wijzigen. Het uitschakelen van cookies kan betekenen dat u van bepaalde functionaliteiten van de website geen gebruik kunt maken.',
    p0814: 'Meer informatie over cookies vindt u tevens op: https://www.allaboutcookies.org. Meer informatie over online behavioural advertising en online privacy vindt u hier: https://www.youronlinechoices.eu.'
  },
  fr: {
  },
  en: {
    title: 'Privacy policy',
    back: 'Back to the feed',
  },
});
i18n.locale = Localization.locale; // this should happen only once
i18n.enableFallback = true;
// => causes fallback to default below
i18n.locale = 'nl';
