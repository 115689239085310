import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { MenuProvider } from 'react-native-popup-menu';

import useCachedResources from './hooks/useCachedResources';
import { AppProvider } from "./contexts";
import useColorScheme from './hooks/useColorScheme'; // light or dark property
import Navigation from './navigation';
import RequestComponent from './components/RequestComponent';
import WelcomeScreen from './screens/WelcomeScreen';
import { useAsyncStorage } from './hooks/useAsyncStorage';
import axios from 'axios';
import * as React from 'react'
import { environment, environmentType, hostname, hostnameFrontEnd } from './env/env';
import { AlertWeb } from './components/AlertWeb';
import FullScreenImage from './components/Carousel';
import Notification from './components/Notification';
import Spinner from './components/Spinner';
import { Platform } from 'react-native';
import { Helmet } from 'react-helmet';

axios.defaults.baseURL = hostname;

export default function App() {
  const isLoadingComplete = useCachedResources();
  const asyncStorage = useAsyncStorage();
  const colorScheme = useColorScheme();
  const [ showWelcome, setShowWelcome ] = useState(false);
  const [ showRequest, setShowRequest ] = useState(false);
  const [ appReady, setAppReady ] = useState(false);

  // Firebase config via https://console.firebase.google.com/project/grill-1541829414677/settings/general/web:YTc1ZWFiYzktNWUyNi00M2FkLTg3M2ItMmE2ZDczNzUxOTY3
  const firebaseConfig = {
    apiKey: "AIzaSyAhNZqphEkQ0JPtmkfOrtbeaX_LBw22L8Y",
    authDomain: "grill-1541829414677.firebaseapp.com",
    projectId: "grill-1541829414677",
    storageBucket: "grill-1541829414677.appspot.com",
    messagingSenderId: "794063113946",
    appId: "1:794063113946:web:195b9cea5eca8d909d9fe3",
    measurementId: "G-EDJSTWEZ36"
  };

  const enterWithCookies = () => {
    AsyncStorage.setItem('cookiesAccepted', JSON.stringify({cookiesAccepted: true}));
    setShowWelcome(false);
    setAppReady(true);
  };

  const enterNoCookies = () => {
    setShowWelcome(false);
    setAppReady(true);
  };

  const requestDone = () => {
    setShowRequest(false);
    setAppReady(true);
  };

  const initFirebase = () => {
    // Initialize Firebase if cookies accepted or on mobile after pressing welcome
    if (Platform.OS === 'web' && String(environment) == environmentType.PRD) { // Voor mobile eerst: https://docs.expo.dev/guides/using-firebase/#configure-metro
      // Import the Firebase functions you need from the SDKs you need
      // TODO: Add SDKs for Firebase products that you want to use
      // https://firebase.google.com/docs/web/setup#available-libraries
      const firebaseApp = require("firebase/app");
      const initializeApp = firebaseApp.initializeApp;
      const firebaseAnalytics = require("firebase/analytics");
      const getAnalytics = firebaseAnalytics.getAnalytics;
      const app = initializeApp(firebaseConfig);
      const analytics = getAnalytics(app);
    }
  };

  useEffect(()=>{
    if (asyncStorage.isAsyncStorageReady) {
      if (asyncStorage.authStateFromLocalStorage && asyncStorage.authStateFromLocalStorage.uuid && asyncStorage.authStateFromLocalStorage.uuid!=='') {
        initFirebase();
        setShowRequest(true);
      } else if (asyncStorage.cookiesAccepted) {
        initFirebase();
        setAppReady(true);
      } else {
        setShowWelcome(true);
      }
    }
  }, [asyncStorage.isAsyncStorageReady]);
  
  // console.log('isLoadingComplete: '+isLoadingComplete);
  // console.log('appReady: '+appReady);
  // console.log('showWelcome: '+showWelcome);
  // console.log('showRequest: '+showRequest);
  if (!isLoadingComplete) {
    return null;
  } else {

    // Prep meta content
    const meta = {
      title: 'GRILL Classic Car Community',
      description: 'GRILL is an online classic car community',
      // image: '',
      url: hostnameFrontEnd,
      // type: 'summary_large_image',
      keywords: 'GRILL, Classic car, Community, Oldtimer',
      // slug: 'SLUG_PLACEHOLDER'
    };

    return (
      <AppProvider>
        {/* <React.StrictMode> */}
          <SafeAreaProvider>
            <Helmet>
              <title>{meta.title}</title>
              <meta  name="description" content={meta.description} />
              <meta name="keywords" content={meta.keywords} />

              <meta property="og:title" content={meta.title} />
              <meta name="description" content={meta.description} />
              <meta property="og:description" content={meta.description} />
              {/* <meta property="og:image" content={meta.image} /> */}
              <meta property="og:url" content={meta.url} />

              <meta name="twitter:title" content={meta.title} />
              <meta name="twitter:description" content={meta.description} />
              {/* <meta name="twitter:image" content={meta.image} /> */}
              {/* <link rel="canonical" href={slug} /> */}
            </Helmet>
            {!appReady && !showWelcome && !showRequest && <Spinner />}
            {!appReady && showWelcome && <WelcomeScreen enterNoCookies={enterNoCookies} enterWithCookies={enterWithCookies} />}
            {!appReady && showRequest && <RequestComponent requestDone={requestDone} authState={asyncStorage.authStateFromLocalStorage} />}
            {appReady && <MenuProvider>
              <Navigation colorScheme={colorScheme} />
              <StatusBar />
              <AlertWeb />
              <FullScreenImage />
              <Notification />
            </MenuProvider>}
          </SafeAreaProvider>
        {/* </React.StrictMode> */}
      </AppProvider>
    );
  }
};
