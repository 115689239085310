import { FlatList, StyleSheet, TouchableOpacity } from 'react-native';
import { useContext, useEffect, useState } from 'react';

import { PostListCard } from './PostListCard';
import { AppContext } from "../contexts";
import { PostActionType, PostStateType } from '../contexts/reducers/postListReducer';
import axios from 'axios';
import { getPath, path } from '../env/env';
import { PostListRefreshActionType } from '../contexts/reducers/postListRefreshReducer';
import { imagePadding } from '../hooks/usePlatform';
import { PostType } from '../types/types';
import { PostListHeader } from './PostListHeader';

export default function PostList (props: {focusOnUuid: string}) {
  const { state, dispatch } = useContext(AppContext);
  const [ page, setPage ] = useState(0);

  const pageSize = 0; // 0 = use server defaults

  const keyExtractor = (item: PostStateType) => `PostCard-${item.uuid}`;
  const renderItem = ({ item }: { item: PostStateType }) => <PostListCard post={item} focus={item.uuid==props.focusOnUuid?true:false} />;

  function refresh () {
    setPage(0);
  };

  function nextPage () {
    setPage(page => page+1);
  };

  useEffect(
    () => {
      dispatch({
        type: PostListRefreshActionType.POSTS_REFRESH,
        payload: null,
      });
      axios({
        method: 'GET',
        url: getPath(PostType.POST),
        params: {
          page: page,
          ...(pageSize>0 && {pagesize: pageSize}), // only add when not 0, careful with casing
        },
      })
      .then(function (response) {
        if (response.data.items) {
          dispatch({
            type: page==0 ? PostActionType.POSTS_FETCHED_ON_MOUNT : PostActionType.POSTS_FETCHED,
            payload: response.data.items,
          });
        } else {
          console.log('No more posts found');
        }
      })
      .catch(function (error) {
        console.log('Error fetching posts with error: ');
        console.log(error);
      })
      .then(function () {
        // always executed
        dispatch({
          type: PostListRefreshActionType.POSTS_REFRESHED,
          payload: null,
        });
      });
    }, [page],
  );

  return (
    <FlatList
      data={state.postList}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      pinchGestureEnabled={false}
      contentContainerStyle={styles.listContent}
      onRefresh={refresh}
      refreshing={state.postListRefresh.refreshing}
      onEndReached={nextPage}
      onEndReachedThreshold={1}
      ListHeaderComponent={<PostListHeader />}
    />
  );
};

const styles = StyleSheet.create({
  listContent: {
    paddingHorizontal: imagePadding,
  },
});
