import {StyleSheet, TouchableOpacity } from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';
import { HeeboBoldText } from '../components/StyledText';

import Colors from '../constants/Colors';
import {LogoColors, useThemeColor} from './Themed';
import { FontAwesome } from '@expo/vector-icons';

interface Props {
  onPress: () => void;
  text: string;
  icon?: string;
  disabled?: boolean;
}

export function ColoredButton(props: Props) {

  const styles = StyleSheet.create({
    buttonStyle: {
      marginVertical: 5,
      width: '90%',
      maxWidth: 400,
    },
    background: {
      paddingVertical: 10,
      paddingHorizontal: 30,
      borderRadius: 22,
    },
    buttonText: {
      color: Colors.light.textinverse,
      fontSize: 16,
      textAlign: 'center',
    },
    buttonDisabled: {
      color: Colors.light.textmuted,
      fontSize: 16,
      textAlign: 'center',
    },
    buttonIcon: {
      fontSize: 18,
    },
  });

  // Only allow press callback if not disabled
  const callback = props.disabled ? ()=>{} : props.onPress;

  return (
    <TouchableOpacity onPress={callback} style={styles.buttonStyle}>
      <LinearGradient
        style={styles.background}
        start={{x: 0, y: 0}} // = 0% & 0%
        end={{x: 1, y: 0.6}} // = 100% & 60%
        colors={props.disabled ? [Colors.light.alt2dark, Colors.light.alt2dark] : [LogoColors().left, LogoColors().right]}>
        <HeeboBoldText style={props.disabled ? styles.buttonDisabled : styles.buttonText}>
          {props.icon && <FontAwesome style={styles.buttonIcon} name={props.icon} />}
          &nbsp;&nbsp;
          {props.text}
        </HeeboBoldText>
      </LinearGradient>
    </TouchableOpacity>
  );
};
