import { StyleSheet, View } from 'react-native';
import React, { useEffect, useState } from 'react';

import usePlatform from '../../hooks/usePlatform';
import { useThemeColor } from './../Themed';
import { HeeboBoldTextLarge, HeeboRegularTextMuted } from './../StyledText';
import { EventStateType } from '../../contexts/reducers/eventListReducer';
import { ImageMetadata } from '../../types/types';
import { emptyImageInstance, fetchImageInstance } from '../../util/imageHelper';
import { parseISO } from 'date-fns';
import DateIcon from '../DateIcon';
import axios from 'axios';
import { path } from '../../env/env';
import EventCardImage from './EventCardImage';

export function EventCardContent (props: {
  item: EventStateType,
  detailMode: boolean;
}) {
  if (!props.item) return null;

  const platform  = usePlatform();
  
  const [ imageInstance, setImageInstance] = useState<ImageMetadata>(emptyImageInstance);
  const [ addressTagLine, setAddressTagLine] = useState('');
  
  useEffect(() => {
    fetchImageInstance(setImageInstance, props.item.imageUuids, 'lg', true, '');

    if (!props.item.payload.locationUuids || props.item.payload.locationUuids.length===0) return;

    props.item.payload.locationUuids.map((locationUuid) => {
      axios({
        method: 'GET',
        url: path.addresses+'/'+locationUuid,
      })
      .then(function (response) {
        if (!response.data.item) return;
        if (addressTagLine=='') setAddressTagLine(response.data.item.name);
        else setAddressTagLine((prevAddressTagLine) => prevAddressTagLine + ', ' + response.data.item.name);
      })
      .catch(function (error) {
        console.log(error.response.data.message);
      })
      .then(function () {
        // always executed
      });
    });
  }, []);

  const styles = StyleSheet.create({
    container: {
      borderRadius: 8,
      flexDirection: 'row',
      alignItems: 'center',
    },
    smallContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    content: {
      justifyContent: 'center',
      position: 'absolute',
      left: 24,
      bottom: 24,
    },
    mainText: {
      fontSize: platform.normalize(18),
      color: useThemeColor({}, 'text'),
    },
    tagline: {
      fontSize: platform.normalize(13),
      color: useThemeColor({}, 'text'),
    },
    subText: {
      fontSize: platform.normalize(14),
      color: useThemeColor({}, 'text'),
    },
    arrow: {
      fontSize: platform.normalize(24),
      color: useThemeColor({}, 'bluedark'),
    },
  });

  return (
    <View style={styles.container}>
      {/* <Image source={imageInstance.uri!='' ? {uri: imageInstance.uri} : require('../../assets/images/empty_image.jpg')} style={} /> */}
      <EventCardImage
        key="image"
        imageInstance={imageInstance}
        detailMode={props.detailMode}
      />
      <View style={styles.content}>
        <HeeboBoldTextLarge style={styles.mainText}>{props.item.payload.mainText}</HeeboBoldTextLarge>
        <HeeboRegularTextMuted style={styles.tagline}>{addressTagLine}</HeeboRegularTextMuted>
      </View>
      <DateIcon startDate={parseISO(props.item.payload.startDate)} endDate={parseISO(props.item.payload.endDate)} detailMode={props.detailMode} />
    </View>
  );
};

export default EventCardContent;
