import { ImageMetadata } from "../../types/types";

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      }
};

export type CarouselStateType = {
  index: number,
  imageMetadatas: (ImageMetadata)[]|null,
};

export const initialCarouselState : CarouselStateType = {
  index: -1,
  imageMetadatas: null,
};

export enum CarouselActionType {
  CAROUSELREADY = 'CAROUSELREADY',
  CAROUSELSHOW = 'CAROUSELSHOW',
  CAROUSELNEXT = 'CAROUSELNEXT',
  CAROUSELPREV = 'CAROUSELPREV',
  CAROUSELHIDDEN = 'CAROUSELHIDDEN',
}

type CarouselActionPayload = {
  [CarouselActionType.CAROUSELREADY]: CarouselStateType;
  [CarouselActionType.CAROUSELSHOW]: number;
  [CarouselActionType.CAROUSELNEXT]: null;
  [CarouselActionType.CAROUSELPREV]: null;
  [CarouselActionType.CAROUSELHIDDEN]: null;
};

export type CarouselAction = ActionMap<CarouselActionPayload>[keyof ActionMap<
  CarouselActionPayload
>];

export const carouselReducer = (
  state: CarouselStateType,
  action: CarouselAction
) => {
  switch (action.type) {
    case CarouselActionType.CAROUSELREADY:
      return action.payload;
    case CarouselActionType.CAROUSELSHOW:
      if (!state.imageMetadatas) return {...state};
      const showIndex = action.payload;
      return {...state, index: showIndex};
    case CarouselActionType.CAROUSELNEXT:
      if (!state.imageMetadatas) return {...state};
      const nextIndex = (state.index+1)%state.imageMetadatas.length;
      return {...state, index: nextIndex};
    case CarouselActionType.CAROUSELPREV:
      if (!state.imageMetadatas) return {...state};
      const prevIndex = state.index==0 ? state.imageMetadatas.length-1 : state.index-1;
      return {...state, index: prevIndex};
    case CarouselActionType.CAROUSELHIDDEN:
      return {...state, index: -1};
    default:
      return state;
  }
};
