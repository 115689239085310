import { StyleSheet, Animated, TouchableWithoutFeedback } from 'react-native';
import React from 'react';

import { useThemeColor } from './Themed';
import { FontAwesome } from '@expo/vector-icons';

export default function CarouselExpand () {

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      right: 6,
      top: 6,
      justifyContent: 'center',
      alignItems: 'center',
      // opacity: 0.5,
      // backgroundColor: useThemeColor({}, 'text'),
      borderRadius: 14,
      width: 20,
      height: 20,
    },
    buttonIcon: {
      color: useThemeColor({}, 'alt2dark'),
      fontSize: 16,
    },
  });
  
  return (
    <Animated.View style={styles.container}>
      <FontAwesome style={styles.buttonIcon} name='expand' />
    </Animated.View>
  );
};
