type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      }
};

export type AlertStateType = {
  show: boolean,
  title: string,
  message: string,
  action: Function|null,
};

export const initialAlertState : AlertStateType = {
  show: false,
  title: '',
  message: '',
  action: null,
};

export enum AlertActionType {
  ALERTSHOWED = 'ALERTSHOWED',
  ALERTHIDDEN = 'ALERTHIDDEN',
}

type AlertActionPayload = {
  [AlertActionType.ALERTSHOWED]: AlertStateType;
  [AlertActionType.ALERTHIDDEN]: AlertStateType;
};

export type AlertAction = ActionMap<AlertActionPayload>[keyof ActionMap<
  AlertActionPayload
>];

export const alertReducer = (
  state: AlertStateType,
  action: AlertAction
) => {
  switch (action.type) {
    case AlertActionType.ALERTSHOWED:
      return action.payload;
    case AlertActionType.ALERTHIDDEN:
      return action.payload;
    default:
      return state;
  }
};
