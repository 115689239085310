import React, { useEffect, useState } from 'react';
import { ActivityIndicator, ImageBackground, Platform, StyleSheet, TouchableOpacity } from 'react-native';
import { useThemeColor, View } from '../components/Themed';
import * as ImagePicker from 'expo-image-picker';
import axios from 'axios';
import mime from 'mime';
import FontAwesome from '@expo/vector-icons/FontAwesome';
global.Buffer = global.Buffer || require('buffer').Buffer;
import { ServerImage } from '../types/types';
import { PostImage } from '../contexts/reducers/postListReducer';
import { grillImageServerUrl } from '../env/env';

export default function PostImagePicker(props: {
  main: boolean,
  imageUri: string,
  imagePicked: (postImage: PostImage, imagePage: number, inputName: string) => void,
  imageRemoved: (imageUuidRemoved: string, imagePage: number, inputName: string) => void,
  imagePage: number,
  inputName: string,
  savedCallback: Function;
}) {
  
  const emptyServerImage: ServerImage = {
    uuid: '',
    owner: '',
    orientation: '',
    sizes: [],
  };

  const [uploading, setUploading] = useState(false);
  const [imageUri, setImageUri] = useState(props.imageUri);
  const [uploadedImage, setUploadedImage] = useState<ServerImage>(emptyServerImage);
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');

  // Load image information onload if imageUri is provided
  useEffect( () => {
    if (imageUri=='') return;

    axios.get(grillImageServerUrl+'/'+imageUri)
    .then ((response) => {
      setUploadedImage(response.data.image);
      setUploadedImageUrl(response.data.image.uuid+'/'+'pr');
    })
    .catch((error) => {
      console.log('Failed to fetch image info with error:');
      console.log(error);
    });
    }, [],
  );

  const pickImage = async () => {
    props.savedCallback(false);

    // No permissions request is necessary for launching the image library
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      base64: true,
      // allowsEditing: true,
      // aspect: [4, 3],
      // quality: 1,
    });

    if (!result.cancelled && result.base64) {
      setUploadedImageUrl('');
      setImageUri(result.uri);

      const formData = new FormData();
      if (Platform.OS === 'web') {
        const buffer = await Buffer.from(result.base64, "base64");
        const imageBlob = await new Blob([buffer]);
        formData.append('image', imageBlob);
      } else {
        var fileToUpload = {
          name: result.uri.split("/").pop(),
          uri: result.uri,
          type: Platform.OS === 'android' ? mime.getType(result.uri) : result.type, // DJEEZES https://forums.expo.dev/t/network-request-failed-while-uploading-image-to-server/30737/5
        };
        formData.append('image', fileToUpload);
      }
      setUploading(true);

      postImage(grillImageServerUrl, formData);
    }
  };

  const postImage = async (grillImageServerUrl: string, formData: FormData) => {
    await axios.post(
      grillImageServerUrl,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        transformRequest: (data, headers) => {
          // !!! override data to return formData since axios converts that to string
          return formData;
        },
      })
    .then (async function (response) {
      await new Promise(r => setTimeout(r, 1000)); // Timer to give server some time, even though I debugged the timings, may a filesystem issue
      // setUploading(false);
      // setUploadedImage(response.data.image);
      // setUploadedImageUrl(response.data.image.uuid+'/'+'pr');
      props.imagePicked({
        uuid: response.data.image.uuid,
        mainImage: props.main,
      },
      props.imagePage,
      props.inputName);
    })
    .catch(function (error) {
      // setUploading(false);
      // setUploadedImage(emptyServerImage);
      // setUploadedImageUrl('');
      console.log('Failed to upload image with error:');
      console.log(error);
      postImage(grillImageServerUrl, formData);
    });
  };

  const deleteImage = async (uuid: string) => {
    setUploading(true);
    await axios.delete(grillImageServerUrl+'/'+uuid)
    .then (async function (response) {
      setUploading(false);
      setUploadedImage(emptyServerImage);
      setUploadedImageUrl('');
      setImageUri('');
      props.imageRemoved(
        uuid,
        props.imagePage,
        props.inputName
      );
    })
    .catch(function (error) {
      setUploading(false);
      console.log('Failed to delete image with error:');
      console.log(error);
    });
  };

  const styles = StyleSheet.create({
    container: {
      marginVertical: 10,
      marginHorizontal: 5,
    },
    imagePreview: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: props.main? 160 : 80,
      height: props.main? 160 : 80,
      borderRadius: 16,
      overflow: 'hidden', // needed to have borderRadius om ImageBackground, as ImageBackground is basically a <View> wrapping an <Image>
    },
    softBackground: {
      backgroundColor: useThemeColor({}, 'subtlebackgroundonreactcolors'),
    },
    positionCloseButton: {
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
    },
    closeButton: {
      padding: 10,
      shadowColor: useThemeColor({}, 'textinverse'),
      textShadowRadius: 2,
      textShadowOffset: { width: 0, height: 0 },
      textShadowColor: useThemeColor({}, 'shadowinverse'),
    },
    uploadButton: {
      padding: 5,
      fontSize: 30,
      color: useThemeColor({}, 'text'),
    },
    loading: {
      opacity: 0.5,
    },
  });

 // old icon: file-image-o
  return (
    <View style={styles.container}>
      {imageUri=='' && <TouchableOpacity style={[styles.imagePreview, styles.softBackground]} onPress={pickImage}>
          <FontAwesome name={'cloud-upload'} style={styles.uploadButton} />
        </TouchableOpacity>
      }
      {uploadedImageUrl!='' && 
        <ImageBackground source={{ uri: grillImageServerUrl+'/'+uploadedImageUrl }} style={[styles.imagePreview, styles.positionCloseButton]}>
          <FontAwesome name={'remove'} onPress={() => deleteImage(uploadedImage.uuid)} style={styles.closeButton} />
        </ImageBackground>
      }
      {imageUri!='' && uploading && <ImageBackground source={{ uri: imageUri }} style={[styles.imagePreview, styles.loading]}><ActivityIndicator /></ImageBackground>}
    </View>
  );
}
