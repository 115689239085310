import { EventStateType} from "./eventListReducer";
import { PostDetailCardInfoType } from "./postListReducer";

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      }
};

export type EventDetailStateType = {
  item: EventStateType | null,
  info: PostDetailCardInfoType | null | undefined,
};

export type EventDetailShowedType = {
  item: EventStateType | null,
  info: PostDetailCardInfoType | null | undefined,
};

export const initialEventDetailState: EventDetailStateType = {
  item: null,
  info: null,
};

export enum EventDetailActionType {
  EVENT_HIDE = 'EVENT_HIDE',
  EVENT_SHOWED = 'EVENT_SHOWED',
}

type EventDetailActionPayload = {
  [EventDetailActionType.EVENT_HIDE]: null;
  [EventDetailActionType.EVENT_SHOWED]: EventDetailShowedType;
};

export type EventDetailAction = ActionMap<EventDetailActionPayload>[keyof ActionMap<
  EventDetailActionPayload
>];

export const eventDetailReducer = (
  state: EventDetailStateType,
  action: EventDetailAction
) => {
  switch (action.type) {
    case EventDetailActionType.EVENT_HIDE:
      return {...state, hasDimmedLayer: false, item: null};
    case EventDetailActionType.EVENT_SHOWED:
      return {...state, item: action.payload.item, info: action.payload.info };
    default:
      return state;
  }
};
