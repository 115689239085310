import axios from "axios";
import { PostImage } from "../contexts/reducers/postListReducer";
import { grillImageServerUrl } from "../env/env";
import { ImageMetadata } from "../types/types";

export const emptyImageInstance : ImageMetadata = {
  createdAt: new Date(),
  uuid: '',
  uri: '',
  width: 0,
  height: 0,
  orientation: '',
  requestSize: '',
  size: '',
  owner: '',
};

export async function fetchImageInstance (
    useStateFunction: Function|null,
    imageUuids: PostImage[],
    requestSize: string,
    main: boolean,
    uuid: string,
  ) {
  if (!imageUuids || !requestSize) return;

  let findDmage : PostImage | undefined;
  if (main) findDmage = imageUuids.find(image => image.mainImage )
  else if (uuid) findDmage = imageUuids.find(image => image.uuid==uuid )

  if (!findDmage || !findDmage.uuid) return;
  const image : PostImage = findDmage;
  
  let imageInstance : ImageMetadata = {...emptyImageInstance}; // IMPORTANT, otherwise we copy a pointer!
  await axios.get(grillImageServerUrl+'/'+image.uuid)
  .then ((response) => {
    imageInstance.uuid = image.uuid;
    imageInstance.requestSize = requestSize;

    imageInstance.size = requestSize;
    if (requestSize!='orig' && requestSize!='lg_sq' && requestSize!='pr' && requestSize!='th') {
      if ( response.data.image.orientation == 'portrait' ) { imageInstance.size = requestSize+'_pt'; }
      if ( response.data.image.orientation == 'landscape' ) { imageInstance.size = requestSize+'_ls'; }
    }
    const findSize = response.data.image.sizes.find((size: any) => size.param === imageInstance.size );
    if (findSize) {
      imageInstance.width = findSize.width;
      imageInstance.height = findSize.height;
    }
    imageInstance.uri = grillImageServerUrl+'/'+imageInstance.uuid+'/'+imageInstance.size;
    imageInstance.createdAt = response.data.image.createdAt;
    imageInstance.orientation = response.data.image.orientation;
    imageInstance.owner = response.data.image.owner;
    if (response.data.image.dominantColor) imageInstance.dominantColor = response.data.image.dominantColor;

    if (useStateFunction) useStateFunction(imageInstance);
  })
  .catch((error) => {
    console.log('Failed to fetch image info with error:');
    console.log(error);
  });

  return imageInstance;
};
