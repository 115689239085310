/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      GRILL: {
        // path: '', // not required
        screens: {
          Feed: {
            path: '/:uuid?',
          },
          Events: {
            path: 'events/:uuid?',
          },
          Post: {
            path: 'post/:uuid?',
          },
          Clubs: {
            path: 'clubs/:uuid?',
          },
          Experts: {
            path: 'experts/:uuid?',
          },
        },
      },
      Profile: {
        path: 'profile',
      },
      Activate: {
        path: 'activate/:uuid/:verifyString',
      },
      Reset: {
        path: 'resetpassword/:uuid/:verifyString',
      },
      PrivacyPolicy: {
        path: 'privacypolicy',
      },
      TermsOfService: {
        path: 'termsofservice',
      },
      Admin: {
        path: 'admin',
      },
      NotFound: '*',
      // For later profiles with parameters: https://reactnavigation.org/docs/configuring-links/ (including userids)
    },
  },
};

export default linking;
