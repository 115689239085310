import { StyleSheet, TouchableOpacity } from 'react-native';

import { Text, useThemeColor, View } from '../components/Themed';
import { RootStackScreenProps } from '../types';

export default function NotFoundScreen({ navigation, route }: RootStackScreenProps<'NotFound'>) {

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
    },
    link: {
      marginTop: 15,
      paddingVertical: 15,
    },
    linkText: {
      fontSize: 14,
      color: useThemeColor({}, 'link'),
    },
  });
  
  return (
    <View style={styles.container}>
      <Text style={styles.title}>This screen doesn't exist.</Text>
      <TouchableOpacity onPress={() => navigation.replace('GRILL')} style={styles.link}>
        <Text style={styles.linkText}>Go back to the feed!</Text>
      </TouchableOpacity>
    </View>
  );
}
