import React from 'react';
import { ScrollView, Animated, PanResponder, BackHandler, StyleSheet, PanResponderGestureState, GestureResponderEvent, NativeEventSubscription, Platform, Alert, TextInput } from 'react-native';
import { useState, useEffect, useContext, useRef } from 'react';
import { useHeaderHeight } from '@react-navigation/elements';
import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js';

import { AppContext } from "../../contexts";
import usePlatform, { imagePadding, maxWidth } from '../../hooks/usePlatform';
import { setStatusBarHidden } from 'expo-status-bar';
import { EventDetailActionType } from '../../contexts/reducers/eventDetailReducer';
import { useNavigation } from '@react-navigation/native';
import { RootTabScreenProps } from '../../types';
import { useThemeColor } from './../Themed';
import { AlertModal } from "../AlertModal";
import EventCardContent from './EventCardContent';
import PostDetailCardClose from '../PostDetailCardClose';
import { WhiteButton } from '../WhiteButton';
import EventDetailCardContent from './EventDetailCardContent';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import axios from 'axios';
import { getPath, grillImageServerUrl, hostnameFrontEnd, path } from '../../env/env';
import { EventActionType } from '../../contexts/reducers/eventListReducer';
import { PostType } from '../../types/types';
import { Helmet } from 'react-helmet';

export default function EventDetailCard () {

  const { state, dispatch } = useContext(AppContext);
  const navigation = useNavigation<RootTabScreenProps<'Events'>['navigation']>();
  const platform = usePlatform();
  const [ shouldStartShowAnimation, setShouldStartShowAnimation ] = useState(false);
  const [ transferEmail, setTransferEmail ] = useState('');

  useEffect(() => {
    if (state.eventDetail.info) setShouldStartShowAnimation(true);
  }, [state.eventDetail.info]);

  const SWIPE_CLOSE_THRESHOLD = 30;
  
  enum SWIPE_MODE {
    HORIZONTAL = 'HORIZONTAL',
    VERTICAL = 'VERTICAL',
  };

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      backgroundColor: useThemeColor({}, 'subtlebackground'),
      zIndex: 20,
      maxWidth: maxWidth,
    },
    contentContainer: {
      paddingHorizontal: imagePadding,
      alignItems: 'center',
      paddingBottom: 50,
    },
    textinput: {
      borderRadius: 15,
      borderColor: useThemeColor({}, 'text'),
      borderWidth: 1,
      padding: 3,
      margin: 1,
      textAlign: 'center',
    },
  });
  
  const [ hasSwipeCloseAnimation, setHasSwipeCloseAnimation ] = useState(false);
  const panResponder = PanResponder.create({
    onMoveShouldSetPanResponder: onMoveShouldSetPanResponder,
    onPanResponderGrant: onPanResponderGrant,
    onPanResponderMove: onPanResponderMove,
    onPanResponderRelease: onPanResponderRelease,
    onPanResponderTerminate: onPanResponderRelease,
  });
  const floatingRef = React.useRef<any>(null); // <any> should rather be e.g. <HTMLInputElement> (= random example of html element)
  const scrollViewRef = useRef<ScrollView>(null);

  let lastScrollY = useRef(0);
  const swipeCloseAnimation = useRef(new Animated.Value(0));
  const cardAnimation = useRef(new Animated.Value(0));
  const contentAnimation = useRef(new Animated.Value(0));
  let isHiding = useRef(false);
  let swipeMode = useRef(SWIPE_MODE.HORIZONTAL); // Should be uninitialized but don't know how, probably doesn't hurt

  useEffect(() => {
    // componentDidMount
    let backkHandlerEvent: NativeEventSubscription | null = null;
    if (platform.isAndroid) {
      backkHandlerEvent = BackHandler.addEventListener('hardwareBackPress', onPressHardwareBack);
    }

    return () => {
      // componentWillUnmount
      backkHandlerEvent && backkHandlerEvent.remove();
    };
  }, []);

  function onPressHardwareBack () {
    if (state.eventDetail.item) {
      hide();
      return true;
    }

    return false;
  }

  function onMoveShouldSetPanResponder (event: GestureResponderEvent, gestureState: PanResponderGestureState) {
    if (isHiding.current) {
      return false;
    }

    const {
      moveX,
      dx,
      dy,
    } = gestureState;

    if (moveX < 44 && dx > 4) {
      swipeMode.current = SWIPE_MODE.HORIZONTAL;
      return true;
    } if (lastScrollY.current === 0 && dy > 4) {
      swipeMode.current = SWIPE_MODE.VERTICAL;
      return true;
    }

    return false;
  }

  function onPanResponderGrant () {
    swipeCloseAnimation.current.setValue(0);
    // setHasSwipeCloseAnimation(true);
  }
  
  function onPanResponderMove (event: GestureResponderEvent, gestureState: PanResponderGestureState) {
    const move = swipeMode.current === SWIPE_MODE.HORIZONTAL ? gestureState.dx : gestureState.dy;

    if (move > SWIPE_CLOSE_THRESHOLD) {
      swipeHide();
      return;
    }

    swipeCloseAnimation.current.setValue(move / SWIPE_CLOSE_THRESHOLD);
  }

  function onPanResponderRelease (event: GestureResponderEvent, gestureState: PanResponderGestureState) {
    if (isHiding.current) return;

    const move = swipeMode.current === SWIPE_MODE.HORIZONTAL ? gestureState.dx : gestureState.dy;

    if (move >= SWIPE_CLOSE_THRESHOLD / 2) {
      swipeHide();
      return;
    }

    Animated.timing(swipeCloseAnimation.current, {
      toValue: 0,
      duration: 150,
      useNativeDriver: false
    }).start(() => {
      setHasSwipeCloseAnimation(false);
    });
  }

  function startShowAnimation () {

    if (!state.eventDetail.info) {
      return;
    }

    setStatusBarHidden(true, true ? 'slide' : 'none');

    cardAnimation.current.setValue(0);
    contentAnimation.current.setValue(0);

    Animated.sequence([
      Animated.timing(cardAnimation.current, {
        toValue: 1,
        duration: state.eventDetail.info.duration,
        useNativeDriver: false,
      }),
      Animated.timing(contentAnimation.current, {
        toValue: 1,
        duration: state.eventDetail.info.duration,
        useNativeDriver: false,
      }),
    ]).start();
  }

  function onPressClose () {
    hide();
  }

  function swipeHide() {
    if (isHiding.current) return;
    isHiding.current = true;

    Animated.timing(swipeCloseAnimation.current, {
      toValue: 2,
      duration: 250,
      // overshootClamping: true,
      useNativeDriver: false,
    }).start(() => {
      setStatusBarHidden(false, true ? 'slide' : 'none'); // show

      navigation.navigate('Events');
      dispatch({
        type: EventDetailActionType.EVENT_HIDE,
        payload: null,
      });

      isHiding.current = true;

      setHasSwipeCloseAnimation(false);
    });
  }

  function hide () {
    if (floatingRef.current) {
      floatingRef.current.hide(false)
    }

    if (lastScrollY.current > 0) {
      scrollViewRef.current && scrollViewRef.current.scrollTo({ y: 0, animated: true });
    }

    Animated.parallel([
      Animated.timing(contentAnimation.current, {
        toValue: 0,
        duration: state.eventDetail.info?.duration,
        useNativeDriver: false,
      }),
      Animated.spring(cardAnimation.current, {
        toValue: 0,
        tension: 25,
        overshootClamping: true,
        useNativeDriver: false
      }),
    ]).start(() => {
      setStatusBarHidden(false, true ? 'slide' : 'none'); // show
      // navigation.setOptions({ headerShown: true });

      navigation.navigate('Events');
      dispatch({
        type: EventDetailActionType.EVENT_HIDE,
        payload: null,
      });
    });
  }

  const headerHeight = useHeaderHeight();
  const bottomTabBarHeight = useBottomTabBarHeight(); // not in getRenderVariables because or wrong order rerenderings

  function getRenderVariables () {

    const containerStyle = [styles.container];
    
    if (state.eventDetail.info) {
      if (hasSwipeCloseAnimation) {
        // containerStyle.push({
        //   left: 0,
        //   top: swipeCloseAnimation.current.interpolate({
        //     inputRange: [0, 1, 2],
        //     outputRange: [0, 0, (Number(state.eventDetail.info.pageY) - 20)],
        //   }),
        //   width: platform.windowWidth,
        //   height: platform.windowHeight - useBottomTabBarHeight(),
        //   borderRadius: swipeCloseAnimation.current.interpolate({
        //     inputRange: [0, 1],
        //     outputRange: [0, 16],
        //   }),
        //   transform: [{
        //     scale: swipeCloseAnimation.current.interpolate({
        //       inputRange: [0, 1, 2],
        //       outputRange: [1, 0.9, 0.95],
        //     }),
        //   }],
        // });
      } else {
        containerStyle.push({
          // left: cardAnimation.current.interpolate({
          //   inputRange: [0, 0.7, 1],
          //   outputRange: [state.eventDetail.info.pageX, -20, 0],
          // }),
          top: cardAnimation.current.interpolate({
            inputRange: [0, 0.7, 1],
            outputRange: [state.eventDetail.info.pageY-headerHeight, -20, 0],
          }),
          width: cardAnimation.current.interpolate({
            inputRange: [0, 0.7, 1],
            outputRange: [state.eventDetail.info.width, state.eventDetail.info.windowWidth + 40, state.eventDetail.info.windowWidth],
          }),
          height: cardAnimation.current.interpolate({
            inputRange: [0, 1],
            outputRange: [state.eventDetail.info.height, state.eventDetail.info.windowHeight-bottomTabBarHeight],
          }),
          borderRadius: cardAnimation.current.interpolate({
            inputRange: [0, 1],
            outputRange: [16, 0],
          }),
        });
      }
    }

    return {
      containerStyle,
    };
  }

  if (!state.eventDetail.item) return null;

  const {
    containerStyle,
  } = getRenderVariables();
  
  if (shouldStartShowAnimation) {
    // navigation.setOptions({ headerShown: false });
    startShowAnimation();
    setShouldStartShowAnimation(false);
  }

  function transferOwner () {
    if (!state.eventDetail.item) return;
    
    axios({
      method: 'POST',
      url: getPath(PostType.EVENT)+'/'+state.eventDetail.item.uuid+'/transfer',
      data: {
        newOwner: transferEmail,
      }
    })
    .then(function (response) {
    })
    .catch(function (error) {
    })
    .then(function () {
      // always executed
    });
  };
  
  function editPost () {
    if (!state.eventDetail.item) return;

    const item = state.eventDetail.item;

    dispatch({
      type: EventDetailActionType.EVENT_HIDE,
      payload: null,
    });

    axios({
      method: 'PUT',
      url: getPath(PostType.EVENT)+'/'+item.uuid+'/draft/false',
    })
    .then(function (response) {
      // do a temporary local update of the posts
      dispatch({
        type: EventActionType.EVENT_REMOVED,
        payload: item.uuid,
      });
      navigation.navigate('Post', {type: PostType.EVENT}); // this is edit-screen, so ok
    })
    .catch(function (error) {
      console.log(error.response.data.message);
    })
    .then(function () {
    });
  };

  function removePost () {
    if (!state.eventDetail.item) return;

    const item = state.eventDetail.item;
    
    navigation.navigate('Events');
    dispatch({
      type: EventDetailActionType.EVENT_HIDE,
      payload: null,
    });
    
    // Delete images
    item.imageUuids.map(imageUuid => {
      axios({
        method: 'DELETE',
        url: grillImageServerUrl+'/'+imageUuid.uuid,
      })
      .catch(function (error) {
        console.log(error.response.data.message);
      })
    });

    
    // Delete addresses
    item.payload.locationUuids.map(locationUuid => {
      axios({
        method: 'DELETE',
        url: path.addresses+'/'+locationUuid,
      })
      .catch(function (error) {
        console.log(error.response.data.message);
      })
    });
    
    // Delete post
    axios({
      method: 'DELETE',
      url: getPath(PostType.EVENT)+'/'+item.uuid,
    })
    .then(function (response) {
      dispatch({
        type: EventActionType.EVENT_REMOVED,
        payload: item.uuid,
      });
    })
    .catch(function (error) {
      console.log(error.response.data.message);
    })
    .then(function () {
      // always executed
    });
  };

  // Prep meta content
  const imageUuid = state.eventDetail.item.imageUuids.find((imageUuid) => imageUuid.mainImage);
  const descriptionLength = Math.min(state.eventDetail.item.payload.detailText.length, 200);
  const meta = {
    title: state.eventDetail.item.payload.mainText,
    author: state.eventDetail.item.author,
    description: state.eventDetail.item.payload.detailText.substring(0, descriptionLength),
    image: imageUuid ? grillImageServerUrl+'/'+imageUuid.uuid+'/fl_ls' : '',
    url: hostnameFrontEnd+'events/'+state.eventDetail.item.uuid,
    type: 'summary_large_image',
    // keywords: 'KEYWORDS_PLACEHOLDER',
    // slug: 'SLUG_PLACEHOLDER'
  };

  return (
    <Animated.View style={containerStyle} {...panResponder.panHandlers}>
      <Helmet>
        <title>{meta.title}</title>
        <meta  name="description" content={meta.description} />
        {/* <meta name="keywords" content={meta.keywords} /> */}

        <meta property="og:title" content={meta.title} />
        <meta name="description" content={meta.description} />
        <meta property="og:description" content={meta.description} />
        <meta property="og:image" content={meta.image} />
        <meta property="og:url" content={meta.url} />
        <meta property="og:type" content={meta.type} />

        <meta name="twitter:card" content={meta.type} />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
        <meta name="twitter:image" content={meta.image} />
        
        <meta name="author" content={meta.author} />
        {/* <link rel="canonical" href={slug} /> */}
      </Helmet>
      <ScrollView
        ref={scrollViewRef}
        contentContainerStyle={styles.contentContainer}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        pinchGestureEnabled={false}
        bounces={false}
        scrollEventThrottle={16}
      >
        <EventCardContent item={state.eventDetail.item} detailMode={true}/>
        <EventDetailCardContent
          item={state.eventDetail.item}
          contentAnimation={contentAnimation.current}
        />
        {state.user && state.user.admin && <TextInput style={styles.textinput} value={transferEmail} placeholder={i18n.t('transfer')} onChangeText={setTransferEmail} />}
        {state.user && state.user.admin && <WhiteButton text={i18n.t('transfer')} small={true} icon='send' onPress={transferOwner}></WhiteButton>}
        {state.eventDetail.item.author==state.auth.uuid && <WhiteButton text={i18n.t('edit')} small={true} icon='edit' onPress={editPost}></WhiteButton>}
        {state.eventDetail.item.author==state.auth.uuid && <WhiteButton text={i18n.t('remove')} small={true} icon='trash-o'  onPress={()=>AlertModal({
            title: i18n.t('areyousure'),
            message: i18n.t('willberemoved'),
            action: removePost,
            dispatch: dispatch,
          })}></WhiteButton>}
      </ScrollView>

      <PostDetailCardClose
        cardAnimation={cardAnimation.current}
        onPress={onPressClose}
      />
    </Animated.View>
  );
};

// usage: {i18n.t('loginorregister1')}
const i18n = new I18n({
  nl: {
    transfer: 'Transfer',
    edit: 'Bewerk',
    remove: 'Verwijder',
    areyousure: 'Ben je zeker?',
    willberemoved: 'Jouw organisatie wordt permanent verwijderd',
  },
  fr: {
  },
  en: {
    transfer: 'Transfer',
    edit: 'Edit',
    remove: 'Remove',
    areyousure: 'Are you sure?',
    willberemoved: 'Your organization will be removed permanently',
  },
});
i18n.locale = Localization.locale; // this should happen only once
i18n.enableFallback = true;
// => causes fallback to default below
i18n.locale = 'nl';

