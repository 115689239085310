import { Linking, StyleSheet, TextInput, TouchableOpacity } from "react-native";
import { PostType } from "../types/types";
import { FontAwesome } from '@expo/vector-icons';
import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js';
import { useThemeColor, View } from "./Themed";
import { imagePadding, minimumTextInputHeightRegular } from "../hooks/usePlatform";
import { HeeboRegularTextMuted } from "./StyledText";

export function SearchHeader(props: {
  type: PostType,
  setPage: Function,
  searchActive: Boolean,
  setSearchActive: Function,
  query: string,
  setQuery: Function,
}) {
  
  const styles = StyleSheet.create({
    container: {
      paddingHorizontal: imagePadding,
      flexDirection: 'row',
      color: useThemeColor({}, 'textinverse'),
      paddingVertical: 5,
      // borderWidth: 1, // causes scrollbars to appear in multi-line
      borderRadius: 16,
      marginVertical: 10,
      backgroundColor: useThemeColor({}, 'textinverse'),
      width: '100%',
    },
    textinputwrapper: {
      width: '100%',
      height: '100%',
      paddingLeft: 25,
    },
    textinput: {
      fontSize: 16,
      fontFamily: 'Heebo Regular',
      color: useThemeColor({}, 'text'),
      width: '100%',
      height: minimumTextInputHeightRegular,
    },
    icon: {
      fontSize: 16,
      color: useThemeColor({}, 'text'),
      alignSelf: 'center',
    },
    topText: {
      fontSize: 12,
      textAlign: 'center',
    },
    link: {
      fontSize: 12,
      textDecorationLine: 'underline',
    },
    horizontal: {
      paddingTop: 5,
      flexDirection: 'row',
      textAlign: 'center',
      justifyContent: 'center',
    },
  });

  function queryUpdated(newQuery: string) {
    props.setQuery(newQuery);
    if (newQuery==='') {
      if (props.searchActive) {
        props.setSearchActive(false);
        props.setPage(0);
      }
      return;
    } else {
      props.setSearchActive(true);
      props.setPage(0);
    }
  };

  let invitation = '';
  if (props.type==PostType.BUSINESS) invitation = i18n.t('yourbusiness');
  if (props.type==PostType.EVENT) invitation = i18n.t('yourevent');
  if (props.type==PostType.CLUB) invitation = i18n.t('yourclub');
  
  return (
    <View>
      {invitation && <View style={styles.horizontal}>
        <HeeboRegularTextMuted style={styles.topText}>{invitation} </HeeboRegularTextMuted>
        <TouchableOpacity onPress={()=>Linking.openURL('mailto:info@grill-community.com')}><HeeboRegularTextMuted style={styles.link}>info@grill-community.com</HeeboRegularTextMuted></TouchableOpacity>
      </View>}
      <View style={styles.container}>
        <FontAwesome style={styles.icon} name='search' />
        <View style={styles.textinputwrapper}>
          <TextInput
            style={styles.textinput}
            placeholder={i18n.t('placeholder')}
            placeholderTextColor={useThemeColor({}, 'textmuted')}
            value={props.query}
            onChangeText={queryUpdated}
          />
        </View>
      </View>
    </View>
  );
};

// usage: {i18n.t('loginorregister1')}
const i18n = new I18n({
  nl: {
    yourbusiness: 'Uw organizatie in GRILL? Contacteer ',
    yourevent: 'Uw event in GRILL? Contacteer ',
    yourclub: 'Uw club in GRILL? Contacteer ',
    placeholder: 'Naam, merk, onderwerp, ...',
  },
  fr: {
  },
  en: {
    yourbusiness: 'Your organization in GRILL? Contact ',
    yourevent: 'Your event in GRILL? Contact ',
    yourclub: 'Your club in GRILL? Contact ',
    placeholder: 'Name, brand, topic, ...',
  },
});
i18n.locale = Localization.locale; // this should happen only once
i18n.enableFallback = true;
// => causes fallback to default below
i18n.locale = 'nl';
