import React, { useContext } from 'react';
import { StyleSheet } from 'react-native';
import { useThemeColor, View } from './Themed';

import { PostActionType, PostSocial } from '../contexts/reducers/postListReducer';
import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js';
import { AppContext } from '../contexts';
import SocialBarLike from './SocialBarLike';
import SocialBarComment from './SocialBarComment';
import { HeeboBoldTextHuge, HeeboRegularTextMuted, HeeboBoldTextLarge } from './StyledText';
import timeAgo from '../util/TimeAgo';
import { AlertModal, AlertConfig } from "../components/AlertModal";
import axios from 'axios';
import { userPostListHelper } from '../hooks/usePostListHelper';
import { useNavigation } from '@react-navigation/native';
import { RootTabScreenProps } from '../types';
import { getPath, grillImageServerUrl } from '../env/env';
import { FontAwesome } from '@expo/vector-icons';
import { PostDetailActionType } from '../contexts/reducers/postDetailReducer';
import { PostType } from '../types/types';


export default function PostListCardContentPost (props: {
  social: PostSocial,
  uuid: string,
  detailMode: boolean,
  type: PostType,
}) {
  
  const { state, dispatch } = useContext(AppContext);
  const navigation = useNavigation<RootTabScreenProps<'Feed'>['navigation']>();

  const {
    social,
    uuid,
    detailMode,
  } = props;

  const styles = StyleSheet.create({
    container: {
      padding: 16,
    },
    horizontal: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    containerIsDetailMode: {
      paddingBottom: 0,
    },
    categoryText: {
      color: useThemeColor({}, 'textmuted'),
    },
    mainText: {
      fontWeight: 'bold',
      marginVertical: 4,
    },
    linkText: {
      color: useThemeColor({}, 'linkinverse'),
      fontStyle: 'italic',
    },
    link: {
      color: useThemeColor({}, 'link'),
      textDecorationLine: 'underline',
    },
    reportHide: {
    },
    separator: {
      marginVertical: 30,
      height: 1,
      width: '80%',
    },
  });
  
  const post = userPostListHelper(props.uuid);
  if (!post) return null;
  
  function editPost () {
    if (!post) return;

    dispatch({
      type: PostDetailActionType.POST_HIDE,
      payload: null,
    });

    axios({
      method: 'PUT',
      url: getPath(props.type)+'/'+uuid+'/draft/false',
    })
    .then(function (response) {
      // do a temporary local update of the posts
      dispatch({
        type: PostActionType.POST_REMOVED,
        payload: uuid,
      });
      navigation.navigate('Post', {type: PostType.POST});
    })
    .catch(function (error) {
      console.log(error.response.data.message);
    })
    .then(function () {
    });
  };

  function removePost () {
    if (!post) return;
    
    navigation.navigate('Feed');
    dispatch({
      type: PostDetailActionType.POST_HIDE,
      payload: null,
    });
    
    // Delete images
    post.imageUuids.map(imageUuid => {
      axios({
        method: 'DELETE',
        url: grillImageServerUrl+'/'+imageUuid.uuid,
      })
      .catch(function (error) {
        console.log(error.response.data.message);
      })
    });

    // Delete post
    axios({
      method: 'DELETE',
      url: getPath(props.type)+'/'+uuid,
    })
    .then(function (response) {
      dispatch({
        type: PostActionType.POST_REMOVED,
        payload: uuid,
      });
    })
    .catch(function (error) {
      console.log(error.response.data.message);
    })
    .then(function () {
      // always executed
    });
  };

  const timeAgoString = timeAgo(new Date(post.createdAt)); // type Date turned into String somehow

  const postAsString = post.postAs && post.postAs.postType!=PostType.UNDEFINED ? post.postAs.name : post.firstName+' '+post.lastName;

  return (
    <View style={[styles.container, detailMode && styles.containerIsDetailMode]}>
      <View>
        <HeeboRegularTextMuted style={styles.categoryText}>{postAsString} - {timeAgoString}
          {post.author==state.auth.uuid && <HeeboRegularTextMuted>&nbsp;-&nbsp;<FontAwesome style={styles.reportHide} onPress={editPost} name='edit' />&nbsp;<FontAwesome style={styles.reportHide} onPress={()=>AlertModal({
            title: i18n.t('areyousure'),
            message: i18n.t('willberemoved'),
            action: removePost,
            dispatch: dispatch,
          })} name='trash-o' /></HeeboRegularTextMuted>}
        </HeeboRegularTextMuted>
        <HeeboRegularTextMuted style={styles.categoryText}>
          <SocialBarLike social={social} postId={uuid} />
          {/* <SocialBarShare uri={hostnameFrontEnd+uuid} /> */}
          {!state.auth.uuid && <HeeboRegularTextMuted style={styles.categoryText} onPress={() => navigation.navigate('Profile')}>&nbsp;-&nbsp;<HeeboRegularTextMuted style={[styles.categoryText, styles.link]}>Log in</HeeboRegularTextMuted> to like or comment</HeeboRegularTextMuted>}
        </HeeboRegularTextMuted>
      </View>
      {detailMode ?
        <HeeboBoldTextHuge style={styles.mainText}>{post.payload.mainText}</HeeboBoldTextHuge>
        : 
        <HeeboBoldTextLarge style={styles.mainText}>{post.payload.mainText}</HeeboBoldTextLarge>
      }
      {/* {post.payload.externalLink!='' && <TouchableOpacity onPress={() => WebBrowser.openBrowserAsync(post.payload.externalLink)}>
        <HeeboRegularText style={styles.linkText}>Read original article</HeeboRegularText>
      </TouchableOpacity>} */}
      {/* <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" /> */}
      <SocialBarComment detailMode={detailMode} social={social} postId={uuid} />
    </View>
  );
};

// usage: {i18n.t('loginorregister1')}
const i18n = new I18n({
  nl: {
    areyousure: 'Ben je zeker?',
    willberemoved: 'Jouw post wordt permanent verwijderd',
  },
  fr: {
  },
  en: {
    areyousure: 'Are you sure?',
    willberemoved: 'Your post will be removed permanently',
  },
});
i18n.locale = Localization.locale; // this should happen only once
i18n.enableFallback = true;
// => causes fallback to default below
i18n.locale = 'nl';
