import { StyleSheet } from 'react-native';
import PostList from '../components/PostList';
import PostDetailCard from '../components/PostDetailCard';
import { View } from '../components/Themed';
import { RootTabScreenProps } from '../types';
import { useFocusEffect } from '@react-navigation/native';
import React, { useContext, useState } from 'react';
import { PostDetailActionType } from '../contexts/reducers/postDetailReducer';
import { AppContext } from '../contexts';

export default function PostsScreen({ navigation, route }: RootTabScreenProps<'Feed'>) {
  const { state, dispatch } = useContext(AppContext);
  
  const [ focusOnUuid, setFocusOnUuid ] = useState('');

  useFocusEffect(
    React.useCallback(() => {
      setFocusOnUuid(route.params && route.params.uuid ? route.params.uuid : '');
      return () => {
        setFocusOnUuid('');
        dispatch({
          type: PostDetailActionType.POST_HIDE,
          payload: null,
        });
      }
    }, [route.params?.uuid])
  );

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
    },
  });

  return (
    <View style={styles.container}>
      <PostList focusOnUuid={focusOnUuid} />
      <PostDetailCard />
    </View>
  );
}
