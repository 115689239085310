import React, { useContext, useState } from 'react';
import { View, StyleSheet, Text, TextInput } from 'react-native';

import { PostActionType, PostLikeUpdate, PostSocial } from '../contexts/reducers/postListReducer';
import { FontAwesome } from '@expo/vector-icons';
import { AppContext } from '../contexts';
import { LogoColors, useThemeColor } from './Themed';
import { HeeboRegularTextMuted } from './StyledText';
import axios from 'axios';
import { getPath, path } from '../env/env';
import { PostType } from '../types/types';

export default function SocialBarLike (props: {
  social: PostSocial,
  postId: string,
}) {

  const { state, dispatch } = useContext(AppContext);

  const {
    social,
    postId
  } = props;

  const styles = StyleSheet.create({
    socialBar: {
      marginVertical: 4,
      color: useThemeColor({}, 'textmuted'),
    },
    likeButton: {
      fontWeight: 'bold',
      color: useThemeColor({}, 'textmuted'),
    },
    likedButton: {
      fontWeight: 'bold',
      color: useThemeColor({}, 'link'),
    },
    likeIcon: {
      // { marginBottom: -3 }
    },
  });

  function handleLikeButton () {
    if (!state.auth.uuid) return;

    const newLikeStatus = !liked;
    const payload : PostLikeUpdate = {
      postId: postId,
      userUuid: state.auth.uuid,
      userFirstName: state.user.firstName,
      userLastName: state.user.lastName,
      liked: newLikeStatus,
    }
    dispatch({
      type: PostActionType.POST_LIKED,
      payload: payload,
    });

    axios({
      method: newLikeStatus ? 'POST' : 'DELETE',
      url: getPath(PostType.POST)+'/'+postId+'/like/',
    })
    .then(function (response) {

    })
    .catch(function (error) {
      console.log('Like call failed');
    })
    .then(function () {
      // always executed
    });
  };

  const likes = social.likes.length;
  const liked = social.likes.find(comment => comment.userUuid == state.auth.uuid) ? true : false;
  const textPart1 = likes==0 ?
    '': // 'Be the first to like this post'
    ''+likes+' like'+(likes>1 ? 's': '')+'  -  '; // 'This post has '
  const textPart2 = liked ? 'Liked' : 'Like';

  return (
    <HeeboRegularTextMuted style={styles.socialBar}>{textPart1}<HeeboRegularTextMuted style={liked ? styles.likedButton : styles.likeButton} onPress={handleLikeButton}><FontAwesome style={styles.likeIcon} name='heart' /> {textPart2}</HeeboRegularTextMuted></HeeboRegularTextMuted>
  );
};
