import { useState, useEffect } from "react";
import AsyncStorage from '@react-native-async-storage/async-storage';

import { initialAuthState } from "../contexts/reducers/authReducer";

export function useAsyncStorage() {
  const [state, setState] = useState({authStateFromLocalStorage: initialAuthState, cookiesAccepted: false, isAsyncStorageReady: false});

  const [localAuthState, setLocalAuthState] = useState({
    state: initialAuthState,
    ready: false,
  });
  const [cookiesAccepted, setCookiesAccepted] = useState({
    state: false,
    ready: false,
  });

  useEffect(() => {
    AsyncStorage.getItem("authState").then((value:string|null) => {
      setLocalAuthState({
        state: value ? JSON.parse(value) : null,
        ready: true,
      });
    })
  }, []);

  useEffect(() => {
    AsyncStorage.getItem("cookiesAccepted").then((value:string|null) => {
      setCookiesAccepted({
        state: value ? JSON.parse(value).cookiesAccepted : false,
        ready: true,
      });
    })
  }, []);


  useEffect(() => {
    if (localAuthState.ready && cookiesAccepted.ready) setState({
      authStateFromLocalStorage: localAuthState.state,
      cookiesAccepted: cookiesAccepted.state,
      isAsyncStorageReady: true,
    });
  }, [localAuthState.ready, cookiesAccepted.ready]);

  return state;
};
