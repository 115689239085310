import { StyleSheet } from 'react-native';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { BackgroundGradient, ThemedTextInput, View } from '../components/Themed';
import { HeeboBoldText, HeeboBoldTextLarge, HeeboRegularText, HeeboRegularTextMuted } from '../components/StyledText';
import { WhiteButton } from '../components/WhiteButton';
import { path } from '../env/env';
import { RootStackScreenProps } from '../types';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { CommonActions } from '@react-navigation/native';


export default function ActivateAccountScreen({ navigation, route }: RootStackScreenProps<'Reset'>) {
  const [ newPassword, setNewPassword ] = useState('');
  const [ passwordReset, setPasswordReset ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');

  // Add back button when coming here directly (empty navigation history)
  useEffect(
    () => {
      if (navigation.getState().routes.length>1) return;

      navigation.dispatch(navigationState => {
        const currentNavigationState = navigationState; // This can also directly be accessed with navigation.getState()
    
        const routes = [{name: 'GRILL'}, ...currentNavigationState.routes];
    
        const newNavigationState = {
          ...navigationState,
          routes,
          index: routes.length - 1,
        };
      
        return CommonActions.reset(newNavigationState);
      });
    }, [],
  );

  function resetPassword () {
    if (!route.params || !route.params.uuid || !route.params.verifyString) {
      setError(true);
      setErrorMessage('Your password could not be reset.');
      return;
    }

    axios({
      method: 'POST',
      url: path.resetpassword+'/'+route.params.uuid+'/'+route.params.verifyString,
      data: {password: newPassword},
    })
    .then(function (response) {
      setPasswordReset(true);
      setError(false);
      setErrorMessage('');
    })
    .catch(function (error) {
      setError(true);
      setErrorMessage(error.response.data.message);
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  };

  return (
    <SafeAreaProvider>
      <BackgroundGradient />
      <View style={styles.container}>
        {!passwordReset && <ThemedTextInput
          editable={true}
          onChangeText={newPassword => setNewPassword(newPassword)}
          placeholder="Your new password"
          // ref="password"
          returnKeyType="next"
          secureTextEntry={true}
          textContentType={'password'} // for automatic storage of login info
          value={newPassword}
        />}
        {!passwordReset && <WhiteButton onPress={resetPassword} text='Reset password'/>}
        {passwordReset && <HeeboBoldTextLarge style={styles.center}>Password reset!</HeeboBoldTextLarge>}
        {passwordReset && <HeeboBoldText style={styles.center}>You can now log in to GRILL</HeeboBoldText>}
        {passwordReset && <WhiteButton onPress={() => navigation.navigate('Profile')} text='Log in!' />}
        {error && <HeeboRegularText style={styles.center}>{errorMessage}</HeeboRegularText>}
        {error && <HeeboRegularTextMuted style={styles.center}>If this problem persists, please contact info@grill-community.com</HeeboRegularTextMuted>}
      </View>
    </SafeAreaProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  center: {
    textAlign: 'center',
  },
});
