import { StyleSheet, Animated, TouchableWithoutFeedback } from 'react-native';
import React from 'react';

import { useThemeColor } from './Themed';
import { FontAwesome } from '@expo/vector-icons';

export default function CarouselArrow (props:{
  orientation: string,
  onPress: () => void,
}) {

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      right: props.orientation=='right' ? 0 : 'auto',
      left: props.orientation=='left' ? 0 : 'auto',
      bottom: 10,
      justifyContent: 'center',
      alignItems: 'center',
      // opacity: 0.5,
      // backgroundColor: useThemeColor({}, 'text'),
      borderRadius: 14,
      width: 20,
      height: 20,
    },
    buttonIcon: {
      color: useThemeColor({}, 'alt2dark'),
      fontSize: 24,
    },
  });
  
  const iconName = 'angle-'+props.orientation;

  return (
    <TouchableWithoutFeedback onPress={props.onPress}>
      <Animated.View style={styles.container}>
        <FontAwesome style={styles.buttonIcon} name={iconName} />
      </Animated.View>
    </TouchableWithoutFeedback>
  );
};
