import { useEffect, useState } from 'react';
import { useWindowDimensions, Platform as NativePlatform, Dimensions, ScaledSize } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import Constants from 'expo-constants';

export const maxWidth = 800;
export const imagePadding = 12;
export const minimumTextInputHeightRegular = 18;
export const minimumTextInputHeightLarge = 24;

export type PlatformStateType = {
  isAndroid: boolean,
  isIOS: boolean,
  isOveriPhoneX: boolean,
  windowWidth: number,
  maxWidthReached: boolean,
  windowHeight: number,
  statusBarHeight: number,
  normalize: Function,
};

const calculatePlatform = (window: ScaledSize) => {
  const maxWidthReached = window.width >= maxWidth + 2 * imagePadding;
  const isAndroid = NativePlatform.OS === 'android' ? true : false;
  const isPad = NativePlatform.OS === 'ios' ? NativePlatform.isPad : false;
  const isOveriPhoneX = !isAndroid && !isPad && !NativePlatform.isTV && window.height >= 812;
  const iPhoneStatusBarHeight = isOveriPhoneX ? 40 : 20;
  const windowWidth = maxWidthReached ? maxWidth : window.width;
  const currentHeight = NativePlatform.OS === 'android' ? Constants.statusBarHeight  : 0 ;
  const windowHeight = window.height - (currentHeight ? currentHeight : 0);
  const deviceScale = Math.min(480, windowWidth) / 414;
  
  const platform: PlatformStateType = {
    maxWidthReached: maxWidthReached,
    isAndroid: isAndroid,
    isIOS: !isAndroid,
    isOveriPhoneX: isOveriPhoneX,
    windowWidth: windowWidth,
    windowHeight: windowHeight,
    statusBarHeight: (isAndroid && Constants.statusBarHeight) ? Constants.statusBarHeight : iPhoneStatusBarHeight,
    normalize: (size: number) => { return Math.round(deviceScale * size) },
  };

  return platform;
};

const initialWindow = Dimensions.get('window');
const initialPlatform = calculatePlatform(initialWindow);

export default function usePlatform () {
  const [ platform, setPlatform] = useState(initialPlatform);

  const window = useWindowDimensions();

  useEffect(() => {
    setPlatform(calculatePlatform(window));
  }, [window]);

  return platform;
};
