import { StyleSheet, Animated, TouchableOpacity } from 'react-native';
import React from 'react';
import { HeeboBoldTextSmall } from '../StyledText';
import { useThemeColor } from '../Themed';

export default function BusinessDetailCardContentButton (props:{
  text: string
  orientation: string,
  onPress: () => void,
}) {

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      right: props.orientation=='right' ? 0 : 'auto',
      left: props.orientation=='left' ? 0 : 'auto',
      bottom: 10,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 20,
      backgroundColor: useThemeColor({}, 'textinverse'),
      paddingVertical: 7,
      paddingHorizontal: 25,
      marginHorizontal: 20,
    },
  });

  return (
    <TouchableOpacity onPress={props.onPress}>
      <Animated.View style={styles.container}>
        <HeeboBoldTextSmall>{props.text}</HeeboBoldTextSmall>
      </Animated.View>
    </TouchableOpacity>
  );
};
