import { FlatList, StyleSheet } from 'react-native';
import { useContext, useEffect, useState } from 'react';

import { AppContext } from "../../contexts";
import axios from 'axios';
import { getPath, path } from '../../env/env';
import usePlatform, { imagePadding } from '../../hooks/usePlatform';
import { PostType } from '../../types/types';
import { EventListCard } from './EventListCard';
import { EventActionType, EventStateType } from '../../contexts/reducers/eventListReducer';
import { EventListRefreshActionType } from '../../contexts/reducers/eventListRefreshReducer';
import { SearchHeader } from '../SearchHeader';

export default function EventList (props: {focusOnUuid: string}) {
  const { state, dispatch } = useContext(AppContext);
  const [ page, setPage ] = useState(0);
  const [ query, setQuery ] = useState('');
  const [ searchActive, setSearchActive ] = useState(false);

  const styles = StyleSheet.create({
    listContent: {
      paddingHorizontal: imagePadding,
      width: usePlatform().windowWidth,
    },
  });

  const pageSize = 0; // 0 = use server defaults

  const type = PostType.EVENT;

  const keyExtractor = (item: EventStateType) => `EventCard-${item.uuid}`;
  const renderItem = ({ item }: { item: EventStateType }) => <EventListCard item={item} focus={item.uuid==props.focusOnUuid?true:false} />;
  
  const path = getPath(type);

  function refresh () {
    setPage(0);
  };

  function nextPage () {
    setPage(page => page+1);
  };

  useEffect(
    () => {
      const queryPath = searchActive ? path+'/search/'+query : path;

      dispatch({
        type: EventListRefreshActionType.EVENTS_REFRESH,
        payload: null,
      });
      axios({
        method: 'GET',
        url: queryPath,
        params: {
          page: page,
          ...(pageSize>0 && {pagesize: pageSize}), // only add when not 0, careful with casing
        },
      })
      .then(function (response) {
        if (response.data.items) {
          dispatch({
            type: page==0 ? EventActionType.EVENT_FETCHED_ON_MOUNT : EventActionType.EVENT_FETCHED,
            payload: response.data.items,
          });
        } else {
          console.log('No more found');
        }
      })
      .catch(function (error) {
        console.log('Error fetching with error: ');
        console.log(error);
      })
      .then(function () {
        // always executed
        dispatch({
          type: EventListRefreshActionType.EVENTS_REFRESHED,
          payload: null,
        });
      });
    }, [page, query],
  );

  return (
    <FlatList
      data={state.eventList}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      pinchGestureEnabled={false}
      contentContainerStyle={styles.listContent}
      onRefresh={refresh}
      refreshing={state.eventListRefresh.refreshing}
      onEndReached={nextPage}
      onEndReachedThreshold={1}
      ListHeaderComponent={<SearchHeader type={type} setPage={setPage} searchActive={searchActive} setSearchActive={setSearchActive} query={query} setQuery={setQuery} />}
    />
  );
};
