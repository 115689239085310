import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { StyleSheet, TextInput, TouchableOpacity } from "react-native";
import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js';
import { Method, path } from "../env/env";
import { imagePadding } from "../hooks/usePlatform";
import { Address } from "../types/types";
import { AlertModal, AlertConfig } from "./AlertModal";
import Spinner from "./Spinner";
import { HeeboBoldText, HeeboBoldTextSmall, HeeboRegularTextSmall } from "./StyledText";
import { useThemeColor, View } from "./Themed";
import { AppContext } from "../contexts";
import { FontAwesome } from "@expo/vector-icons";

export default function SmartAddressInput (props:{
  pathAttribute: string,
  locationUuids: string[],
  addressAddedRemoved: Function,
  savedCallback: Function,
}) {
  const { state, dispatch } = useContext(AppContext);
  const [ addresses, setAddresses ] = useState<Address[]>([]);
  const [ index, setIndex ] = useState(-1);
  const [ draft, setDraft ] = useState<Address|null>(null);

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      width: '100%',
      paddingHorizontal: imagePadding,
      flexDirection: 'column',
      justifyContent: 'center',
    },
    round: {
      marginVertical: 5,
      alignSelf: 'center',
      width: 40,
      height: 40,
      borderRadius: 20,
      backgroundColor: useThemeColor({}, 'text'),
      justifyContent: 'center',
      textAlign: 'center',
    },
    icon: {
      fontSize: 32,
      color: useThemeColor({}, 'textinverse'),
    },
    iconText: {
      alignSelf: 'center',
      textAlign: 'center',
    },
    textinput: {
      borderRadius: 15,
      borderColor: useThemeColor({}, 'text'),
      borderWidth: 1,
      padding: 3,
      margin: 1,
      textAlign: 'center',
    },
    buttonrow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    addressContainer: {
      marginVertical: 20,
      backgroundColor: useThemeColor({}, 'bluelight'),
      borderRadius: 20,
      paddingHorizontal: 10,
      paddingVertical: 10,
    },
  });

  function addAddress() {
    axios({
      method: Method.POST,
      url: path.addresses,
    })
    .then(function (response) {
      setAddresses([]);
      setDraft(null);
      setIndex(-1);
      props.addressAddedRemoved(Method.POST, response.data.item.uuid);
    })
    .catch(function (error) {
      console.log(error.response.data.message);
    })
    .then(function () {
      // always executed
    });
  };

  function saveAddress() {
    if (!draft) return;

    axios({
      method: Method.PUT,
      url: path.addresses+'/'+draft.uuid,
      data: draft,
    })
    .then(function (response) {
      const i = addresses.findIndex((address => address.uuid === draft.uuid));
      const updatedAddresses = [...addresses];
      updatedAddresses[i] = draft;
      setAddresses(updatedAddresses);
      setDraft(null);
      setIndex(-1);
      props.savedCallback(true);
    })
    .catch(function (error) {
      console.log(error.response.data.message);
    })
    .then(function () {
      // always executed
    });
  };

  function deleteAddress(uuid: string) {

    axios({
      method: Method.DELETE,
      url: path.addresses+'/'+uuid,
    })
    .then(function (response) {
      setAddresses([]);
      setDraft(null);
      setIndex(-1);
      props.addressAddedRemoved(Method.DELETE, uuid);
    })
    .catch(function (error) {
      console.log(error.response.data.message);
    })
    .then(function () {
      // always executed
    });
  };

  function startEditingAddress(uuid: string) {    
    const i = addresses.findIndex((address => address.uuid === uuid));
    setDraft(addresses[i]);
    setIndex(i);
    props.savedCallback(false);
  };

  // Fetch all addresses based in locationUuids
  useEffect(() => {
    setAddresses([]);
    setIndex(-1);
    setDraft(null);

    // add first draft address if there are none and return
    if (props.locationUuids.length===0) return addAddress();

    props.locationUuids.map(locationUuid => {
      axios({
        method: 'GET',
        url: path.addresses+'/'+locationUuid,
      })
      .then(function (response) {
        if (!response.data.item) return;
        if (response.data.item.name==='') {
          props.savedCallback(false);
          setIndex(addresses.length);
          setDraft(response.data.item);
        }
        setAddresses(prevAddresses => [...prevAddresses, response.data.item]);
      })
      .catch(function (error) {
        console.log(error.response.data.message);
      })
      .then(function () {
        // always executed
      });
    });
  }, [props.locationUuids]);

  useEffect(() => {
  }, [props.locationUuids, addresses]);

  const placeholderTextColor = useThemeColor({}, 'textmuted');
  function getComponents() {

    const components : JSX.Element[] = [];

    addresses.map((address, index) => {
      if (address.name!=='') {
        components.push(<View style={styles.addressContainer} key={'addresslist-'+address.uuid}>
          <HeeboBoldText>{address.name}</HeeboBoldText>
          <HeeboRegularTextSmall>{address.street} {address.number}</HeeboRegularTextSmall>
          <HeeboRegularTextSmall>{address.zip} {address.city}</HeeboRegularTextSmall>
          <HeeboRegularTextSmall>{address.country}</HeeboRegularTextSmall>
          <HeeboRegularTextSmall>{address.email}</HeeboRegularTextSmall>
          <HeeboRegularTextSmall>{address.phone}</HeeboRegularTextSmall>
          <View style={styles.buttonrow}>
            <TouchableOpacity onPress={()=>startEditingAddress(address.uuid)}><HeeboRegularTextSmall>
              <FontAwesome name='edit' /> {i18n.t('edit')}
            </HeeboRegularTextSmall></TouchableOpacity>
            <TouchableOpacity onPress={()=>AlertModal({
                title: i18n.t('areyousure'),
                message: i18n.t('willberemoved'),
                action: ()=>deleteAddress(address.uuid),
                dispatch: dispatch,
              })}>
              <HeeboRegularTextSmall><FontAwesome name='trash' /> {i18n.t('delete')}</HeeboRegularTextSmall>
            </TouchableOpacity>
          </View>
        </View>);
      }
    });

    if (index>-1 && draft) {
      components.push(<View style={styles.addressContainer} key={'addresslist-draft-'+draft.uuid}>
        <TextInput style={styles.textinput} placeholderTextColor={placeholderTextColor} value={draft.name} placeholder={i18n.t('name')} onChangeText={onNameChange} />
        <TextInput style={styles.textinput} placeholderTextColor={placeholderTextColor} value={draft.street} placeholder={i18n.t('street')} onChangeText={onStreetChange} />
        <TextInput style={styles.textinput} placeholderTextColor={placeholderTextColor} value={draft.number} placeholder={i18n.t('number')} onChangeText={onNumberChange} />
        <TextInput style={styles.textinput} placeholderTextColor={placeholderTextColor} value={draft.zip} placeholder={i18n.t('zip')} onChangeText={onZipChange} />
        <TextInput style={styles.textinput} placeholderTextColor={placeholderTextColor} value={draft.city} placeholder={i18n.t('city')} onChangeText={onCityChange} />
        <TextInput style={styles.textinput} placeholderTextColor={placeholderTextColor} value={draft.country} placeholder={i18n.t('country')} onChangeText={onCountryChange} />
        <TextInput style={styles.textinput} placeholderTextColor={placeholderTextColor} value={draft.phone} placeholder={i18n.t('phone')} onChangeText={onPhoneChange} />
        <TextInput style={styles.textinput} placeholderTextColor={placeholderTextColor} value={draft.email} placeholder={i18n.t('email')} onChangeText={onEmailChange} />
        <TextInput style={styles.textinput} placeholderTextColor={placeholderTextColor} value={String(draft.longitude)} placeholder={i18n.t('longitude')} onChangeText={onLongitudeChange} keyboardType="numeric" />
        <TextInput style={styles.textinput} placeholderTextColor={placeholderTextColor} value={String(draft.latitude)} placeholder={i18n.t('latitude')} onChangeText={onLatitudeChange} keyboardType="numeric" />
        <View style={styles.buttonrow}>
          <TouchableOpacity><HeeboRegularTextSmall onPress={()=>AlertModal({
            title: i18n.t('areyousure'),
            message: i18n.t('willberemoved'),
            action: ()=>deleteAddress(draft.uuid),
            dispatch: dispatch,
          })}>
            <FontAwesome name='trash' /> {i18n.t('delete')}
          </HeeboRegularTextSmall></TouchableOpacity>
          <TouchableOpacity><HeeboRegularTextSmall onPress={saveAddress}>
            <FontAwesome name='trash' /> {i18n.t('save')}
          </HeeboRegularTextSmall></TouchableOpacity>
        </View>
      </View>);
    }

    return components;
  };

  function onNameChange(name: string) {if (draft) setDraft({ ...draft, name: name })};
  function onStreetChange(street: string) {if (draft) setDraft({ ...draft, street: street})};
  function onNumberChange(number: string) {if (draft) setDraft({ ...draft, number: number})};
  function onZipChange(zip: string) {if (draft) setDraft({ ...draft, zip: zip})};
  function onCityChange(city: string) {if (draft) setDraft({ ...draft, city: city})};
  function onCountryChange(country: string) {if (draft) setDraft({ ...draft, country: country})};
  function onPhoneChange(phone: string) {if (draft) setDraft({ ...draft, phone: phone})};
  function onEmailChange(email: string) {if (draft) setDraft({ ...draft, email: email})};
  function onLongitudeChange(longitude: string) {if (draft) setDraft({ ...draft, longitude: longitude})};
  function onLatitudeChange(latitude: string) {if (draft) setDraft({ ...draft, latitude: latitude})};

  const components = getComponents();

  if (props.locationUuids.length===0 || addresses.length===0) return <Spinner />
  else return (
    <View style={styles.container}>
      {components}
      {index==-1 && <TouchableOpacity onPress={addAddress}><HeeboBoldTextSmall>
        <FontAwesome name='plus-circle' /> {i18n.t('add')}
      </HeeboBoldTextSmall></TouchableOpacity>}
    </View>
  );
};


// usage: {i18n.t('loginorregister1')}
const i18n = new I18n({
  nl: {
    name: 'Naam',
    street: 'Straat',
    number: 'Nummer',
    zip: 'Postcode',
    city: 'Stad',
    country: 'Land',
    phone: 'Telefoon nr.',
    email: 'E-mail',
    longitude: 'Longitude',
    latitude: 'Latitude',
    add: 'Toevoegen',
    edit: 'Bewerken',
    save: 'Bewaren',
    delete: 'Verwijderen',
    areyousure: 'Ben je zeker?',
    willberemoved: 'Dit adres wordt permanent verwijderd',
  },
  fr: {
  },
  en: {
    name: 'Name',
    street: 'Street',
    number: 'Number',
    zip: 'Postal code',
    city: 'City',
    country: 'Country',
    phone: 'Phone nr.',
    email: 'E-mail',
    longitude: 'Longitude',
    latitude: 'Latitude',
    add: 'Add',
    edit: 'Edit',
    save: 'Save',
    delete: 'Remove',
    areyousure: 'Are you sure?',
    willberemoved: 'This address will be removed permanently',
  },
});
i18n.locale = Localization.locale; // this should happen only once
i18n.enableFallback = true;
// => causes fallback to default below
i18n.locale = 'nl';
