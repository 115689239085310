type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      }
};

// todo
export enum EventListRefreshActionType {
  EVENTS_REFRESH = 'EVENTS_REFRESH',
  EVENTS_REFRESHED = 'EVENTS_REFRESHED',
}

type EventListRefreshActionPayload = {
  [EventListRefreshActionType.EVENTS_REFRESH]: null;
  [EventListRefreshActionType.EVENTS_REFRESHED]: null;
};

export type EventListRefreshAction = ActionMap<EventListRefreshActionPayload>[keyof ActionMap<
  EventListRefreshActionPayload
>];

export type EventListRefreshStateType = {
  refreshing: boolean,
}

export const initialEventListRefreshState = {
  refreshing: false,
};

export const eventListRefreshReducer = (
  state: EventListRefreshStateType,
  action: EventListRefreshAction
) => {
  switch (action.type) {
    case EventListRefreshActionType.EVENTS_REFRESH:
      return {refreshing: true};
    case EventListRefreshActionType.EVENTS_REFRESHED:
      return {refreshing: false};
    default:
      return state;
  }
};
