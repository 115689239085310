import React from 'react';
import { Animated, View, StyleSheet, Text } from 'react-native';

import usePlatform, { maxWidth } from '../hooks/usePlatform';
import { PostDetailCardInfoType } from '../contexts/reducers/postListReducer';
import { useThemeColor } from './Themed';

export function PostCardImageText (props: {
  info?: PostDetailCardInfoType,
  cardAnimation?: Animated.Value,
  bottom?: boolean,
  text?: string,
  textStyle?: any,
}) {

  const platform = usePlatform();

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      left: 0,
      top: 0,
      paddingLeft: 24,
      paddingTop: 24,
    },
    containerIsBottom: {
      top: undefined,
      bottom: 0,
      paddingTop: 0,
      paddingBottom: 24,
      width: '100%',
    },
    title: {
      fontSize: platform.normalize(18),
      color: useThemeColor({}, 'text'),
    },
  });

  function getRenderVariables () {
    const {
      info,
      cardAnimation,
      bottom,
      text,
      textStyle,
    } = props;

    const containerAnimationStyle = [
      styles.container,
      bottom && styles.containerIsBottom,
      info && cardAnimation && {
        transform: [{
          scale: cardAnimation.interpolate({
            inputRange: [0, 1],
            outputRange: [info?.scale, 1],
          }),
        }],
      },
      platform.isOveriPhoneX && info && cardAnimation && {
        top: cardAnimation.interpolate({
          inputRange: [0, 1],
          outputRange: [0, 20],
        }),
      },
    ];

    const containerStyle = [
      styles.container,
      bottom && styles.containerIsBottom,
    ];

    const textStyleCombined = [styles.title, textStyle];

    return {
      containerAnimationStyle,
      containerStyle,
      textStyleCombined,
      text,
    };
  }

  const {
    containerAnimationStyle,
    containerStyle,
    textStyleCombined,
    text,
  } = getRenderVariables();

  return (
    <Animated.View style={containerAnimationStyle}>
      <View style={containerStyle}>
        {text && <Text style={textStyleCombined}>{text}</Text>}
      </View>
    </Animated.View>
  );
};
