import { useEffect, useContext } from 'react';
import { StyleSheet } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';

import { AppContext } from "../contexts";
import { AuthActionType, AuthStateType, initialAuthState } from '../contexts/reducers/authReducer';
import { path } from '../env/env';
import { UserActionType } from '../contexts/reducers/userReducer';
import { View } from './Themed';
import Spinner from './Spinner';

export default function RequestComponent (props: {
  authState: AuthStateType;
  requestDone: Function,
}) {
  const { state, dispatch } = useContext(AppContext);

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
  });

  useEffect(() => {
    if (props.authState.uuid){
      dispatch({
        type: AuthActionType.LOGGED_IN,
        payload: props.authState,
      });
    }
  }, []);

  useEffect(() => {
    if (!state.auth.uuid) return;
    
    setInterceptor();
    fetchUser();

  }, [state.auth.uuid]);

  useEffect(() => {
    if (state.user.fetched) props.requestDone();
  }, [state.user.fetched]);

  function setInterceptor() {
    if (state.auth.uuid&&state.auth.__act&&state.auth.__rt) {
      axios.defaults.headers.common['Authorization'] = '__act ' + state.auth.__act;

      axios.interceptors.response.use(
        (response) => {
          return response;
        },
        async (error) => {
          if (!error.response) {
            return Promise.reject('');
          }
          const errorResponse = error.response;
          const originalRequest = error.config;
          if (errorResponse.status == 401 && errorResponse.data.__actExpired && !originalRequest.retry) {

            originalRequest.retry = true;
            try {
              axios.defaults.headers.common['Authorization'] = '__rt '+state.auth.__rt;
              const refreshResponse = await axios.get(path.refresh);
              // logout if it fails
              // if (refreshResponse.status==401) {
              //   axios.defaults.headers.common['Authorization'] = '';
              //   AsyncStorage.removeItem('authState');
              //   dispatch({
              //     type: AuthActionType.LOGGED_OUT,
              //     payload: initialAuthState,
              //   });
              //   return Promise.reject(error);
              // }
              const newAuthState = {
                uuid: refreshResponse.data.uuid,
                __act: refreshResponse.data.__act,
                __rt: refreshResponse.data.__rt,
              };

              originalRequest.headers.Authorization = '__act ' + newAuthState.__act;
              dispatch({
                type: AuthActionType.TOKENS_REFRESHED,
                payload: newAuthState,
              });

              axios.defaults.headers.common['Authorization'] ='__act ' + newAuthState.__act;
              try {
                AsyncStorage.setItem('authState', JSON.stringify(newAuthState));
              } catch (error) {
                console.error('Failed to store authentication in persistent storage');
                dispatch({
                  type: AuthActionType.LOGGED_OUT,
                  payload: initialAuthState,
                });
              };

              return axios(originalRequest);
            } catch (_error) {
              console.log('Caught error refreshing');
              console.log(_error);
              return Promise.reject(_error);
            }
          } else {
            console.log(error);
            return Promise.reject(error);
          }
        }
      );

    } else {
      axios.defaults.headers.common['Authorization'] = '';
      AsyncStorage.removeItem('authState');
    }
  };
  
  function fetchUser() {
    axios({
      method: 'GET',
      url: path.users+'/'+state.auth.uuid,
    })
    .then(function (response) {
      dispatch({
        type: UserActionType.SET_USER,
        payload: {
          admin: response.data.user.admin,
          fetched: true,
          firstName: response.data.user.firstName,
          lastName: response.data.user.lastName,
          following: response.data.user.following,
          mutedUsers: response.data.user.mutedUsers,
          blockedUsers: response.data.user.blockedUsers,
          profileImage: response.data.user.profileImage,
        },
      });
    })
    .catch(function (error) {
      console.log('Error in handling response');
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  };

  return <View style={styles.container}>
    {!state.user.fetched && <Spinner />}
  </View>;
};
