import { useContext } from 'react';
import { Pressable } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';

import { AppContext } from "../contexts";
import { useThemeColor } from './Themed';

export default function UserComponent (props: {
  navigate: Function;
}) {
  const { state, dispatch } = useContext(AppContext);

  return <Pressable
    onPress={() => props.navigate('Profile')}
    style={({ pressed }) => ({
      opacity: pressed ? 0.5 : 1,
    })}>
    <FontAwesome
      name={state.user.fetched? 'car': 'user-times'}
      size={22}
      color={state.user.fetched? useThemeColor({}, 'bluedark') : useThemeColor({}, 'text')}
      style={{ marginRight: 15 }}
    />
  </Pressable>;
};
