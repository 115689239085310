import { Linking, StyleSheet, TouchableOpacity } from "react-native";
import { Address } from '../types/types';
import { HeeboBoldText, HeeboRegularTextSmall } from "./StyledText";
import { View } from "./Themed";

export default function AddressView  (props:{
  address: Address,
}) {
  
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      margin: 5,
      minWidth: 150,
    },
    link: {
      textDecorationLine: 'underline',
    },
  });

  return (
    <View style={styles.container}>
      <HeeboBoldText>{props.address.name}</HeeboBoldText>
      <HeeboRegularTextSmall>{props.address.street} {props.address.number}</HeeboRegularTextSmall>
      <HeeboRegularTextSmall>{props.address.zip} {props.address.city}</HeeboRegularTextSmall>
      <HeeboRegularTextSmall>{props.address.country}</HeeboRegularTextSmall>
      <TouchableOpacity onPress={()=>Linking.openURL('mailto:'+props.address.email)}><HeeboRegularTextSmall style={styles.link}>{props.address.email}</HeeboRegularTextSmall></TouchableOpacity>
      <TouchableOpacity onPress={()=>Linking.openURL('tel:'+props.address.phone)}><HeeboRegularTextSmall style={styles.link}>{props.address.phone}</HeeboRegularTextSmall></TouchableOpacity>
    </View>
  );
};
