import React, { useContext, useEffect, useState } from 'react';
import { Animated, ImageLoadEventData, NativeSyntheticEvent, StyleSheet } from 'react-native';

import usePlatform, { maxWidth } from '../hooks/usePlatform';
import useImageSize from '../hooks/useCardSize';
import { PostCardImageText } from './PostCardImageText';
import { PostDetailCardInfoType } from "../contexts/reducers/postListReducer";
import { AppContext } from "../contexts";
import { userPostListHelper } from '../hooks/usePostListHelper';
import { ImageMetadata } from '../types/types';
import { emptyImageInstance, fetchImageInstance } from '../util/imageHelper';

export default function PostDetailCardImage (props:{
  uuid: string,
  info: PostDetailCardInfoType | null | undefined,
  cardAnimation: Animated.Value,
  onLoad: (event: NativeSyntheticEvent<ImageLoadEventData>|null) => void,
}) {
  const { state, dispatch } = useContext(AppContext);
  const [ imageInstance, setImageInstance] = useState<ImageMetadata>(emptyImageInstance);
  
  const post = userPostListHelper(props.uuid);

  const platform = usePlatform();

  const styles = StyleSheet.create({
    headerText: {
      fontSize: platform.normalize(26),
      fontWeight: 'bold',
    },
    bottomTextIsBold: {
      fontSize: platform.normalize(32),
      fontWeight: 'bold',
    },
  });

  function getRenderVariables () {

    if (!props.info || !post) {
      return null;
    }

    const {
      imageUuids,
      payload: {
        imageHeaderText,
        imageBottom,
      },
    } = post;
    
    useEffect(() => {
      fetchImageInstance(setImageInstance, imageUuids, 'lg', true, '');
    }, []);
    
    const imageSize = useImageSize(imageInstance.width, imageInstance.height, false);

    const containerStyle = {
      width: props.cardAnimation.interpolate({
        inputRange: [0, 0.7, 1],
        outputRange: [props.info.imageWidth, imageSize.cardWidth + 40, imageSize.cardWidth],
      }),
      height: props.cardAnimation.interpolate({
        inputRange: [0, 1],
        outputRange: [props.info.imageHeight, imageSize.cardHeight],
      }),
    };

    const imageStyle = {
      width: props.cardAnimation.interpolate({
        inputRange: [0, 0.7, 1],
        outputRange: [imageSize.cardWidth, imageSize.cardWidth + 40, imageSize.cardWidth],
      }),
      height: props.cardAnimation.interpolate({
        inputRange: [0, 0.7, 1],
        outputRange: [imageSize.cardHeight, imageSize.cardHeight + 40, imageSize.cardHeight],
      }),
      marginLeft: props.cardAnimation.interpolate({
        inputRange: [0, 1],
        outputRange: [props.info.imageMarginLeft, 0],
      }),
      marginTop: props.cardAnimation.interpolate({
        inputRange: [0, 1],
        outputRange: [props.info.imageMarginTop, 0],
      }),
      transform: [{
        scale: props.cardAnimation.interpolate({
          inputRange: [0, 1],
          outputRange: [props.info.scale, 1],
        }),
      }],
    };
    
    // components
    const components = [];
    components.push(<Animated.Image
      key="image"
      source={imageInstance.uri!='' ? {uri: imageInstance.uri} : require('../assets/images/empty_image.jpg')}
      onLoad={!state.postDetail.isImageLoaded? props.onLoad : undefined}
      onError={!state.postDetail.isImageLoaded? (error) => props.onLoad(null) : undefined}
      style={imageStyle}
      resizeMode="cover"
    />);

    if (imageHeaderText) {
      components.push((
        <PostCardImageText
          key="headerText"
          text={imageHeaderText}
          textStyle={styles.headerText}
          info={props.info}
          cardAnimation={props.cardAnimation}
        />
      ));
    }

    if (imageBottom) {
      const {
        text: bottomText,
        type: bottomTextType,
      } = imageBottom;

      components.push((
        <PostCardImageText
          key="bottomText"
          bottom
          text={bottomText}
          textStyle={bottomTextType === 'BOLD' && styles.bottomTextIsBold}
          info={props.info}
          cardAnimation={props.cardAnimation}
        />
      ));
    }

    return {
      containerStyle,
      components,
    };
  }

  const renderVariables = getRenderVariables();
  if (!renderVariables) {
    return null;
  }

  const {
    containerStyle,
    components,
  } = renderVariables;
  
  return (
    <Animated.View style={containerStyle}>
      {components}
    </Animated.View>
  );
};
