import { FontAwesome, FontAwesome5, Foundation, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { TouchableOpacity, StyleSheet } from "react-native";
import { Service } from "../types/types";
import { HeeboBoldTextTiny, HeeboRegularTextTiny } from "./StyledText";
import { useThemeColor, View } from "./Themed";
import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js';

export default function ServiceIcon (props:{
  active: boolean,
  setService: Function,
  serviceUuid: string,
  service: Service,
}) {

  const {iconLibrary, iconName, fontSize, name} = props.service;

  const styles = StyleSheet.create({
    container: {
      width: 60,
      gap: 5,
      marginVertical: 5,
      justifyContent: 'center',
      alignItems: 'center',
    },
    round: {
      // alignSelf: 'center',
      width: 40,
      height: 40,
      borderRadius: 20,
      backgroundColor: useThemeColor({}, 'text'),
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      color: props.active ? useThemeColor({}, 'bluelight') : useThemeColor({}, 'textmuted'),
    },
    iconText: {
      color: props.active ? useThemeColor({}, 'text') : useThemeColor({}, 'textmuted'),
      textAlign: 'center',
    },
  });

  const i18n = new I18n(name);
  i18n.locale = Localization.locale; // this should happen only once
  i18n.enableFallback = true;
  // => causes fallback to default below
  i18n.locale = 'nl'; // todo everywhere to english?

  function getIcon() {
    if (iconLibrary=='Foundation') return <Foundation name={iconName} size={fontSize} style={styles.icon} />;
    else if (iconLibrary=='FontAwesome') return <FontAwesome name={iconName} size={fontSize} style={styles.icon} />;
    else if (iconLibrary=='FontAwesome5') return <FontAwesome5 name={iconName} size={fontSize} style={styles.icon} />;
    else if (iconLibrary=='MaterialIcons') return <MaterialIcons name={iconName} size={fontSize} style={styles.icon} />;
    else if (iconLibrary=='MaterialCommunityIcons') return <MaterialCommunityIcons name={iconName} size={fontSize} style={styles.icon} />;
  };
  
  return (
    <TouchableOpacity onPress={()=>props.setService(props.serviceUuid, !props.active)}>
      <View style={styles.container}>
        <View style={styles.round}>
          {getIcon()}
        </View>
        {!props.active && <HeeboRegularTextTiny style={styles.iconText}>{i18n.t('name')}</HeeboRegularTextTiny>}
        {props.active && <HeeboBoldTextTiny style={styles.iconText}>{i18n.t('name')}</HeeboBoldTextTiny>}
      </View>
    </TouchableOpacity>
  )
};
