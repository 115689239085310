import { PostCorePayload, PostLikeUpdate, PostCommentAdded, PostCommentDeleted, PostCoreStateType } from './postListReducer';

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      }
};

      // todo
export interface BusinessPayload extends PostCorePayload {
  tagline: string,
  locationUuids: string[],
  serviceUuids: string[],
  carUuids: string[],
}

export interface BusinessStateType extends PostCoreStateType {
  payload: BusinessPayload,
};

export type BusinessListStateType = BusinessStateType[];

export const initialBusinessListState: BusinessListStateType = [];

export enum BusinessActionType {
  BUSINESS_FETCHED_ON_MOUNT = 'BUSINESS_FETCHED_ON_MOUNT',
  BUSINESS_FETCHED = 'BUSINESS_FETCHED',
  BUSINESS_PUBLISHED = 'BUSINESS_PUBLISHED',
  BUSINESS_REMOVED = 'BUSINESS_REMOVED',
  BUSINESS_CLEAR = 'BUSINESS_CLEAR',
  BUSINESS_LIKED = 'BUSINESS_LIKED',
  BUSINESS_COMMENT_ADDED = 'BUSINESS_COMMENT_ADDED',
  BUSINESS_COMMENT_DELETED = 'BUSINESS_COMMENT_DELETED',
}

type BusinessActionPayload = {
  [BusinessActionType.BUSINESS_FETCHED_ON_MOUNT]: BusinessListStateType;
  [BusinessActionType.BUSINESS_FETCHED]: BusinessListStateType;
  [BusinessActionType.BUSINESS_PUBLISHED]: BusinessStateType;
  [BusinessActionType.BUSINESS_REMOVED]: string;
  [BusinessActionType.BUSINESS_CLEAR]: BusinessListStateType;
  [BusinessActionType.BUSINESS_LIKED]: PostLikeUpdate;
  [BusinessActionType.BUSINESS_COMMENT_ADDED]: PostCommentAdded;
  [BusinessActionType.BUSINESS_COMMENT_DELETED]: PostCommentDeleted;
};

export type BusinessAction = ActionMap<BusinessActionPayload>[keyof ActionMap<
  BusinessActionPayload
>];

export const businessListReducer = (
  state: BusinessListStateType,
  action: BusinessAction
) => {
  switch (action.type) {
    case BusinessActionType.BUSINESS_FETCHED_ON_MOUNT:
      return action.payload;
    case BusinessActionType.BUSINESS_FETCHED:
      return [...state, ...action.payload];
    case BusinessActionType.BUSINESS_PUBLISHED:
      return [action.payload, ...state];
    case BusinessActionType.BUSINESS_REMOVED:
      return state.filter(business => business.uuid !== action.payload);
    case BusinessActionType.BUSINESS_CLEAR:
      return [...initialBusinessListState];
    case BusinessActionType.BUSINESS_LIKED:
      if (action.payload.liked) {
        let newState = state.map(item => {
          var returnItem = {...item};
          if (item.uuid == action.payload.postId) {
            returnItem.social?.likes.push({
              userFirstName: action.payload.userFirstName,
              userLastName: action.payload.userLastName,
              userUuid: action.payload.userUuid,
            });
          }
          return returnItem;
        })
        return newState;
      } else {
        let newState = state.map(item => {
          var returnItem = {...item};
          if (item.uuid == action.payload.postId) {
            if (item.social && returnItem.social){
              returnItem.social.likes = item.social?.likes.filter(like => like.userUuid !== action.payload.userUuid);
            }
          }
          return returnItem;
        })
        return newState;
      }
    case BusinessActionType.BUSINESS_COMMENT_ADDED:
      {
        let newState = state.map(item => {
          var returnItem = {...item};
          if (item.uuid == action.payload.postId) {
            returnItem.social?.comments.push({
              _id: action.payload._id,
              userFirstName: action.payload.userFirstName,
              userLastName: action.payload.userLastName,
              userUuid: action.payload.userUuid,
              value: action.payload.value,
              createdAt: action.payload.createdAt,
            });
          }
          return returnItem;
        })
        return newState;
      }
    case BusinessActionType.BUSINESS_COMMENT_DELETED:
      {
        let newState = state.map(item => {
          var returnItem = {...item};
          if (item.uuid == action.payload.postId) {
            if (item.social && returnItem.social){
              returnItem.social.comments = item.social?.comments.filter(comment => comment._id !== action.payload._id);
            }
          }
          return returnItem;
        })
        return newState;
      }
    default:
      return state;
  }
};
