import {StyleSheet} from 'react-native';
import React from 'react';
import { View, Image } from 'react-native';

import { PostCardImageText } from './PostCardImageText';
import usePlatform, { maxWidth } from '../hooks/usePlatform';
import useCardSize from '../hooks/useCardSize';
import { userPostListHelper } from '../hooks/usePostListHelper';
import { ImageMetadata } from '../types/types';

export default function PostCardImage (props: {
  uuid: string,
  imageInstance: ImageMetadata,
}) {
  const post = userPostListHelper(props.uuid);

  const platform = usePlatform();

  const styles = StyleSheet.create({
    container: {
      overflow: 'hidden',
      borderRadius: 16,
    },
    image: {
    },
    headerText: {
      fontSize: platform.normalize(26),
      fontWeight: 'bold',
    },
    bottomTextIsBold: {
      fontSize: platform.normalize(32),
      fontWeight: 'bold',
    },
  });

  if (!post) {
    return null;
  }

  function getRenderVariables () {

    const cardSize = useCardSize(props.imageInstance.width, props.imageInstance.height, true);

    const containerStyle = [
      styles.container,
      {
        width: cardSize.cardWidth,
        height: cardSize.cardHeight,
      },
    ];

    const imageStyle = [
      styles.image,
      {
        width: cardSize.cardWidth,
        height: cardSize.cardHeight,
      },
    ];

    // components
    const components = [(
      <Image
        key="image"
        source={{uri: props.imageInstance.uri}}
        style={imageStyle}
        resizeMode="cover"
      />
    )];
    
    if (post && post.payload.imageHeaderText) {
      components.push((
        <PostCardImageText
          key="headerText"
          text={post.payload.imageHeaderText}
          textStyle={styles.headerText}
        />
      ));
    }

    if (post && post.payload.imageBottom) {
      const {
        text: bottomText,
        type: bottomTextType,
      } = post.payload.imageBottom;

      components.push((
        <PostCardImageText
          key="bottomText"
          bottom
          text={bottomText}
          textStyle={bottomTextType === 'BOLD' && styles.bottomTextIsBold}
        />
      ));
    }

    return {
      containerStyle,
      components,
    };
  }

  const {
    containerStyle,
    components,
  } = getRenderVariables();

  return (
    <View style={containerStyle}>
      {components}
    </View>
  );
};
