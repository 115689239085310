import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, Animated, View, Image, Touchable, TouchableOpacity, } from 'react-native';
import * as WebBrowser from 'expo-web-browser';

import { PostStateType, POST_CARD_CONTENT_TYPE } from "../contexts/reducers/postListReducer";
import PostCardContent from './PostCardContent';
import { fetchImageInstance } from '../util/imageHelper';
import { AppContext } from '../contexts';
import { ImageMetadata, PostType } from '../types/types';
import { useCardSizeNotAHookVersion } from '../hooks/useCardSize';
import { HeeboRegularText } from './StyledText';
import usePlatform from '../hooks/usePlatform';
import { CarouselActionType } from '../contexts/reducers/carouselReducer';
import { useThemeColor } from './Themed';
import { BusinessStateType } from '../contexts/reducers/businessListReducer';
import axios from 'axios';
import { getPath } from '../env/env';
import { BusinessListCard } from './business/BusinessListCard';
import CarouselClose from './CarouselClose';
import CarouselExpand from './CarouselExpand';

export default function PostDetailCardContent (props:{
  item: PostStateType,
  contentAnimation: Animated.Value,
}) {
  const { state, dispatch } = useContext(AppContext);
  const [ textComponentsReady, setTextComponentsReady ] = useState(false);
  const [ amountOfMainImages, setAmountOfMainImages ] = useState(0);
  const [ imageMetadatasReady, setImageMetadatasReady ] = useState(false);
  const [ imageComponentsReady, setImageComponentsReady ] = useState(false);
  const [ textComponents, setTextComponents ] = useState<(JSX.Element)[]>([]);
  const [ imageMetadatas, setImageMetadatas] = useState<(ImageMetadata)[]>([]);
  const [ imageComponents, setImageComponents] = useState<(JSX.Element)[]>([]); // custom hook that updates state using ref instead of load-time
  const [ detailTextAndImages, setDetailTextAndImages ] = useState<(JSX.Element|null)[]>([]);
  const [ business, setBusiness ] = useState<BusinessStateType|null>(null);

  const styles = StyleSheet.create({
    container: {
      paddingBottom: 200,
    },
    fullContents: {
      padding: 16,
    },
    detailContentText: {
      marginBottom: 24,
    },
    detailContentImageWrapper: {
      marginBottom: 24,
    },
    detailContentImage: {
      width: 260,
      height: 180,
      alignSelf: 'center',
      borderRadius: 10,
    },
    linkText: {
      color: useThemeColor({}, 'linkinverse'),
      fontStyle: 'italic',
      textDecorationLine: 'underline',
    },
    center: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
  
  const { item } = props;
  if (!item) {
    return null;
  }

  const {
    imageUuids,
    payload: {
      detailText,
    },
  } = item;

  const fullContentsStyle = [
    styles.fullContents,
    {
      opacity: props.contentAnimation.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1],
      }),
    },
  ];

  // create Text objects
  useEffect(() => {
    let tempTextComponent : (JSX.Element)[] = [];
    if (detailText) {
      const detailTextParagraphs: string[] = detailText.replace(/\r\n/g,"\n").split("\n").filter((line:string) => line); // last step clears empty lines
      tempTextComponent = detailTextParagraphs.map((detailParagraph, index) => {
        const key = `detailContent-${index}`;

        return (
          <HeeboRegularText
            key={key}
            style={styles.detailContentText}
          >
            {detailParagraph}
          </HeeboRegularText>
        );
      });
    }
    setTextComponents(tempTextComponent);
    setTextComponentsReady(true);
  }, []);

  // Fetch all image metadata
  useEffect(() => {
    // Create Image objects via statefunction

    if (imageUuids) {
      imageUuids.map( (imageUuid, index) => {
        if (imageUuid.mainImage) {
          setAmountOfMainImages(currentAmountOfMainImages => currentAmountOfMainImages+1);
        };

        fetchImageInstance(
          appendImageMetadata,
          imageUuids,
          'lg',
          imageUuid.mainImage,
          imageUuid.uuid
        );
      });
    }
  }, []);

  // Fetch postAs information
  useEffect(() => {
    if (item.postAs && item.postAs.postType!=PostType.UNDEFINED) {
      axios({
        method: 'GET',
        url: getPath(item.postAs.postType)+'/'+item.postAs.uuid,
      })
      .then(function (response) {
        setBusiness(response.data.item);
      })
      .catch(function (error) {
      })
      .then(function () {
        // always executed
      });
    }
  }, []);

  // update imageMetadata state as callback
  function appendImageMetadata (imageMetadata: ImageMetadata) {
    setImageMetadatas(currentImageMetadatas => [...currentImageMetadatas, imageMetadata]);
  };

  // once al image metadata is fetched, proceed
  useEffect(() => {
    if (imageMetadatas.length==imageUuids.length) {
      setImageMetadatasReady(true);;
    };
  }, [imageMetadatas]);

  // once al image metadata is fetched, proceed
  const platform = usePlatform();
  useEffect(() => {
    if (!imageMetadatasReady) return;

    dispatch({
      type: CarouselActionType.CAROUSELREADY,
      payload: {
        index: -1,
        imageMetadatas: imageMetadatas,
      }
    });
    
    imageUuids.map((imageUuid, index) => {
      const imageMetadataIndex = imageMetadatas.findIndex(imageMetadata => imageMetadata && imageMetadata.uuid == imageUuid.uuid);
      const imageMetadata = imageMetadatas[imageMetadataIndex]

      if (!imageMetadata) return; // should not happen

      const imageSize = useCardSizeNotAHookVersion(platform, imageMetadata.width, imageMetadata.height, true);
      const scaleFactor = 0.75;
  
      const imageComponent: JSX.Element = <TouchableOpacity
          key={`detailContent-${imageMetadata.uuid}`}
          onPress={() => {
            dispatch({
              type: CarouselActionType.CAROUSELSHOW,
              payload: imageMetadataIndex,
            });
          }}
          style={styles.center}
        >
          <View
            style={[styles.detailContentImageWrapper ,{
              width: imageSize.cardWidth*scaleFactor,
              height: imageSize.cardHeight*scaleFactor,
            }]}>
              <Image
                source={imageMetadata.uri!='' ? {uri: imageMetadata.uri} : require('../assets/images/empty_image.jpg')}
                style={[styles.detailContentImage, {
                  width: imageSize.cardWidth*scaleFactor,
                  height: imageSize.cardHeight*scaleFactor,
                }]}
              />
              <CarouselExpand />
            </View>
      </TouchableOpacity>;
  
      if (!imageUuid.mainImage) {
        setImageComponents(oldImageComponents => [...oldImageComponents, imageComponent]); // important to get the latest array, not the initialization
      }
    });
  }, [imageMetadatasReady]);

  // once al image components are ready, proceed
  useEffect(() => {
    if (imageComponents.length==imageUuids.length-amountOfMainImages) {
      setImageComponentsReady(true);
    };
  }, [imageComponents]);
      
  // When all images are loaded, render in order
  useEffect(
    () => {
      const tempDetailTextAndImages : (JSX.Element|null)[] = [];
      const maxIt = Math.max(imageComponents.length, textComponents.length);
      for (let i = 0; i < maxIt; i++) {
        if (i<textComponents.length) tempDetailTextAndImages.push(textComponents[i]);
        if (i<imageComponents.length) tempDetailTextAndImages.push(imageComponents[i]);
      }
      setDetailTextAndImages(tempDetailTextAndImages);
    }, [textComponentsReady, imageComponentsReady],
  );

  // const itemPayloadCardContentText = item.payload.itemPayloadCardContentText ? item.payload.itemPayloadCardContentText: null;
  // const itemPayloadCardContentDownload = item.payload.itemPayloadCardContentDownload ? item.payload.itemPayloadCardContentDownload: null;

  let externalLink = item ? item.payload.externalLink : '';
  try {
    if (externalLink!='') {
      const url = new URL(externalLink);
    }
  } catch (error) {
    externalLink='';
  }
  
  return (
    <View style={styles.container}>
      {/* {itemPayloadCardContentText && <PostCardContent uuid={props.item.uuid} key="cardContent" cardTextContent={itemPayloadCardContentText} social={props.item.social} detailMode />}
      {itemPayloadCardContentDownload && <PostCardContent uuid={props.item.uuid} key="cardContent" cardDownloadContent={itemPayloadCardContentDownload} social={props.item.social} detailMode />} */}
      {props.item.payload.type==POST_CARD_CONTENT_TYPE.POST && <PostCardContent uuid={props.item.uuid} key="cardContent" social={props.item.social} detailMode />}
      <Animated.View style={fullContentsStyle}>
        {detailTextAndImages}
        {externalLink!='' && <TouchableOpacity onPress={() => WebBrowser.openBrowserAsync(externalLink)}>
          <HeeboRegularText style={styles.linkText}>{externalLink}</HeeboRegularText>
        </TouchableOpacity>}
        {business && <BusinessListCard item={business} focus={false} />}
      </Animated.View>
    </View>
  );
};
