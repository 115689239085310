import React, { useRef } from 'react';
import { useEffect, useState,useContext } from 'react';
import { View, TouchableWithoutFeedback, Animated, PixelRatio, Alert } from 'react-native';
import {StyleSheet} from 'react-native';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';

import { AppContext } from "../../contexts";
import { PostDetailCardInfoType, POST_CARD_CONTENT_TYPE } from '../../contexts/reducers/postListReducer';
import { EventStateType } from '../../contexts/reducers/eventListReducer';
import usePlatform, { imagePadding, maxWidth } from '../../hooks/usePlatform';
import useCardSize from '../../hooks/useCardSize';
import { EventDetailActionType } from '../../contexts/reducers/eventDetailReducer';
import { emptyImageInstance, fetchImageInstance } from '../../util/imageHelper';
import { ImageMetadata, PostType } from '../../types/types';
import { useNavigation } from '@react-navigation/native';
import { RootTabScreenProps } from '../../types';
import { shadowStyle, useThemeColor } from './../Themed';
import EventCardContent from './EventCardContent';

export function EventListCard (props: {
  item: EventStateType,
  focus: boolean,
}) {
  const platform = usePlatform();
  const containerViewRef = useRef<View>(null);

  const navigation = useNavigation<RootTabScreenProps<'Events'>['navigation']>();

  const styles = StyleSheet.create({
    container: {
      marginVertical: 10,
      backgroundColor: useThemeColor({}, 'textinverse'),
      borderRadius: 16,
      maxWidth: maxWidth,
    },
    containerIsHidden: {
      opacity: 0,
    },
    shadow: {
      overflow: 'hidden',
      borderRadius: 16,
    },
  });
  
  const { state, dispatch } = useContext(AppContext);
  const [ isHidden, setIsHidden ] = useState(false);
  const [ imageInstance, setImageInstance] = useState<ImageMetadata>(emptyImageInstance);
  

  useEffect(() => {
    fetchImageInstance(setImageInstance, props.item.imageUuids, 'lg', true, '');
  }, []);

  const cardSize = useCardSize(imageInstance.width, imageInstance.height, true);

  const bottomTabBarHeight = useBottomTabBarHeight();

  const [ focussed, setFocussed ] = useState(false);

  useEffect(() => {
    if (!props.focus && focussed) {
      setFocussed(false);
    } else if (props.focus && !focussed) {
      setFocussed(true); // We do this becuase on iOS a double tap triggers this twice, ending up with an empty screen
      onPress();
    }
  }, [props.focus]);

  const onPress = () => {
    if (focussed) return;

    containerViewRef.current && containerViewRef.current.measure((
      x, y, width, height, pageX, pageY,
    ) => {
      const position = {
        pageX,
        pageY,
        width,
        height,
        scale: 1,
      };
      
      const {
        width: imageWidth,
      } = position;
      
      const duration = 250; // 180 + (position.pageY / platform.windowHeight - bottomTabBarHeight) * 140;
      const imageHeight = PixelRatio.roundToNearestPixel((cardSize.cardHeight * imageWidth) / cardSize.cardWidth);
      const imageMarginLeft = -1 * (imagePadding + ((cardSize.cardWidth - imageWidth) / 2));
      const imageMarginTop = -1 * (imagePadding + ((cardSize.cardHeight - imageHeight) / 2));
      
      const info: PostDetailCardInfoType = {
      ...position,
      imageWidth,
      imageHeight,
      imageMarginLeft,
      imageMarginTop,
      windowWidth: platform.windowWidth,
      windowHeight: platform.windowHeight - bottomTabBarHeight + (platform.isAndroid ? (platform.statusBarHeight? platform.statusBarHeight : 0) : 0),
      duration,
      };
      
      navigation.navigate('Events', {
        uuid: props.item.uuid,
      });
      dispatch({
        type: EventDetailActionType.EVENT_SHOWED,
        payload: {
          item: props.item,
          info: info,
        },
      });
    });
  };

  const getRenderVariables = () => {

    const containerStyle = [
      styles.container,
      isHidden && styles.containerIsHidden,
    ];

    const components = [];

    if (props.item.payload.type==POST_CARD_CONTENT_TYPE.EVENT) {
      components.push(<EventCardContent key={'EventListCard-'+props.item.uuid} item={props.item} detailMode={false}/>);
    }

    return {
      containerStyle,
      components,
    };
  };

  const {
    containerStyle,
    components,
  } = getRenderVariables();

  const dominantColor = imageInstance.dominantColor ? 'rgb('+imageInstance.dominantColor.r+','+imageInstance.dominantColor.g+','+imageInstance.dominantColor.b+')' : null;
  const coloredContainerShadowStyle = dominantColor ? [containerStyle, {
    shadowColor: dominantColor,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.5,
    shadowRadius: 15,
    elevation: 10,
  }] : [containerStyle, shadowStyle.shadow];

  return (
    <TouchableWithoutFeedback
      onPress={onPress}
    >
      <Animated.View style={[containerStyle, coloredContainerShadowStyle]}>
        <View ref={containerViewRef} style={styles.shadow}>
          {components}
        </View>
      </Animated.View>
    </TouchableWithoutFeedback>
  );
};
