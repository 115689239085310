import { StyleSheet } from "react-native";
import { imagePadding } from "../hooks/usePlatform";
import { Service } from "../types/types";
import ServiceIcon from "./ServiceIcon";
import { useThemeColor, View } from "./Themed";

export default function SmartServiceIcon (props:{
  services: Service[],
  activeServices: string[],
  pathAttribute: string,
  setService?: Function,
  showOnlyActive?: boolean,
}) {

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'row',
      maxWidth: '100%',
      paddingHorizontal: imagePadding,
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    round: {
      marginVertical: 5,
      alignSelf: 'center',
      width: 40,
      height: 40,
      borderRadius: 20,
      backgroundColor: useThemeColor({}, 'text'),
      justifyContent: 'center',
      textAlign: 'center',
    },
    icon: {
      fontSize: 32,
      color: useThemeColor({}, 'textinverse'),
    },
    iconText: {
      alignSelf: 'center',
      textAlign: 'center',
    },
  });

  function getComponents() {

    const components : JSX.Element[] = [];

    if (!props.activeServices) return;

    props.services.map(service => {
      const index = props.activeServices.findIndex(activeService => activeService==service.uuid);
      const active = index==-1 ? false : true;
      if (!props.showOnlyActive || active) {
        components.push(
          <ServiceIcon
            key={'service-icon-'+service.uuid}
            active={active}
            setService={props.setService ? props.setService : ()=>{}}
            service={service}
            serviceUuid={service.uuid}
          />
        );
      }
    });

    return components;
  };

  const components = getComponents();

  return (
    <View style={styles.container}>
      {components}
    </View>
  );
};
