import { FontAwesome } from '@expo/vector-icons';
import {StyleSheet, TouchableOpacity } from 'react-native';
import { HeeboBoldText } from '../components/StyledText';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import { useThemeColor } from './Themed';

interface Props {
  onPress: () => void;
  text: string;
  icon?: string;
  disabled?: boolean;
  small?: boolean;
}

export function WhiteButton(props: Props) {
  
  const styles = StyleSheet.create({
    buttonStyle: {
      borderColor: useThemeColor({}, props.disabled? 'alt2dark' : 'text'),
      borderWidth: 1,
      borderRadius: 34,
      fontFamily: 'Heebo Regular',
      marginVertical: 5,
      width: props.small ? '45%' : '90%',
      maxWidth: 400,
    },
    background: {
      paddingVertical: 10,
      paddingHorizontal: 30,
      borderRadius: 22,
    },
    buttonText: {
      fontSize: 16,
      fontFamily: 'Heebo Bold',
      textAlign: 'center',
      paddingVertical: props.small ? 3 : 10,
      paddingHorizontal: props.small ? 10 : 50,
    },
    buttonDisabled: {
      color: Colors.light.textmuted,
      fontSize: 16,
      fontFamily: 'Heebo Bold',
      textAlign: 'center',
      paddingVertical: props.small ? 3 : 10,
      paddingHorizontal: props.small ? 10 : 50,
    },
    buttonIcon: {
      fontSize: 18,
    },
  });

  const callback = props.disabled ? ()=>{} : props.onPress;
  
  return (
    <TouchableOpacity onPress={callback} style={styles.buttonStyle}>
      <HeeboBoldText style={props.disabled ? styles.buttonDisabled : styles.buttonText}>
        {props.icon && <FontAwesome style={styles.buttonIcon} name={props.icon} />}
        &nbsp;&nbsp;
        {props.text}
      </HeeboBoldText>
    </TouchableOpacity>
  );
};
