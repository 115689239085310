import { ClubStateType} from "./clubListReducer";
import { PostDetailCardInfoType } from "./postListReducer";

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      }
};

export type ClubDetailStateType = {
  item: ClubStateType | null,
  info: PostDetailCardInfoType | null | undefined,
};

export type ClubDetailShowedType = {
  item: ClubStateType | null,
  info: PostDetailCardInfoType | null | undefined,
};

export const initialClubDetailState: ClubDetailStateType = {
  item: null,
  info: null,
};

export enum ClubDetailActionType {
  CLUB_HIDE = 'CLUB_HIDE',
  CLUB_SHOWED = 'CLUB_SHOWED',
}

type ClubDetailActionPayload = {
  [ClubDetailActionType.CLUB_HIDE]: null;
  [ClubDetailActionType.CLUB_SHOWED]: ClubDetailShowedType;
};

export type ClubDetailAction = ActionMap<ClubDetailActionPayload>[keyof ActionMap<
  ClubDetailActionPayload
>];

export const clubDetailReducer = (
  state: ClubDetailStateType,
  action: ClubDetailAction
) => {
  switch (action.type) {
    case ClubDetailActionType.CLUB_HIDE:
      return {...state, hasDimmedLayer: false, item: null};
    case ClubDetailActionType.CLUB_SHOWED:
      return {...state, item: action.payload.item, info: action.payload.info };
    default:
      return state;
  }
};
