type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      }
};

// todo
export enum ClubListRefreshActionType {
  CLUBS_REFRESH = 'CLUBS_REFRESH',
  CLUBS_REFRESHED = 'CLUBS_REFRESHED',
}

type ClubListRefreshActionPayload = {
  [ClubListRefreshActionType.CLUBS_REFRESH]: null;
  [ClubListRefreshActionType.CLUBS_REFRESHED]: null;
};

export type ClubListRefreshAction = ActionMap<ClubListRefreshActionPayload>[keyof ActionMap<
  ClubListRefreshActionPayload
>];

export type ClubListRefreshStateType = {
  refreshing: boolean,
}

export const initialClubListRefreshState = {
  refreshing: false,
};

export const clubListRefreshReducer = (
  state: ClubListRefreshStateType,
  action: ClubListRefreshAction
) => {
  switch (action.type) {
    case ClubListRefreshActionType.CLUBS_REFRESH:
      return {refreshing: true};
    case ClubListRefreshActionType.CLUBS_REFRESHED:
      return {refreshing: false};
    default:
      return state;
  }
};
