import { CommonActions } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { ScrollView, StyleSheet, TouchableOpacity } from 'react-native';
import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js';

import { Text, useThemeColor, View } from '../components/Themed';
import { RootStackScreenProps } from '../types';
import { HeeboBoldText, HeeboRegularText, HeeboRegularTextHuge, HeeboRegularTextLarge, HeeboRegularTextMuted } from '../components/StyledText';
import { SafeAreaProvider } from 'react-native-safe-area-context';

export default function TermsOfService({ navigation, route }: RootStackScreenProps<'TermsOfService'>) {

  const styles = StyleSheet.create({
    container: {
      paddingHorizontal: 20,
    },
    content: {
      flex: 1,
      gap: 20,
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
    },
    link: {
      paddingVertical: 20,
    },
    linkText: {
      fontSize: 14,
      color: useThemeColor({}, 'link'),
    },
  });

  // Add back button when coming here directly (empty navigation history)
  useEffect(
    () => {
      if (navigation.getState().routes.length>1) return;

      navigation.dispatch(navigationState => {
        const currentNavigationState = navigationState; // This can also directly be accessed with navigation.getState()
    
        const routes = [{name: 'GRILL'}, ...currentNavigationState.routes];
    
        const newNavigationState = {
          ...navigationState,
          routes,
          index: routes.length - 1,
        };
      
        return CommonActions.reset(newNavigationState);
      });
    }, [],
  );
  
  return (
    <SafeAreaProvider>
      <ScrollView contentContainerStyle={styles.container}>
        <View style={styles.content}>
          <TouchableOpacity onPress={() => navigation.replace('GRILL')} style={styles.link}>
            <Text style={styles.linkText}>{i18n.t('back')}</Text>
          </TouchableOpacity>
          <HeeboRegularTextHuge style={styles.title}>{i18n.t('title')}</HeeboRegularTextHuge>
          <HeeboRegularTextMuted>{i18n.t('p0001')}</HeeboRegularTextMuted>
          <HeeboRegularText>{i18n.t('p0002')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0003')}</HeeboRegularText>
          <HeeboRegularTextLarge style={styles.title}>{i18n.t('p01')}</HeeboRegularTextLarge>
          <HeeboRegularText>{i18n.t('p0103')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0104')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0106')}</HeeboRegularText>
          <HeeboRegularTextLarge style={styles.title}>{i18n.t('p02')}</HeeboRegularTextLarge>
          <HeeboRegularText>{i18n.t('p0201')}</HeeboRegularText>
          <HeeboRegularTextLarge style={styles.title}>{i18n.t('p03')}</HeeboRegularTextLarge>
          <HeeboRegularText>{i18n.t('p0301')}</HeeboRegularText>
          <HeeboRegularTextLarge style={styles.title}>{i18n.t('p04')}</HeeboRegularTextLarge>
          <HeeboRegularText>{i18n.t('p0401')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0402')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0403')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0404')}</HeeboRegularText>
          <HeeboRegularTextLarge style={styles.title}>{i18n.t('p05')}</HeeboRegularTextLarge>
          <HeeboRegularText>{i18n.t('p0501')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0502')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0503')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0504')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0505')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0506')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0507')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0508')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0509')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0510')}</HeeboRegularText>
          <HeeboRegularTextLarge style={styles.title}>{i18n.t('p06')}</HeeboRegularTextLarge>
          <HeeboRegularText>{i18n.t('p0601')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0602')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0603')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0605')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0606')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0607')}</HeeboRegularText>
          <HeeboRegularTextLarge style={styles.title}>{i18n.t('p07')}</HeeboRegularTextLarge>
          <HeeboRegularText>{i18n.t('p0701')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0702')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0703')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0704')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p07041')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p07042')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0705')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0706')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0707')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0708')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0709')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0710')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0711')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0712')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0713')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p0714')}</HeeboRegularText>
          <HeeboRegularTextLarge style={styles.title}>{i18n.t('p08')}</HeeboRegularTextLarge>
          <HeeboRegularText>{i18n.t('p0801')}</HeeboRegularText>
          <HeeboRegularTextLarge style={styles.title}>{i18n.t('p09')}</HeeboRegularTextLarge>
          <HeeboRegularText>{i18n.t('p0901')}</HeeboRegularText>
          <HeeboRegularTextLarge style={styles.title}>{i18n.t('p10')}</HeeboRegularTextLarge>
          <HeeboRegularText>{i18n.t('p1001')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p1002')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p1003')}</HeeboRegularText>
          <HeeboRegularText>{i18n.t('p1004')}</HeeboRegularText>
          <TouchableOpacity onPress={() => navigation.replace('GRILL')} style={styles.link}>
            <Text style={styles.linkText}>{i18n.t('back')}</Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </SafeAreaProvider>
  );
}

const i18n = new I18n({
  nl: {
    title: 'Algemene voorwaarden',
    back: 'Terug naar de feed',
    p0001: 'Laatst bijgewerkt op 1 september 2022',
    p0002: 'Deze algemene voorwaarden regelen jouw rechten en verplichtingen in het kader van het gebruik van de GRILL-app en -website, beiden verdeeld door info@grill-community.com (hierna: “GRILL”).',
    p0003: 'Lees deze algement voorwaarden aandachtig door, want ze bepalen de voorwaarden voor de toegang tot en het gebruik van de GRILL-app en -website, het aanmaken van een account en het gebruik van onze diensten.',
    p01: 'Artikel 1 – Definities',
    p0103: 'GRILL-app en -website: website en mobiele app op Android en iOS voor de GRILL-gebruikers;',
    p0104: 'GRILL-gebruiker: persoon die een account heeft gemaakt op de GRILL-app en -website;',
    p0106: 'GRILL-cloud: online web-platform, gebruikt door de GRILL-gebruiker met behulp van  de GRILL-app en -website.',
    p02: 'Artikel 2 – Opzet van GRILL',
    p0201: '2.1. GRILL is een online platform die erin bestaat dat middels de GRILL-app en -website GRILL-gebruikers de mogelijkheid geeft om informatie te delen met andere GRILL-gebruikers en anonieme bezoekers over voertuigen en gerelateerde informatie.',
    p03: 'Artikel 3 – Toepasselijkheid en bindende kracht',
    p0301: 'Bij het aanmaken van een account als GRILL-app en -website-gebruiker en bij het gebruik van de GRILL-app en -website zal je gevraagd worden te bevestigen dat je kennis genomen hebt van deze algemene voorwaarden en dat je de inhoud ervan als bindend aanvaardt. Je bezoek aan de GRILL-app en -website, jouw aanmelding voor een account in de app en elk ander gebruik van de GRILL diensten, impliceren in ieder geval de kennisname en de aanvaarding van deze algemene voorwaarden.',
    p04: 'Artikel 4 – Duur van de verwerking',
    p0401: '4.1. Het gebruik van de app en het aanmaken van een account is gratis.',
    p0402: 'GRILL behoudt zich het recht voor om de toegang voor bezoekers die de huidige algemene voorwaarden en Privacy policy van de GRILL-app en -website niet naleven, eenzijdig, onmiddellijk, definitief en zonder voorafgaande verwittiging af te sluiten.',
    p0403: '4.2. Om de diensten te gebruiken die via de GRILL-app en -website toegankelijk zijn moet je vooraf toestemming krijgen van je ouders als je jonger bent dan 13 jaar. In dat geval dien je bij de registratie een contact e-mailadres of telefoonnummer van je ouders te verschaffen ter controle.',
    p0404: 'GRILL behoudt zich het recht voor om de toegang eenzijdig en zonder voorafgaande verwittiging af te sluiten indien blijkt dat je jonger dan 13 bent en geen toestemming van je ouders hebt verkregen.',
    p05: 'Artikel 5 – Het aanmaken van een account',
    p0501: '5.1. Om de GRILL diensten interactief te kunnen gebruiken op de GRILL-app en -website, dien je een account aan te maken door je persoonsgegevens in te vullen op het registratieformulier ter beschikking op de GRILL-app en -website. Het aanmeldingsformulier dient volledig en waarheidsgetrouw ingevuld te worden.',
    p0502: '5.2. Het is niet toegestaan gebruik te maken van andermans identiteit, van proxys en tijdelijke e-mailadressen.',
    p0503: '5.3. Bij de aanmelding dien je je door het aanzetten van de daartoe voorziene schakelaar akkoord te verklaren met de huidige algemene voorwaarden en Privacy policy van de GRILL-app en -website. Dit is een vereiste om geldig een account te kunnen aanmaken.',
    p0504: '5.4. GRILL behoudt zich het recht voor om jouw aanmelding al dan niet te aanvaarden. Jouw aanmelding kan onder meer, maar niet uitsluitend, geweigerd worden om volgende redenen:',
    p0505: '-Je verstrekt niet de gevraagde gegevens voor identificatie of verstrekt onvolledige of onjuiste gegevens ervoor;',
    p0506: '-Je tracht zich meerdere malen aan te melden door gebruik te maken van verschillende namen;',
    p0507: '-Je maakt gebruik van andermans identiteit;',
    p0508: '-Je komt één of meerdere in deze algemene voorwaarden gestelde verplichtingen niet na of liet zulks in het verleden na of GRILL kan redelijkerwijze aannemen dat je een dergelijke verplichting niet zal nakomen;',
    p0509: '-Elke andere reden op grond waarvan wij redelijkerwijze kunnen oordelen dat jouw aanmelding niet aanvaard kan worden.',
    p0510: '5.5. Vanaf het ogenblik dat jouw aanmelding compleet is, ontvang je een bevestigingsmail met een activatielink met beperkte geldigheid voor activatie van jouw account. Hierdoor is het mogelijk te bewijzen dat de GRILL-gebruiker toegang heeft tot het e-mailadres horende bij het account',
    p06: 'Artikel 6 – GRILL-app en -website',
    p0601: '6.1. GRILL behoudt zich het recht voor om op elk ogenblik, op discretionaire beslissing en zonder voorafgaand overleg aanpassingen aan te brengen aan de GRILL-app en -website.',
    p0602: '6.2. GRILL behoudt zich het recht voor om de toegang voor GRILL-app- en -website-gebruikers die de huidige algemene voorwaarden en de Privacy policy van GRILL niet naleven, eenzijdig, onmiddellijk, definitief en zonder voorafgaande verwittiging af te sluiten. Ze behoudt zich het recht voor de toegang tot de GRILL-app en -website op te schorten indien de algemene voorwaarden niet worden nageleefd.',
    p0603: '6.3. GRILL verleent door onderhavige overeenkomst aan de GRILL-app- en -website-gebruiker een niet-exclusief recht van gebruik van de GRILL-app en -website, die uitsluitend mag worden aangewend door de GRILL-app- en -website-gebruiker.',
    // p0604: '6.4. Om als GRILL-app en -website-gebruiker toegang te krijgen tot de faciliteiten aan de hand van GRILL-hardware, moet de GRILL-organisatie-beheerder een uitnodiging op afstand versturen met een verzoek tot toegang tot de organisatie. Eerder al gekoppelde GRILL-app en -website-gebruikers kunnen door de GRILL-organisatie-beheerder verwijderd worden.',
    p0605: '6.5. Foto’s die door een GRILL-app- en -website-gebruiker gestuurd worden vanuit de GRILL-app en -website blijven bewaard op de GRILL-cloud tot de GRILL-app- en -website-gebruiker deze verwijderd. Binnen de GRILL-app en -website zelf blijven foto’s gedurende het gebruik bewaard, tot de GRILL-app en -website wordt verwijderd of wordt vervangen/verwijderd door de GRILL-app en -website-gebruiker.',
    p0606: '6.6. GRILL-app en -website-gebruikers kunnen foto’s laten verwijderen waar zij zelf op staan. Dit kan gebeuren via contact met info@grill-community.com',
    p0607: '6.7. info@grill-community.com staat in als aanspreekpunt binnen de organisatie om privacy-vragen of -verzoeken op te volgen en eventueel door te geven aan GRILL voor verdere verwerking.',
    p07: 'Artikel 7 – GRILL als platform',
    p0701: '7.1. GRILL is slechts een technische facilitator. Het gebruik dat de GRILL-app- en -website-gebruiker maakt van de GRILL-app en -website wordt niet door GRILL gecontroleerd op inhoud, betrouwbaarheid of correctheid en GRILL wordt als dusdanig nooit betrokken partij in de relatie tussen GRILL-app en -website-gebruikers onderling. GRILL is evenmin aansprakelijk voor de inhoud op de account van GRILL-app en -website-gebruikers. Evenmin is GRILL aansprakelijk voor gebeurlijke inbreuken op de rechten van derden.',
    p0702: '7.2. GRILL zal zich naar alle redelijkheid inspannen voor een optimaal gebruikersgemak en voert een beleid gericht op de continuïteit van de GRILL-app en -website. Indien GRILL kennis neemt van een ongeoorloofde activiteit of ongeoorloofde informatie die plaatsvindt op of wordt verspreid via de GRILL-app en -website, verbindt GRILL zich ertoe de desbetreffende gegevens ontoegankelijk te maken en, indien het een strafrechtelijke inbreuk betreft, deze informatie onmiddellijk mee te delen aan het parket. GRILL behoudt zich het recht voor (doch zonder hiertoe verplicht te zijn of enige aansprakelijkheid te hebben) de toegang tot door de gebruiker geplaatste informatie te blokkeren, aan te passen en/of te verwijderen in het geval deze aanstootgevend, hinderlijk of ongepast is of kan zijn voor andere gebruikers of voor derden.',
    p0703: '7.3. GRILL besteedt de grootst mogelijke aandacht en zorg aan de inhoud van de GRILL-app en -website en het project in het algemeen. Deze inhoud is echter onderhevig aan wijzigingen, kan te allen tijde verwijderd worden en wordt je aangeboden zonder expliciete of impliciete garanties omtrent de correctheid ervan. GRILL is niet aansprakelijk voor schade ingevolge het gebruik van de inhoud op de GRILL-app en -website, ongeacht of deze correct of incorrect bleek, noch voor schade die wordt veroorzaakt door het gebruiken of verspreiden van deze inhoud.',
    p0704: '7.4. GRILL benadrukt dat zij niet de auteur, noch de verantwoordelijke persoon is voor jouw account en de inhoud ervan. GRILL wijst alle aansprakelijkheid af voor deze inhoud als die niet correct zouden blijken te zijn of als die in strijd zouden blijken te zijn met de rechten van derden, met geldende strafwetten of met de openbare orde en de goede zeden. Als de GRILL-app en -website-gebruiker de aanwezigheid van zulke inhoud vaststelt, dienen ze GRILL hiervan wel onverwijld op de hoogte te stellen, zodat GRILL waar mogelijk de gepaste maatregelen kan nemen.',
    p07041: 'GRILL staat niet toe dat naaktheid openbaar wordt gedeeld.',
    p07042: 'GRILL staat niet toe dat levensecht, expliciet geweld openbaar wordt gedeeld.',
    p0705: '7.5. GRILL wijst bovendien alle aansprakelijkheid af voor de dienstverlening van eventuele derden ten aanzien van de GRILL-app en -website-gebruikers.',
    p0706: 'GRILL komt niet tussenbeide en is geen partij in de verhouding tussen de GRILL-app en -website-gebruikers en is bijgevolg niet aansprakelijk voor eventuele geschillen tussen deze partijen.',
    p0707: '7.6. GRILL is niet aansprakelijk voor directe, indirecte, bijzondere, incidentele, immateriële of gevolgschade (met inbegrip van gederfde winst) die voortvloeit uit het gebruik van de GRILL-app en -website met inbegrip van, maar niet beperkt tot, schade veroorzaakt door:',
    p0708: 'Defecten, virussen of overige onvolkomenheden aan apparatuur en/of software in verband met de toegang tot of het gebruik van de GRILL-app en -website;',
    p0709: 'Het onderscheppen, wijzigen of het oneigenlijk gebruik van informatie die aan de GRILL-app en -website of aan de gebruiker wordt gezonden;',
    p0710: 'De werking of het niet-beschikbaar zijn van de GRILL-app en -website;',
    p0711: 'Misbruik van de GRILL-app en -website, verlies van gegevens of het downloaden of gebruiken van software die via de GRILL-app en -website beschikbaar wordt gesteld;',
    p0712: 'Aanspraken van derden in verband met het gebruik van de GRILL-app en -website;',
    p0713: 'Op de GRILL-app en -website geplaatste hyperlinks die naar andere internetsites verwijzen indien deze hyperlinks niet (meer) actief zijn of, behalve bedrog of grove nalatigheid, indien de informatie waartoe zij toegang verstrekken ongeoorloofd of aanstootgevend is;',
    p0714: '7.7. GRILL stelt alles in werking om een toegang te verlenen tot de GRILL-app en -website 24 uur op 24 en 7 dagen op 7. Gelet op de technische kenmerken van het internet en van de informaticamiddelen en gelet op de noodzaak om periodieke onderhouds-, update- of upgradewerkzaamheden uit te voeren, kan GRILL echter geen ononderbroken toegang en dienstverlening garanderen. In geval van normaal aanvaardbare onderbreking of verstoring van de toegang of de dienstverlening, stelt GRILL alles in werking om dit binnen een zo kort mogelijke termijn te verhelpen. Dergelijke normaal aanvaardbare onderbrekingen of verstoringen zijn eigen aan de dienstverlening via internet en kunnen niet beschouwd worden als tekortkomingen.',
    p08: 'Artikel 8 – Verwerking van persoonsgegevens',
    p0801: '8.1. Bij het leveren van de onder huidige overeenkomst beschreven diensten, fungeert GRILL als verwerker van persoonsgegevens in de zin van de Algemene Verordening Gegevensbescherming (GDPR). In het kader van deze verwerking van persoonsgegevens verklaart GRILL te voldoen aan alle toepasselijke privacywetgeving en meer specifiek aan de Algemene Verordening Gegevensbescherming (GDPR), in het bijzonder artikel 28 ervan. Als je meer details wil over hoe wij met jouw gegevens omgaan, lees dan best onze Privacy policy.',
    p09: 'Artikel 9 – Intellectuele eigendom',
    p0901: '9.1. GRILL is de exclusieve rechthebbende of rechtverkrijgende van het geheel van de intellectuele eigendomsrechten verbonden aan de GRILL-app en -website en de aangeboden dienst. Je verbindt je ertoe op geen enkele wijze en onder geen enkele vorm dan ook het geheel van elementen waaruit de GRILL-app en -website bestaat, of een deel ervan, te kopiëren, te bewerken, uit te baten of door te verkopen of op enige andere wijze te gebruiken. Deze elementen zijn onder andere het merk, de teksten, de software, de databases, de formulieren, de handelsnamen, de productnamen, de logo’s, de grafische elementen en illustraties, de grafieken, de muziek, de kleurcombinaties, de slogans, de lay-outs en bladschikkingen en elk ander voor bescherming in aanmerking komend element van de GRILL-app en -website en van de dienst.',
    p10: 'Artikel 10 – Geschillenbeslechting',
    p1001: '10.1. We hopen natuurlijk steeds dat al onze klanten 100% tevreden zijn. Als je toch klachten zou hebben over onze diensten, kan je ons contacteren via info@grill-community.com. We doen er alles aan om je klacht binnen de 7 dagen te behandelen.',
    p1002: '10.2. Op alle overeenkomsten die we afsluiten met onze klanten, ongeacht hun woonplaats, is uitsluitend het Belgische recht van toepassing en bij geschillen zijn enkel de bevoegde Belgische rechtbanken bevoegd. Indien om redenen van internationaal recht toch een ander recht van toepassing is, zal bij de interpretatie van huidige algemene voorwaarden in de eerste plaats teruggegrepen worden naar de Belgische Wet Marktpraktijken en Consumentenbescherming.',
    p1003: '10.3. Bij buitengerechtelijke regeling van het geschil is de Consumentenombudsdienst van de Federale Overheid bevoegd om elke aanvraag tot buitengerechtelijke regeling van consumentengeschillen te ontvangen. Deze zal op haar beurt de aanvraag ofwel zelf behandelen ofwel doorsturen naar een gekwalificeerde entiteit. Je kan de Consumentenombudsdienst bereiken via deze link: https://consumentenombudsdienst.be/nl.',
    p1004: '10.4. Bij geschillen met een grensoverschrijdend karakter kan je bovendien beroep doen op het Online Dispute Resolution platform van de Europese Unie via deze link: http://ec.europa.eu/odr.',
  },
  fr: {
  },
  en: {
    title: 'Terms of Service',
    back: 'Back to the feed',
  },
});
i18n.locale = Localization.locale; // this should happen only once
i18n.enableFallback = true;
// => causes fallback to default below
i18n.locale = 'nl';