import React from 'react';

import PostListCardContentText from './PostListCardContentText';
import PostListCardContentDownload from './PostListCardContentDownload';

import {
  PostItemPayloadCardContentTextType,
  PostItemPayloadCardContentDownloadType,
  POST_CARD_CONTENT_TYPE,
  PostSocial,
} from '../contexts/reducers/postListReducer';
import PostListCardContentPost from './PostListCardContentPost';
import { userPostListHelper } from '../hooks/usePostListHelper';
import { PostType } from '../types/types';

export default function PostCardContent (props: {
  social: PostSocial,
  uuid: string,
  cardTextContent?: PostItemPayloadCardContentTextType,
  cardDownloadContent?: PostItemPayloadCardContentDownloadType,
  detailMode?: boolean,
}) {
  const post = userPostListHelper(props.uuid);
  if (!post) return null;
  
  const {
    detailMode = false,
    social,
  } = props;

  // need to check for cardTextContent
  if (props.cardTextContent && post.payload.type === POST_CARD_CONTENT_TYPE.TEXT) {
    return <PostListCardContentText item={props.cardTextContent} detailMode={detailMode} />;
  }

  // need to check for cardDownloadContent?
  if (props.cardDownloadContent && post.payload.type === POST_CARD_CONTENT_TYPE.DOWNLOAD) {
    return <PostListCardContentDownload item={props.cardDownloadContent} />;
  }
  
  if (post.payload.type === POST_CARD_CONTENT_TYPE.POST) {
    return <PostListCardContentPost uuid={props.uuid} detailMode={detailMode} social={props.social} type={PostType.POST} />;
  }

  return null;
};
