import { FontAwesome } from '@expo/vector-icons';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { useEffect, useState } from 'react';

export default function useCachedResources() {

  // Keep showing splash screen
  useEffect(() => {
    SplashScreen.preventAutoHideAsync();
  }, []);

  const [loaded, error] = useFonts({
    ...FontAwesome.font,
    'space-mono': require('../assets/fonts/SpaceMono-Regular.ttf'),
    'Heebo Black': require('../assets/fonts/Heebo-Black.ttf'),
    'Heebo Bold': require('../assets/fonts/Heebo-Bold.ttf'),
    'Heebo ExtraBold': require('../assets/fonts/Heebo-ExtraBold.ttf'),
    'Heebo Light': require('../assets/fonts/Heebo-Light.ttf'),
    'Heebo Medium': require('../assets/fonts/Heebo-Medium.ttf'),
    'Heebo Regular': require('../assets/fonts/Heebo-Regular.ttf'),
    'Heebo Thin': require('../assets/fonts/Heebo-Thin.ttf'),
    'SignPainter Regular': require('../assets/fonts/SignPainter.ttf'),
    'Praise Regular': require('../assets/fonts/Praise-Regular.ttf'),
    'Lobster-Regular': require('../assets/fonts/Lobster-Regular.ttf'),
  });

  // Hide splash screen
  useEffect(() => {
    if (loaded) {
      SplashScreen.hideAsync();
    }
  }, [loaded]);

  if (loaded) {
    return true;
  } else {
    return false;
  }
};
