const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';

const colors = {
  mainnormal: '#563A59',
  maindark: '#332235',
  mainlight: '#7A527F',
  mainlighter: '#EFD6F2',
  mainlightest: '#F4E4F6',
  alt1normal: '#20201E',
  alt1dark: '#060606',
  alt1darktransparent: '#06060620',
  alt1light: '#403F3C',
  alt1lighter: '#63615C',
  alt1lightest: '#7F7D77',
  alt2normal: '#F3EEE3',
  alt2dark: '#DEDAD0',
  alt2light: '#F7F5EE',
  alt2lighter: '#FFFCF4',
  alt2lightest: '#FFFEF9',
  greennormal: '#70BD9C',
  greendark: '#4A9374',
  greenlight: '#E2F3EC',
  greendarkest: '#1C7280',
  greennearblack: '#264C3D',
  bluenormal: '#34BACC',
  bluedark: '#27A0B3',
  bluelight: '#E2F5F7',
  bluedarkest: '#1C727F',
  bluenearblack: '#11314D',
  goldnormal: '#E6CE80',
  golddark: '#C0AB66',
  goldlight: '#F8F3E4',
  rednormal: '#D33016',
  reddark: '#A9200A',
  redlight: '#F5DDD9',
};

export default {
  light: {
    // from example START
    expoicon: colors.bluedark,
    text: colors.alt1dark,
    textinverse: colors.alt2lightest,
    textmuted: colors.alt1lightest,
    texterror: colors.rednormal,
    background: colors.alt2lightest,
    subtlebackground: colors.alt2lightest,
    subtlebackgroundonreactcolors: colors.alt2light,
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
    // from example END,
    greennormal: colors.greennormal,
    bluedark: colors.bluedark,
    bluelight: colors.bluelight,
    alt2lightest: colors.alt2lightest,
    alt1lightest: colors.alt1lightest,
    alt2dark: colors.alt2dark,
    modal: colors.alt2light,
    shadow: colors.alt1light,
    shadowinverse: colors.alt1lightest,
    darkText: colors.bluenearblack,
    link: colors.bluedark,
    linkinverse: colors.bluedarkest,
  },
  dark: {
    // from example START
    expoicon: colors.bluenormal,
    text: colors.alt2lightest,
    textinverse: colors.alt1dark,
    textmuted: colors.alt2dark,
    texterror: colors.rednormal,
    background: colors.alt1dark,
    subtlebackground: colors.alt1normal,
    subtlebackgroundonreactcolors: colors.alt1light,
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
    // from example END
    greennormal: colors.greennearblack,
    bluedark: colors.bluenormal,
    bluelight: colors.bluedarkest,
    alt2lightest: colors.alt1dark,
    alt1lightest: colors.alt1lightest,
    alt2dark: colors.alt1lightest,
    modal: colors.alt1normal,
    shadow: colors.alt1light,
    shadowinverse: colors.alt1lightest,
    darkText: colors.bluelight,
    link: colors.bluenormal,
    linkinverse: colors.bluelight,
  },
};
