import React from 'react';
import { View, StyleSheet, Text } from 'react-native';

import usePlatform, { maxWidth } from '../hooks/usePlatform';
import { PostItemPayloadCardContentTextType } from '../contexts/reducers/postListReducer';
import { useThemeColor } from './Themed';

export default function PostListCardContentText (props: {
  item: PostItemPayloadCardContentTextType,
  detailMode: boolean,
}) {
  const {
    item: {
      categoryText,
      mainText, // todo moved to level above payload
      subText,
    },
    detailMode,
  } = props;

  const platform = usePlatform();

  const styles = StyleSheet.create({
    container: {
      padding: 16,
      backgroundColor: useThemeColor({}, 'background'),
    },
    containerIsDetailMode: {
      paddingBottom: 0,
    },
    categoryText: {
      fontSize: platform.normalize(14),
      color: useThemeColor({}, 'alt1lightest'),
    },
    mainText: {
      fontSize: platform.normalize(20),
      fontWeight: 'bold',
      color: useThemeColor({}, 'alt1lightest'),
      marginVertical: 4,
    },
    subText: {
      fontSize: platform.normalize(14),
      color: useThemeColor({}, 'alt1lightest'),
    },
  });

  return (
    <View style={[styles.container, detailMode && styles.containerIsDetailMode]}>
      <Text style={styles.categoryText}>{categoryText}</Text>
      <Text style={styles.mainText}>{mainText}</Text>
      {!detailMode && <Text style={styles.subText}>{subText}</Text>}
    </View>
  );
};
