
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { TextInput } from 'react-native';
import { StyleSheet } from 'react-native';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import usePlatform, { imagePadding } from '../hooks/usePlatform';

interface FunctionalProps {
  // required: boolean;
  // large?: boolean;
  // limited?: boolean;
  // initialText?: string;
  // savePath: string;
  // saveAttribute: string;
  // singleLine?: boolean;
  savedCallback: Function;
}

export function useThemeColor(
  props: { light?: string; dark?: string },
  colorName: keyof typeof Colors.light & keyof typeof Colors.dark
) {
  const theme = useColorScheme();
  const colorFromProps = props[theme];

  if (colorFromProps) {
    return colorFromProps;
  } else {
    return Colors[theme][colorName];
  }
}

type ThemeProps = {
  lightColor?: string;
  darkColor?: string;
};

export type TagInputProps = ThemeProps & TextInput['props'] & FunctionalProps;

// to support multiline
// multiline = {true}
// numberOfLines = {4}
export function SmartTagInput(props: TagInputProps) {





  
  // Temporary implementation
  useEffect(() => {
    props.savedCallback(true);
  }, []);
  return null;









  // const { style, lightColor, darkColor, ...otherProps } = props;
  // const color = useThemeColor({ light: lightColor, dark: darkColor }, 'text');
  // const placeholderTextColor=useThemeColor({}, 'textmuted');
  // const borderColor = useThemeColor({ light: lightColor, dark: darkColor }, 'text');
  // const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, 'bluelight');

  // const platform = usePlatform();
  // const textinputStyle = StyleSheet.create({
  //   textInput: {
  //     fontSize: 16,
  //     fontFamily: 'Heebo Regular',
  //     textAlign: 'center',
  //     paddingVertical: 5,
  //     // borderWidth: 1, // causes scrollbars to appear in multi-line
  //     borderRadius: 10,
  //     marginVertical: 10,
  //     width: platform.windowWidth*0.9-2*imagePadding,
  //     maxWidth: 600,
  //   },
  // });

  // const [text, setText] = useState(props.initialText ? props.initialText : '');

  // const minimumTextInputHeight = props.large ? 24 : 18;
  // const maximumLimitedTextInputHeight = props.large ? 
  //   (props.singleLine ? 45 : 80)
  //   :
  //   (props.singleLine ? 37 : 63);
  // function getDynamicTextInputHeightStyle(height: number) {
  //   return StyleSheet.create(
  //     {
  //       textHeight: {
  //         height: height,
  //       },
  //       large: {
  //         fontFamily: 'Heebo Regular',
  //         fontSize: minimumTextInputHeight,
  //       },
  //     }
  //   );
  // };

  // const [dynamicTextStyle, setDynamicTextStyle] = useState(getDynamicTextInputHeightStyle(minimumTextInputHeight));

  // // Save draft post each time editing mainText has ended
  // const onEndEditingText = () => {
  //   axios({
  //     method: 'PUT',
  //     url: props.savePath,
  //     data: {[props.saveAttribute]: text}, // With ES6, you can now directly use a variable as your property key in your object literal using brackets
  //   })
  //   .then(function (response) {
  //     if (!props.required || text!='') {
  //       props.savedCallback(true);
  //     } else {
  //       props.savedCallback(false);
  //     }
  //   })
  //   .catch(function (error) {
  //     console.log(error.response.data.message);
  //   })
  //   .then(function () {
  //     // always executed
  //   });
  // };

  // return <TextInput
  //   style={[textinputStyle.textInput, dynamicTextStyle.textHeight, dynamicTextStyle.large, {color}, {borderColor}, {backgroundColor}, style]}
  //   placeholderTextColor={placeholderTextColor}
  //   onContentSizeChange={(event) => {
  //     let newHeight = event.nativeEvent.contentSize.height>0 ? event.nativeEvent.contentSize.height : maximumLimitedTextInputHeight;
  //     newHeight = props.limited && newHeight>maximumLimitedTextInputHeight ? maximumLimitedTextInputHeight : newHeight;
  //     setDynamicTextStyle(getDynamicTextInputHeightStyle(newHeight));
  //   }}
  //   editable={true}
  //   multiline={props.multiline ? props.multiline : true}
  //   value={text}
  //   onChangeText={(updatedText: string) => setText(updatedText)}
  //   onFocus={() => props.savedCallback(false)}
  //   onBlur={onEndEditingText}
  //   {...otherProps}
  // />;
};
