import React, { PureComponent } from 'react';
import { View, Image, StyleSheet, Text } from 'react-native';
import usePlatform, { maxWidth } from '../hooks/usePlatform';

import { PostItemPayloadCardContentDownloadType } from '../contexts/reducers/postListReducer';
import { useThemeColor } from './Themed';

export default function PostListCardContentText (props: {
  item: PostItemPayloadCardContentDownloadType,
}) {
    const {
      item: {
      appName,
      category,
      appImage,
      backgroundColor,
      color,
      buttonBackgroundColor,
      buttonColor,
    },
  } = props;

  const platform = usePlatform();

  const styles = StyleSheet.create({
    container: {
      padding: 16,
      flexDirection: 'row',
      alignItems: 'center',
    },
    content: {
      flex: 1,
      justifyContent: 'center',
      paddingLeft: 12,
    },
    categoryText: {
      fontSize: platform.normalize(14),
      marginBottom: 2,
    },
    appNameText: {
      fontSize: platform.normalize(20),
      fontWeight: 'bold',
    },
    subText: {
      fontSize: platform.normalize(14),
      color: useThemeColor({}, 'alt1lightest'),
    },
    appImage: {
      width: 40,
      height: 40,
      borderRadius: 6,
    },
    downloadButton: {
      borderRadius: 20,
      paddingVertical: 4,
      paddingHorizontal: 8,
    },
    downloadText: {
      fontSize: platform.normalize(14),
    },
  });

  return (
    <View style={[styles.container, { backgroundColor }]}>
      <Image source={appImage} style={styles.appImage} />
      <View style={styles.content}>
        <Text style={[styles.categoryText, { color }]}>{category}</Text>
        <Text style={[styles.appNameText, { color }]}>{appName}</Text>
      </View>
      <View>
        <View style={[styles.downloadButton, { backgroundColor: buttonBackgroundColor }]}>
          <Text style={[styles.downloadText, { color: buttonColor }]}>Download</Text>
        </View>
      </View>
    </View>
  );
};
