import { Text, Button, Platform } from 'react-native';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { useContext, useEffect, useState, useRef } from 'react';
import { StyleSheet } from "react-native";
import { View } from "./Themed";
import axios from 'axios';
import { environment, environmentType, path } from '../env/env';
import { AppContext } from '../contexts';

export default function Notification () {
  const { state, dispatch } = useContext(AppContext);

  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: false,
      shouldSetBadge: true,
    }),
  });
  
  // Can use this function below OR use Expo's Push Notification Tool from: https://expo.dev/notifications
  async function sendPushNotification(expoPushToken: any) {
    const message = {
      to: expoPushToken,
      sound: 'default',
      title: 'Original Title',
      body: 'And here is the body!',
      data: { someData: 'goes here' },
    };
    console.log(message);
  
    await fetch('https://exp.host/--/api/v2/push/send', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Accept-encoding': 'gzip, deflate',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(message),
    });
  }
  
  async function registerForPushNotificationsAsync() {
    let token;
    if (Device.isDevice && Platform.OS !== 'web') { // true if running on a real device and false if running in a simulator or emulator. On web, this is always set to true.
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        alert('Failed to get push token for push notification!');
        return;
      }
      token = (await Notifications.getDevicePushTokenAsync()).data; // getExpoPushTokenAsync for expo notification system
    } else {
      // alert('Must use physical device for Push Notifications');
    }
  
    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }
  
    return token;
  }

  const [expoPushToken, setExpoPushToken] = useState<string|undefined>('');
  const [notification, setNotification] = useState<Notifications.Notification>(false);
  const notificationListener = useRef();
  const responseListener = useRef();

  useEffect(() => {
    registerForPushNotificationsAsync().then(token => {
      if (token) {
        setExpoPushToken(token);

        // register device in back-end
        if (environment==environmentType.STG||environment==environmentType.PRD) {
          axios({
            method: 'POST',
            url: path.notification,
            data: {
              user: state.auth.uuid,
              token: token,
              platform: Platform.OS,
            },
          })
          .then(function (response) {
  
          })
          .catch(function (error) {
            console.log('Failed to push notification token');
            console.log(error);
          })
          .then(function () {
            // always executed
          });
        }
      }
    });

    notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
      setNotification(notification);
    });

    responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
      console.log(response);
    });

    return () => {
      Notifications.removeNotificationSubscription(notificationListener.current);
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, []);

  return null;

  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'space-around' }}>
      <Text>Your expo push token: {expoPushToken}</Text>
      <View style={{ alignItems: 'center', justifyContent: 'center' }}>
        <Text>Title: {notification && notification.request.content.title} </Text>
        <Text>Body: {notification && notification.request.content.body}</Text>
        <Text>Data: {notification && JSON.stringify(notification.request.content.data)}</Text>
      </View>
      <Button
        title="Press to Send Notification"
        onPress={async () => {
          await sendPushNotification(expoPushToken);
        }}
      />
    </View>
  );
};

  
// const styles = StyleSheet.create({
//   container: {
//   },
// });
// 
  // // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  // const firebaseConfig = {
  //   apiKey: "AIzaSyAhNZqphEkQ0JPtmkfOrtbeaX_LBw22L8Y",
  //   authDomain: "grill-1541829414677.firebaseapp.com",
  //   projectId: "grill-1541829414677",
  //   storageBucket: "grill-1541829414677.appspot.com",
  //   messagingSenderId: "794063113946",
  //   appId: "1:794063113946:web:195b9cea5eca8d909d9fe3",
  //   measurementId: "G-EDJSTWEZ36"
  // };

  // // Initialize Firebase
  // useEffect(() => {
  //   const app = initializeApp(firebaseConfig);
  //   const analytics = getAnalytics(app);
  //   const messaging = getMessaging(app);

  //   onMessage(messaging, (payload) => {
  //     console.log("Message received. ", JSON.stringify(payload));
  //   });
  
  //   getToken(messaging, {vapidKey: "BMVVLjrg7EsZ_tC1-mOlVX2ZYobmHxO-59LzzYSTEaXpW9jpMJWzcb9Z9j-rqgAJQHcShFqbmwCWp_0apJEuU64"}).then((currentToken) => {
  //     if (currentToken) {
  //       console.log(currentToken);
  //       // Send the token to your server and update the UI if necessary
  //       // ...
  //     } else {
  //       // Show permission request UI
  //       console.log('No registration token available. Request permission to generate one.');
  //       // ...
  //     }
  //   }).catch((err) => {
  //     console.log('An error occurred while retrieving token. ', err);
  //     // ...
  //   });
  // },[]);

  // return (
  //   <View></View>
  // );