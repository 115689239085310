import { Platform, Alert } from "react-native";
import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js';
import { AlertActionType } from "../contexts/reducers/alertReducer";

export interface AlertConfig {
  title: string,
  message: string,
  action: Function,
  dispatch: Function,
}; 

export function AlertModal(alertConfig: AlertConfig) {
  
  if (Platform.OS === 'web') {
    alertConfig.dispatch({
      type: AlertActionType.ALERTSHOWED,
      payload: {
        show: true,
        title: alertConfig.title,
        message: alertConfig.message,
        action: alertConfig.action,
      }
    });
  } else {
    Alert.alert(alertConfig.title, alertConfig.message, [
      { text: i18n.t('no'), style: 'cancel' },
      { text: i18n.t('yes'), onPress: () => {console.log('hopla');alertConfig.action()} },
    ]);
  }
}

// usage: {i18n.t('loginorregister1')}
const i18n = new I18n({
  nl: {
    yes: 'Ja',
    no: 'Nee',
  },
  fr: {
  },
  en: {
    yes: 'Yes',
    no: 'No',
  },
});
i18n.locale = Localization.locale; // this should happen only once
i18n.enableFallback = true;
// => causes fallback to default below
i18n.locale = 'nl';


