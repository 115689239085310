import { StyleSheet } from 'react-native';
import React from 'react';
import { HeeboRegularTextSmall } from '../StyledText';
import { useThemeColor, View } from '../Themed';

export default function BusinessDetailCardContentText (props:{
  text: string
}) {

  const styles = StyleSheet.create({
    container: {
      padding: 20,
      alignItems: 'center',
    },
    contentContainer: {
      alignItems: 'center',
    },
    text: {
      color: useThemeColor({}, 'text'),
    },
  });

  return (
    <View style={styles.container}>
      <HeeboRegularTextSmall style={styles.text}>{props.text}</HeeboRegularTextSmall>
    </View>
  );
};
