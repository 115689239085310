import { StyleSheet, Image} from 'react-native';

import useColorScheme from '../hooks/useColorScheme';

export function LogoImage() {
  const theme = useColorScheme();

  if (theme=='light') {
    return (
      <Image
        style={styles.logoAuth}
        source={require('../assets/images/logo-black-transp-280.png')}
      />
    );
  }
  if (theme=='dark') {
    return (
      <Image
        style={styles.logoAuth}
        source={require('../assets/images/logo-white-transp-280.png')}
      />
    );
  }
  return null;
};

const styles = StyleSheet.create({
  logoAuth: {
    width: 140,
    height: 140,
    marginVertical: 10,
  },
});
