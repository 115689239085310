import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, Animated, Image, Linking } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import { BusinessStateType } from '../../contexts/reducers/businessListReducer';
import { Address, ImageMetadata, Service } from '../../types/types';
import { fetchImageInstance } from '../../util/imageHelper';
import usePlatform, { imagePadding } from '../../hooks/usePlatform';
import { useCardSizeNotAHookVersion } from '../../hooks/useCardSize';
import { useThemeColor, View } from '../Themed';
import BusinessDetailCardContentButton from './BusinessDetailCardContentButton';
import BusinessDetailCardContentText from './BusinessDetailCardContentText';
import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js';
import SmartServiceIcon from '../SmartServiceIcon';
import axios from 'axios';
import { path } from '../../env/env';
import AddressView from '../AddressView';

export default function BusinessDetailCardContent (props:{
  item: BusinessStateType,
  contentAnimation: Animated.Value,
}) {
  const [ imageMetadatasReady, setImageMetadatasReady ] = useState(false);
  const [ imageMetadatas, setImageMetadatas] = useState<(ImageMetadata)[]>([]);
  const [ amountOfMainImages, setAmountOfMainImages ] = useState(0);
  const [ imageComponents, setImageComponents] = useState<(JSX.Element)[]>([]); // custom hook that updates state using ref instead of load-time
  const [ imageComponentsReady, setImageComponentsReady ] = useState(false);
  const [ currentImage, setCurrentImage ] = useState(-1);
  const [ fetchingServices, setFetchingServices ] = useState(false);
  const [ services, setServices ] = useState<Service[]|null>(null);
  const [ addresses, setAddresses ] = useState<Address[]>([]);
  
  const fadeAnimation = useRef(new Animated.Value(1)).current;
  const fadeTransitionTime = 500; 
  const fadeInterval = 5000;
  const fadeIn = () => {
    // Will change fadeAnim value to 1 in 1 seconds
    Animated.timing(fadeAnimation, {
      toValue: 1,
      duration: fadeTransitionTime,
      useNativeDriver: true,
    }).start();
  };

  const platform = usePlatform();
  const aspectRatio = 0.64;
  const cardWidth = platform.windowWidth-imagePadding;
  const cardHeight = cardWidth*aspectRatio;
  const cardDimension = {
    width: cardWidth,
    height: cardHeight,
  };

  function fetchServices() {
    if (fetchingServices) return;

    setFetchingServices(true);
    axios({
      method: 'GET',
      url: path.services,
    })
    .then(function (response) {
      setServices(response.data.items);
      setFetchingServices(false);
    })
    .catch(function (error) {
      console.log(error.response.data.message);
    })
    .then(function () {
      // always executed
    });
  };

  const fadeOut = () => {
    // Will change fadeAnim value to 0 in 1 seconds
    Animated.timing(fadeAnimation, {
      toValue: 0,
      duration: fadeTransitionTime,
      useNativeDriver: true,
    }).start();
  };

  const styles = StyleSheet.create({
    container: {
    },
    card: {
      width: cardDimension.width,
      heigth: cardDimension.height,
      borderRadius: 16,
    },
    fullContents: {
      padding: 16,
    },
    detailContentText: {
      marginBottom: 24,
    },
    detailContentImage: {
      width: 260,
      height: 180,
      alignSelf: 'center',
      borderRadius: 16,
      // opacity: 0.8,
    },
    linkText: {
      color: useThemeColor({}, 'linkinverse'),
      fontStyle: 'italic',
      textDecorationLine: 'underline',
    },
    marginServices: {
      marginTop: 20,
    },
    horizontal: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      paddingHorizontal: imagePadding,
      paddingVertical: 10,
      backgroundColor: useThemeColor({}, 'link'),
    },
  });
  
  const { item } = props;
  if (!item) {
    return null;
  }

  const {
    imageUuids,
    payload: {
      detailText,
    },
  } = item;

  const fullContentsStyle = [
    styles.fullContents,
    {
      opacity: props.contentAnimation.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1],
      }),
    },
  ];

  // Fetch all image metadata
  useEffect(() => {
    // Create Image objects via statefunction
    if (!services) fetchServices();
    
    if (imageUuids) {
      imageUuids.map( (imageUuid, index) => {
        if (imageUuid.mainImage) {
          setAmountOfMainImages(currentAmountOfMainImages => currentAmountOfMainImages+1);
        };

        fetchImageInstance(
          appendImageMetadata,
          imageUuids,
          'lg',
          imageUuid.mainImage,
          imageUuid.uuid
        );
      });
    }
  }, []);

  // Fetch all addresses based in locationUuids
  useEffect(() => {

    // add first draft address if there are none and return
    if (!item.payload.locationUuids || item.payload.locationUuids.length===0) return;

    item.payload.locationUuids.map(locationUuid => {
      axios({
        method: 'GET',
        url: path.addresses+'/'+locationUuid,
      })
      .then(function (response) {
        if (!response.data.item) return;
        setAddresses(prevAddresses => [...prevAddresses, response.data.item]);
      })
      .catch(function (error) {
        console.log(error.response.data.message);
      })
      .then(function () {
        // always executed
      });
    });
  }, []);

  // update imageMetadata state as callback
  function appendImageMetadata (imageMetadata: ImageMetadata) {
    setImageMetadatas(currentImageMetadatas => [...currentImageMetadatas, imageMetadata]);
  };

  // once al image metadata is fetched, proceed
  useEffect(() => {
    if (imageMetadatas.length==imageUuids.length) {
      setImageMetadatasReady(true);;
    };
  }, [imageMetadatas]);

  // once al image metadata is fetched, proceed
  useEffect(() => {
    if (!imageMetadatasReady) return;
    
    imageUuids.map((imageUuid, index) => {
      const imageMetadataIndex = imageMetadatas.findIndex(imageMetadata => imageMetadata && imageMetadata.uuid == imageUuid.uuid);
      const imageMetadata = imageMetadatas[imageMetadataIndex]

      if (!imageMetadata) return; // should not happen

      const imageSize = useCardSizeNotAHookVersion(platform, imageMetadata.width, imageMetadata.height, true);
  
      const imageComponent: JSX.Element = <Animated.View
          style={[
            // styles.something,
            {
              opacity: fadeAnimation,
            },
        ]}>
        <Image
          key={`detailContent-${imageMetadata.uuid}`}
          source={{uri: imageMetadata.uri}}
          style={[styles.detailContentImage, {
            width: cardWidth,
            height: cardHeight,
          }]}
        />
      </Animated.View>;
  
      if (!imageUuid.mainImage && imageMetadata.uri) {
        setImageComponents(oldImageComponents => [...oldImageComponents, imageComponent]); // important to get the latest array, not the initialization
      }
    });
  }, [imageMetadatasReady]);

  const backupImage: JSX.Element = <Animated.View>
    <Image
      key={`detailContent-backupimage`}
      source={require('../../assets/images/empty_image.jpg')}
      style={[styles.detailContentImage, {
        width: cardWidth,
        height: cardHeight,
      }]}
    />
  </Animated.View>;

  // once al image components are ready, proceed
  useEffect(() => {
    if (imageComponents.length==imageUuids.length-amountOfMainImages) {
      setImageComponentsReady(true);
    };
  }, [imageComponents]);

  useEffect(() => {
    if (imageComponents.length==0) return;
    
    setCurrentImage(0);
    const interval = setInterval(() => {
      fadeOut();
      setTimeout(() => {
        setCurrentImage((current) => (current+1)%imageComponents.length);
        fadeIn();
      }, fadeTransitionTime);
    }, fadeInterval);
    return () => clearInterval(interval)
  }, [imageComponentsReady])

  let externalLink = item ? item.payload.externalLink : '';
  try {
    if (externalLink!='') {
      const url = new URL(externalLink);
    }
  } catch (error) {
    externalLink='';
  }

  let email : string|null = null;
  addresses.map(address => {
    if (!email && address.email) {
      email = address.email;
      return;
    }
  });
  
  return (
    <View style={styles.container}>
      <Animated.View style={fullContentsStyle}>
        <View style={styles.card}>
          {currentImage!=-1 && imageComponents[currentImage]}
          {currentImage==-1 && backupImage}
          {email && <BusinessDetailCardContentButton text={i18n.t('getintouch')} orientation='left' onPress={()=>Linking.openURL('mailto:'+email)}></BusinessDetailCardContentButton>}
          {externalLink!='' && <BusinessDetailCardContentButton text={i18n.t('visitwebsite')} orientation='right' onPress={() => WebBrowser.openBrowserAsync(externalLink)}></BusinessDetailCardContentButton>}
        </View>
        <BusinessDetailCardContentText text={props.item.payload.detailText}></BusinessDetailCardContentText>
        {services && <View style={styles.marginServices}>
          <SmartServiceIcon
            pathAttribute=''
            services={services}
            activeServices={props.item.payload.serviceUuids}
            showOnlyActive={true}
          />
        </View>}
        {addresses.length>0 && <View style={styles.horizontal}>
          {addresses.map(address => <AddressView key={'addressview-'+address.uuid} address={address}/>)}
        </View>}
      </Animated.View>
    </View>
  );
};

// usage: {i18n.t('loginorregister1')}
const i18n = new I18n({
  nl: {
    visitwebsite: 'Website',
    getintouch: 'Contact',
  },
  fr: {
  },
  en: {
    visitwebsite: 'Website',
    getintouch: 'Get in touch!',
  },
});
i18n.locale = Localization.locale; // this should happen only once
i18n.enableFallback = true;
// => causes fallback to default below
i18n.locale = 'nl';