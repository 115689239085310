import { StyleSheet, Text, Animated, Image, View, Touchable, TouchableOpacity } from 'react-native';
import React, { useContext, useEffect, useImperativeHandle } from 'react';
import { useState } from 'react';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';

import { PostStateType } from "../contexts/reducers/postListReducer";
import usePlatform, { maxWidth } from '../hooks/usePlatform';
import { AppContext } from '../contexts';
import { useThemeColor } from './Themed';
import { HeeboBoldText, HeeboRegularText, HeeboRegularTextMuted } from './StyledText';
import axios from 'axios';
import { path } from '../env/env';
import { UserActionType } from '../contexts/reducers/userReducer';

const PostDetailCardFloatingFollow = React.forwardRef((props:{
  ref: React.RefObject<unknown>,
  item: PostStateType | null,
}, ref) => {
  const [ isVisible, setIsVisible ] = useState(false);
  const [ following, setFollowing ] = useState(false);
  
  const { state, dispatch } = useContext(AppContext);

  const platform  = usePlatform();

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      left: 16,
      right: 16,
      bottom: 12,
      backgroundColor: useThemeColor({}, 'modal'),
      borderRadius: 8,
      padding: 8,
      flexDirection: 'row',
      alignItems: 'center',
      // Shadow generator: https://ethercreative.github.io/react-native-shadow-generator/
      shadowColor: useThemeColor({}, 'shadow'),
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowOpacity: 0.36,
      shadowRadius: 6.68,
      elevation: 11,
    },
    content: {
      flex: 1,
      justifyContent: 'center',
      paddingLeft: 12,
    },
    categoryText: {
      fontSize: platform.normalize(13),
      color: useThemeColor({}, 'text'),
    },
    appNameText: {
      fontSize: platform.normalize(18),
      color: useThemeColor({}, 'text'),
    },
    subText: {
      fontSize: platform.normalize(14),
      color: useThemeColor({}, 'text'),
    },
    profileImage: {
      width: 40,
      height: 40,
      borderRadius: 6,
    },
    following: {
      borderRadius: 20,
      paddingVertical: 4,
      paddingHorizontal: 8,
      backgroundColor: useThemeColor({}, 'bluelight'),
    },
    notFollowing: {
      borderRadius: 20,
      paddingVertical: 4,
      paddingHorizontal: 8,
      backgroundColor: useThemeColor({}, 'textmuted'),
    },
    downloadText: {
      // fontSize: Platform.normalize(12),
      fontWeight: 'bold',
      color: useThemeColor({}, 'textinverse'),
    },
  });

  const [ showAnimation, setShowAnimation ] = useState(new Animated.Value(0));
  const [ containerStyle, setContainerStyle] = useState([
    styles.container,
    {
      transform: [
        {
          translateY: showAnimation.interpolate({
            inputRange: [0, 1],
            outputRange: [80,  - 15 -useBottomTabBarHeight() -64], // header height instead of 64?
          }),
        },
      ],
    },
  ]);

  useImperativeHandle(ref, () => ({
     hide (animated: boolean = true) {
      if (!isVisible) {
        return;
      }
  
      setIsVisible(false);
  
      if (!animated) {
        showAnimation.setValue(0);
        return;
      }
  
      Animated.spring(showAnimation, {
        toValue: 0,
        useNativeDriver: true,
      }).start();
    },
    show () {
      if (isVisible) {
        return;
      }
      setIsVisible(true);
  
      Animated.spring(showAnimation, {
        toValue: 1,
        useNativeDriver: true,
      }).start();
    },
  }));

  if (!state.postDetail.item) return null;
  const {
    firstName,
    lastName,
    author,
  } = state.postDetail.item;

  useEffect(
    () => {
      if (!state.auth.uuid) return;
      if (state.user.fetched) return;
      
      axios({ // todo this call should be centralized somewhere after logging in
        method: 'GET',
        url: path.users+'/'+state.auth.uuid,
      })
      .then(function (response) {
        dispatch({
          type: UserActionType.SET_USER,
          payload: {
            admin: response.data.user.admin,
            fetched: true,
            firstName: response.data.user.firstName,
            lastName: response.data.user.lastName,
            following: response.data.user.following,
            mutedUsers: response.data.user.mutedUsers,
            blockedUsers: response.data.user.blockedUsers,
            profileImage: response.data.user.profileImage,
          },
        });
      })
      .catch(function (error) {
        console.log('Error in handling response');
        console.log(error);
      })
      .then(function () {
        // always executed
      });
    }, [state.auth],
  );

  useEffect(
    () => {
      if (!state.user.fetched) return;
      const following = state.user.following ? state.user.following.findIndex(uuid => uuid===author) : false;
      setFollowing(following==-1 ? false : true);
    }, [state.user, state.user.following],
  );

  function handleFollowButton () {
    axios({
      method: following ? 'DELETE' : 'POST', // this is the old following state that will be reversed in the back-end
      url: path.users+'/'+author+'/follow',
    })
    .then(function (response) {
      // always executed
      const index = state.user.following? state.user.following.findIndex(uuid => author == uuid) : -2;
      console.log(index);
      if (index==-2) {
        dispatch({
          type: UserActionType.SET_USER,
          payload: {
            admin: state.user.admin,
            fetched: true,
            firstName: state.user.firstName,
            lastName: state.user.lastName,
            following: [author], // not push, as it only returns the new element
            mutedUsers: state.user.mutedUsers,
            blockedUsers: state.user.blockedUsers,
            profileImage: state.user.profileImage,
          },
        });
      } else if (index==-1) {
        dispatch({
          type: UserActionType.SET_USER,
          payload: {
            admin: state.user.admin,
            fetched: true,
            firstName: state.user.firstName,
            lastName: state.user.lastName,
            following: state.user.following.concat([author]), // not push, as it only returns the new element
            mutedUsers: state.user.mutedUsers,
            blockedUsers: state.user.blockedUsers,
            profileImage: state.user.profileImage,
          },
        });
      } else {
        const newFollowing = [...state.user.following];
        newFollowing.splice(index, 1);
        dispatch({
          type: UserActionType.SET_USER,
          payload: {
          admin: response.data.user.admin,
            fetched: true,
            firstName: state.user.firstName,
            lastName: state.user.lastName,
            following: newFollowing,
            mutedUsers: state.user.mutedUsers,
            blockedUsers: state.user.blockedUsers,
            profileImage: state.user.profileImage,
          },
        });
      }
    })
    .catch(function (error) {
      console.log('Error in handling response');
      console.log(error);
    })
    .then(function () {
    });
  };

  const profileImage = state.user.profileImage=='' ? 'http://localhost:19006/favicon.ico' : state.user.profileImage;
  const followers = state.user.following ? state.user.following.length : 0;

  // Don't show if not logged in or if you're the owner
  if (!state.auth.uuid) return null;
  if (state.auth.uuid==author) return null;

  return (
    <Animated.View ref={ref} style={containerStyle}>
      {profileImage!='' && <Image source={{uri: profileImage}} style={styles.profileImage} />}
      <View style={styles.content}>
        <Text style={styles.categoryText}>{followers>0 && followers}{followers>0 && ' follower'}{followers>1 && 's'}</Text>
        <Text style={styles.appNameText}>{firstName} {lastName}</Text>
      </View>
      <TouchableOpacity onPress={handleFollowButton}>
        {!following && <View style={styles.notFollowing}>
          <HeeboBoldText style={styles.downloadText}>Follow</HeeboBoldText>
        </View>}
        {following && <View style={styles.following}>
          <HeeboRegularTextMuted>Following</HeeboRegularTextMuted>
        </View>}
      </TouchableOpacity>
    </Animated.View>
  );
});

export default PostDetailCardFloatingFollow;
