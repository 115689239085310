import { StyleSheet, Animated, Platform } from 'react-native';
import React from 'react';
import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js';

import { useThemeColor } from './Themed';
import { HeeboBoldText, HeeboRegularTextSmall } from './StyledText';
import { ImageOverlay } from './ImageOverlay';

export default function DateIcon (props:{
  startDate: Date,
  endDate: Date,
  detailMode: Boolean,
}) {

  const size = 60;
  const margin = 24;
  const borderRadius = 14;

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      right: margin,
      // top: margin,
      justifyContent: 'center',
      alignItems: 'center',
      // opacity: 0.5,
      backgroundColor: useThemeColor({}, 'textinverse'),
      borderRadius: borderRadius,
      width: size,
      height: size,
    },
    top: {
      top: margin,
    },
    bottom: {
      bottom: margin,
    },
    textColor: {
      color: useThemeColor({}, 'text'),
      zIndex: 10,
    },
  });

  const startDay : string = props.startDate.getDate().toString();
  const endDay : string = props.endDate.getDate().toString();
  const day = (startDay == endDay) ? startDay : startDay+'-'+endDay;
  const month = Platform.OS === 'android' ? i18n.t((props.startDate.getMonth()+1).toString()) : props.startDate.toLocaleString('default', { month: 'short' });
  // const month = props.startDate.toLocaleString('default', { month: 'short' });
  // const month = props.startDate.getMonth();
  
  return (
    <Animated.View style={[styles.container, props.detailMode? styles.bottom : styles.top]}>
      <HeeboBoldText style={styles.textColor}>{day}</HeeboBoldText>
      <HeeboRegularTextSmall style={styles.textColor}>{month}</HeeboRegularTextSmall>
    </Animated.View>
  );
};

// usage: {i18n.t('loginorregister1')}
const i18n = new I18n({
  nl: {
    1: 'jan',
    2: 'feb',
    3: 'maa',
    4: 'apr',
    5: 'mei',
    6: 'jun',
    7: 'jul',
    8: 'aug',
    9: 'sep',
    10: 'okt',
    11: 'nov',
    12: 'dec',
  },
  fr: {
  },
  en: {
    1: 'jan',
    2: 'feb',
    3: 'mar',
    4: 'apr',
    5: 'may',
    6: 'jun',
    7: 'jul',
    8: 'aug',
    9: 'sep',
    10: 'oct',
    11: 'nov',
    12: 'dec',
  },
});
i18n.locale = Localization.locale; // this should happen only once
i18n.enableFallback = true;
// => causes fallback to default below
i18n.locale = 'nl';