import { PostType } from "../../types/types";

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      }
};

export const POST_CARD_CONTENT_TYPE = {
  TEXT: 'TEXT',
  DOWNLOAD: 'DOWNLOAD',
  IMAGE: 'IMAGE',
  POST: 'POST',
  BUSINESS: 'BUSINESS',
  CLUB: 'CLUB',
  EVENT: 'EVENT',
  CAR: 'CAR',
};

export type PostCorePayload = {
  type: string,
  mainText: string,
  detailText: string,
  externalLink: string,
  slug: string,
  imageHeaderText?: string,
  imageBottom?: {
    text: string,
    type: 'NORMAL' | 'BOLD',
  },
};

export interface PostPayload extends PostCorePayload {
  categoryText?: string, // text
  subText?: string, // text
  appName?: string, // download
  category?: string, // download
  appImage?: string, // download
  backgroundColor?: string, // download
  color?: string, // download
  buttonBackgroundColor?: string, // download
  buttonColor?: string, // download
}

export const emptyPostPayload : PostPayload = {
  type: '',
  mainText: '',
  detailText: '',
  externalLink: '',
  slug: '',
}

export type PostImage = {
  uuid: string,
  mainImage: boolean,
};

export type Like = {
  userFirstName: string,
  userLastName: string,
  userUuid: string,
};

export type Comment = {
  _id: string,
  userFirstName: string,
  userLastName: string,
  userUuid: string,
  value: string,
  createdAt: Date,
};

export type PostSocial = {
  likes: Like[],
  reported: String[],
  hidden: String[],
  comments: Comment[],
};

export type PostAs = {
  postType: PostType,
  uuid: string,
  name: string,
};

export type PostCoreStateType = {
  uuid: string,
  draft: boolean,
  author: string,
  firstName: string,
  lastName: string,
  updatedAt: Date,
  createdAt: Date,
  imageUuids: PostImage[],
  social: PostSocial,
  postAs?: PostAs,
};

export interface PostStateType extends PostCoreStateType {
  payload: PostPayload,
};

export type PostListStateType = PostStateType[];

// export const fakePostListState: PostListStateType = [
//   {
//     uuid: '4',
//     draft: false,
//     author: 'b34f141b-c2ce-49cc-8025-74603895411c',
//     firstName: 'Peter',
//     lastName: 'Defreyne',
//     updatedAt: new Date(),
//     createdAt: new Date(),
//     imageUuids: [
//       {
//         uuid: '40a96725-4096-4f4f-a5e1-a0e134ef4b73', // require('../../assets/imagesPosts/item-2.jpg'),
//         mainImage: true,
//       }
//     ],
//     social: {
//       likes: [
//         {
//           userFirstName: 'John2',
//           userLastName: 'Appleseed',
//           userUuid: 'UUID',
//         },
//       ],
//       comments: [
//         {
//           userFirstName: 'John2',
//           userLastName: 'Appleseed',
//           userUuid: 'UUID',
//           value: 'This is my comment.',
//         },
//         {
//           userFirstName: 'John3',
//           userLastName: 'Appleseed',
//           userUuid: 'UUID',
//           value: 'And this is mine!',
//         }
//       ],
//     },
//     payload: {
//       itemPayloadCardContentPost: {
//         type: 'POST',
//         mainText: 'Where can i find a steering wheel like this?',
//       },
//       detailContents: [
//         {
//           type: 'TEXT',
//           value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus accumsan cursus dolor in cursus. Proin a dolor lobortis quam ultricies sollicitudin ac eget orci. Cras pretium turpis sed nisl consectetur convallis.',
//         },
//         {
//           type: 'IMAGE',
//           value: require('../../assets/imagesPosts/item-2-1.jpg'),
//           width: 260,
//           height: 180,
//         },
//         {
//           type: 'TEXT',
//           value: 'Nulla fringilla feugiat justo, at convallis eros ullamcorper fringilla. In hac habitasse platea dictumst. Nam id nulla ut eros ornare mattis ut ut eros. Cras suscipit eros augue, eget sagittis mi efficitur quis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec gravida odio nec risus egestas efficitur. Aenean ut porta ante. Nulla orci quam, dapibus id mi quis, facilisis ultricies odio.',
//         },
//         {
//           type: 'TEXT',
//           value: 'Nulla fringilla feugiat justo, at convallis eros ullamcorper fringilla. In hac habitasse platea dictumst. Nam id nulla ut eros ornare mattis ut ut eros. Cras suscipit eros augue, eget sagittis mi efficitur quis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec gravida odio nec risus egestas efficitur. Aenean ut porta ante. Nulla orci quam, dapibus id mi quis, facilisis ultricies odio.',
//         },
//         {
//           type: 'TEXT',
//           value: 'Phasellus purus odio, finibus vel lobortis vitae, hendrerit vitae odio. Donec auctor porta diam sit amet pulvinar. In scelerisque augue nisi, et posuere lorem volutpat in. In tristique elit sed erat scelerisque, non vestibulum nisi posuere. Aliquam et egestas quam. Duis a sollicitudin sem, et ullamcorper tortor. Suspendisse potenti. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi malesuada tellus eget risus dapibus imperdiet. Cras non elit ante. Quisque nec laoreet augue.`',
//         },
//       ],
//     },
//   },
//   {
//     uuid: '1',
//     draft: false,
//     author: 'b34f141b-c2ce-49cc-8025-74603895411c',
//     firstName: 'Peter',
//     lastName: 'Defreyne',
//     updatedAt: new Date(),
//     createdAt: new Date(),
//     imageUuids: [
//       {
//         uuid: '../../assets/imagesPosts/item-2.jpg', // require('../../assets/imagesPosts/item-2.jpg'),
//         mainImage: true,
//       }
//     ],
//     social: {
//       likes: [],
//       comments: [],
//     },
//     payload: {
//       imageHeaderText: 'Fantasy Astrology',
//       imageBottom: {
//         text: "Let's go to a new world right now.",
//         type: 'NORMAL',
//       },
//       detailContents: [
//         {
//           type: 'TEXT',
//           value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus accumsan cursus dolor in cursus. Proin a dolor lobortis quam ultricies sollicitudin ac eget orci. Cras pretium turpis sed nisl consectetur convallis. Aliquam posuere fermentum tellus sit amet pretium. Cras sem ex, posuere vel tempus sit amet, gravida vitae nisi. Proin congue aliquam dictum. Etiam quis neque mattis, ultrices elit nec, pharetra lacus. Phasellus viverra ligula non ante bibendum porttitor. Etiam aliquam molestie facilisis. Donec non ornare metus. Pellentesque non gravida ex.',
//         },
//         {
//           type: 'IMAGE',
//           value: require('../../assets/imagesPosts/item-1-1.jpg'),
//           width: 260,
//           height: 180,
//         },
//         {
//           type: 'TEXT',
//           value: 'Nulla fringilla feugiat justo, at convallis eros ullamcorper fringilla. In hac habitasse platea dictumst. Nam id nulla ut eros ornare mattis ut ut eros. Cras suscipit eros augue, eget sagittis mi efficitur quis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec gravida odio nec risus egestas efficitur. Aenean ut porta ante. Nulla orci quam, dapibus id mi quis, facilisis ultricies odio.',
//         },
//         {
//           type: 'IMAGE',
//           value: require('../../assets/imagesPosts/item-1-2.jpg'),
//           width: 260,
//           height: 360,
//         },
//         {
//           type: 'TEXT',
//           value: 'Phasellus purus odio, finibus vel lobortis vitae, hendrerit vitae odio. Donec auctor porta diam sit amet pulvinar. In scelerisque augue nisi, et posuere lorem volutpat in. In tristique elit sed erat scelerisque, non vestibulum nisi posuere. Aliquam et egestas quam. Duis a sollicitudin sem, et ullamcorper tortor. Suspendisse potenti. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi malesuada tellus eget risus dapibus imperdiet. Cras non elit ante. Quisque nec laoreet augue.`',
//         },
//       ],
//     },
//   },
//   {
//     uuid: '3',
//     draft: false,
//     author: 'b34f141b-c2ce-49cc-8025-74603895411c',
//     firstName: 'Peter',
//     lastName: 'Defreyne',
//     updatedAt: new Date(),
//     createdAt: new Date(),
//     imageUuids: [
//       {
//         uuid: '../../assets/imagesPosts/item-2.jpg', // require('../../assets/imagesPosts/item-2.jpg'),
//         mainImage: true,
//       }
//     ],
//     social: {
//       likes: [],
//       comments: [],
//     },
//     payload: {
//       imageBottom: {
//         text: "Today's\nApp",
//         type: 'BOLD',
//       },
//       itemPayloadCardContentDownload: {
//         type: 'DOWNLOAD',
//         appName: 'Dog Together',
//         category: 'Life & Style',
//         appImage: require('../../assets/imagesPosts/item-3-app.jpg'),
//         backgroundColor: '#222',
//         color: '#fff',
//         buttonBackgroundColor: '#fff',
//         buttonColor: '#222',
//       },
//       detailContents: [
//         {
//           type: 'TEXT',
//           value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus accumsan cursus dolor in cursus. Proin a dolor lobortis quam ultricies sollicitudin ac eget orci. Cras pretium turpis sed nisl consectetur convallis. Aliquam posuere fermentum tellus sit amet pretium. Cras sem ex, posuere vel tempus sit amet, gravida vitae nisi. Proin congue aliquam dictum. Etiam quis neque mattis, ultrices elit nec, pharetra lacus. Phasellus viverra ligula non ante bibendum porttitor. Etiam aliquam molestie facilisis. Donec non ornare metus. Pellentesque non gravida ex.',
//         },
//         {
//           type: 'TEXT',
//           value: 'Nulla fringilla feugiat justo, at convallis eros ullamcorper fringilla. In hac habitasse platea dictumst. Nam id nulla ut eros ornare mattis ut ut eros. Cras suscipit eros augue, eget sagittis mi efficitur quis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec gravida odio nec risus egestas efficitur. Aenean ut porta ante. Nulla orci quam, dapibus id mi quis, facilisis ultricies odio.',
//         },
//         {
//           type: 'IMAGE',
//           value: require('../../assets/imagesPosts/item-3-1.jpg'),
//           width: 260,
//           height: 180,
//         },
//         {
//           type: 'TEXT',
//           value: 'Phasellus purus odio, finibus vel lobortis vitae, hendrerit vitae odio. Donec auctor porta diam sit amet pulvinar. In scelerisque augue nisi, et posuere lorem volutpat in. In tristique elit sed erat scelerisque, non vestibulum nisi posuere. Aliquam et egestas quam. Duis a sollicitudin sem, et ullamcorper tortor. Suspendisse potenti. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi malesuada tellus eget risus dapibus imperdiet. Cras non elit ante. Quisque nec laoreet augue.`',
//         },
//         {
//           type: 'TEXT',
//           value: 'Nulla fringilla feugiat justo, at convallis eros ullamcorper fringilla. In hac habitasse platea dictumst. Nam id nulla ut eros ornare mattis ut ut eros. Cras suscipit eros augue, eget sagittis mi efficitur quis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec gravida odio nec risus egestas efficitur. Aenean ut porta ante. Nulla orci quam, dapibus id mi quis, facilisis ultricies odio.',
//         },
//         {
//           type: 'IMAGE',
//           value: require('../../assets/imagesPosts/item-3-2.jpg'),
//           width: 260,
//           height: 180,
//         },
//         {
//           type: 'TEXT',
//           value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus accumsan cursus dolor in cursus. Proin a dolor lobortis quam ultricies sollicitudin ac eget orci. Cras pretium turpis sed nisl consectetur convallis. Aliquam posuere fermentum tellus sit amet pretium. Cras sem ex, posuere vel tempus sit amet, gravida vitae nisi. Proin congue aliquam dictum. Etiam quis neque mattis, ultrices elit nec, pharetra lacus. Phasellus viverra ligula non ante bibendum porttitor. Etiam aliquam molestie facilisis. Donec non ornare metus. Pellentesque non gravida ex.',
//         },
//       ],
//     },
//   },
//   {
//     uuid: '2',
//     draft: false,
//     author: 'b34f141b-c2ce-49cc-8025-74603895411c',
//     firstName: 'Peter',
//     lastName: 'Defreyne',
//     updatedAt: new Date(),
//     createdAt: new Date(),
//     imageUuids: [
//       {
//         uuid: '../../assets/imagesPosts/item-2.jpg', // require('../../assets/imagesPosts/item-2.jpg'),
//         mainImage: true,
//       }
//     ],
//     social: {
//       likes: [],
//       comments: [],
//     },
//     payload: {
//       itemPayloadCardContentText: {
//         type: 'TEXT',
//         categoryText: 'Featured App',
//         mainText: 'Magic Mirror Ball',
//         subText: 'Everything in the world looks upside down.',
//       },
//       detailContents: [
//         {
//           type: 'TEXT',
//           value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus accumsan cursus dolor in cursus. Proin a dolor lobortis quam ultricies sollicitudin ac eget orci. Cras pretium turpis sed nisl consectetur convallis.',
//         },
//         {
//           type: 'IMAGE',
//           value: require('../../assets/imagesPosts/item-2-1.jpg'),
//           width: 260,
//           height: 180,
//         },
//         {
//           type: 'TEXT',
//           value: 'Nulla fringilla feugiat justo, at convallis eros ullamcorper fringilla. In hac habitasse platea dictumst. Nam id nulla ut eros ornare mattis ut ut eros. Cras suscipit eros augue, eget sagittis mi efficitur quis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec gravida odio nec risus egestas efficitur. Aenean ut porta ante. Nulla orci quam, dapibus id mi quis, facilisis ultricies odio.',
//         },
//         {
//           type: 'TEXT',
//           value: 'Nulla fringilla feugiat justo, at convallis eros ullamcorper fringilla. In hac habitasse platea dictumst. Nam id nulla ut eros ornare mattis ut ut eros. Cras suscipit eros augue, eget sagittis mi efficitur quis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec gravida odio nec risus egestas efficitur. Aenean ut porta ante. Nulla orci quam, dapibus id mi quis, facilisis ultricies odio.',
//         },
//         {
//           type: 'TEXT',
//           value: 'Phasellus purus odio, finibus vel lobortis vitae, hendrerit vitae odio. Donec auctor porta diam sit amet pulvinar. In scelerisque augue nisi, et posuere lorem volutpat in. In tristique elit sed erat scelerisque, non vestibulum nisi posuere. Aliquam et egestas quam. Duis a sollicitudin sem, et ullamcorper tortor. Suspendisse potenti. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi malesuada tellus eget risus dapibus imperdiet. Cras non elit ante. Quisque nec laoreet augue.`',
//         },
//       ],
//     },
//   },
// ];

// export const initialPostListState: PostListStateType = __DEV__ ? fakePostListState : [];
export const initialPostListState: PostListStateType = [];

export type PostCardPositionType = {
  pageX: number,
  pageY: number,
  width: number,
  height: number,
  scale: number,
};

export type PostDetailCardInfoType = {
  pageX: number,
  pageY: number,
  width: number,
  height: number,
  scale: number,
  imageWidth: number,
  imageHeight: number,
  imageMarginLeft: number,
  imageMarginTop: number,
  windowWidth: number,
  windowHeight: number,
  duration: number,
};

export type PostLikeUpdate = {
  postId: string,
  userUuid: string,
  userFirstName: string,
  userLastName: string,
  liked: Boolean,
};

export type PostCommentAdded = {
  _id: string,
  postId: string,
  userUuid: string,
  userFirstName: string,
  userLastName: string,
  value: string,
  createdAt: Date,
};

export type PostCommentDeleted = {
  _id: string,
  postId: string,
};

export enum PostActionType {
  POSTS_FETCHED_ON_MOUNT = 'POSTS_FETCHED_ON_MOUNT',
  POSTS_FETCHED = 'POSTS_FETCHED',
  POST_PUBLISHED = 'POST_PUBLISHED',
  POST_REMOVED = 'POST_REMOVED',
  POST_CLEAR = 'POST_CLEAR',
  POST_LIKED = 'POST_LIKED',
  POST_COMMENT_ADDED = 'POST_COMMENT_ADDED',
  POST_COMMENT_DELETED = 'POST_COMMENT_DELETED',
}

type PostActionPayload = {
  [PostActionType.POSTS_FETCHED_ON_MOUNT]: PostListStateType;
  [PostActionType.POSTS_FETCHED]: PostListStateType;
  [PostActionType.POST_PUBLISHED]: PostStateType;
  [PostActionType.POST_REMOVED]: string;
  [PostActionType.POST_CLEAR]: PostListStateType;
  [PostActionType.POST_LIKED]: PostLikeUpdate;
  [PostActionType.POST_COMMENT_ADDED]: PostCommentAdded;
  [PostActionType.POST_COMMENT_DELETED]: PostCommentDeleted;
};

export type PostAction = ActionMap<PostActionPayload>[keyof ActionMap<
  PostActionPayload
>];

export const postListReducer = (
  state: PostListStateType,
  action: PostAction
) => {
  switch (action.type) {
    case PostActionType.POSTS_FETCHED_ON_MOUNT:
      return action.payload;
    case PostActionType.POSTS_FETCHED:
      return [...state, ...action.payload];
    case PostActionType.POST_PUBLISHED:
      return [action.payload, ...state];
    case PostActionType.POST_REMOVED:
      return state.filter(post => post.uuid !== action.payload);
    case PostActionType.POST_CLEAR:
      return [...initialPostListState];
    case PostActionType.POST_LIKED:
      if (action.payload.liked) {
        let newState = state.map(item => {
          var returnItem = {...item};
          if (item.uuid == action.payload.postId) {
            returnItem.social?.likes.push({
              userFirstName: action.payload.userFirstName,
              userLastName: action.payload.userLastName,
              userUuid: action.payload.userUuid,
            });
          }
          return returnItem;
        })
        return newState;
      } else {
        let newState = state.map(item => {
          var returnItem = {...item};
          if (item.uuid == action.payload.postId) {
            if (item.social && returnItem.social){
              returnItem.social.likes = item.social?.likes.filter(like => like.userUuid !== action.payload.userUuid);
            }
          }
          return returnItem;
        })
        return newState;
      }
    case PostActionType.POST_COMMENT_ADDED:
      {
        let newState = state.map(item => {
          var returnItem = {...item};
          if (item.uuid == action.payload.postId) {
            returnItem.social?.comments.push({
              _id: action.payload._id,
              userFirstName: action.payload.userFirstName,
              userLastName: action.payload.userLastName,
              userUuid: action.payload.userUuid,
              value: action.payload.value,
              createdAt: action.payload.createdAt,
            });
          }
          return returnItem;
        })
        return newState;
      }
    case PostActionType.POST_COMMENT_DELETED:
      {
        let newState = state.map(item => {
          var returnItem = {...item};
          if (item.uuid == action.payload.postId) {
            if (item.social && returnItem.social){
              returnItem.social.comments = item.social?.comments.filter(comment => comment._id !== action.payload._id);
            }
          }
          return returnItem;
        })
        return newState;
      }
    default:
      return state;
  }
};
