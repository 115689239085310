import { Platform, StyleSheet, View } from 'react-native';

// import { View } from '../components/Themed';
import { HeeboRegularTextMuted, LobsterRegularText } from '../components/StyledText';
import { Logo } from '../components/Logo';
import { ColoredButton } from '../components/ColoredButton';
import React from 'react';
import { useThemeColor } from '../components/Themed';

export default function WelcomeScreen(props: {
  enterNoCookies: Function,
  enterWithCookies: Function,
}) {

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'space-around',
      backgroundColor: useThemeColor({}, 'background'),
    },
    alignCenter: {
      alignItems: 'center',
    },
    subtitle: {
      padding: 10,
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.alignCenter}>
        <Logo />
        <LobsterRegularText style={styles.subtitle}>classic car community</LobsterRegularText>
      </View>
      <ColoredButton onPress={() => props.enterWithCookies()} text='Enter with cookies' />
      <HeeboRegularTextMuted onPress={() => props.enterNoCookies(false)}>Continue without cookies</HeeboRegularTextMuted>
    </View>
  );
}
