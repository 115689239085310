import React from 'react';
import { ScrollView, Animated, PanResponder, BackHandler, StyleSheet, PanResponderGestureState, GestureResponderEvent, NativeEventSubscription, NativeScrollEvent, NativeSyntheticEvent, ImageLoadEventData } from 'react-native';
import { useState, useEffect, useContext, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useHeaderHeight } from '@react-navigation/elements';

import { AppContext } from "../contexts";
import usePlatform, { imagePadding, maxWidth } from '../hooks/usePlatform';
import PostDetailCardImage from './PostDetailCardImage';
import PostDetailCardContent from './PostDetailCardContent';
import PostDetailCardClose from './PostDetailCardClose';
import PostDetailCardFloatingDownload from './PostDetailCardFloatingDownload';
import { PostDetailActionType } from '../contexts/reducers/postDetailReducer';
import { POST_CARD_CONTENT_TYPE } from '../contexts/reducers/postListReducer';
import PostDetailCardFloatingFollow from './PostDetailCardFloatingFollow';
import { useNavigation } from '@react-navigation/native';
import { RootTabScreenProps } from '../types';
import { useThemeColor } from './Themed';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import { setStatusBarHidden } from 'expo-status-bar';
import { grillImageServerUrl, hostnameFrontEnd } from '../env/env';

// export default function PostDetailCard ({ navigation, route }: RootTabScreenProps<'Feed'>) {
export default function PostDetailCard () {
  const { state, dispatch } = useContext(AppContext);
  const navigation = useNavigation<RootTabScreenProps<'Feed'>['navigation']>();
  const platform = usePlatform();

  const [ shouldStartShowAnimation, setShouldStartShowAnimation ] = useState(false);

  useEffect(() => {
    if (state.businessDetail.info) setShouldStartShowAnimation(true);
  }, [state.businessDetail.info]);

  const floatingFollowScrolltreshold = 0;

  const SWIPE_CLOSE_THRESHOLD = 30;
  
  enum SWIPE_MODE {
    HORIZONTAL = 'HORIZONTAL',
    VERTICAL = 'VERTICAL',
  };

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      backgroundColor: useThemeColor({}, 'subtlebackground'),
      zIndex: 20,
      maxWidth: maxWidth,
    },
    contentContainer: {
      paddingHorizontal: imagePadding,
      alignItems: 'center',
      paddingBottom: 50,
    },
  });
  
  const [ hasSwipeCloseAnimation, setHasSwipeCloseAnimation ] = useState(false);
  const panResponder = PanResponder.create({
    onMoveShouldSetPanResponder: onMoveShouldSetPanResponder,
    onPanResponderGrant: onPanResponderGrant,
    onPanResponderMove: onPanResponderMove,
    onPanResponderRelease: onPanResponderRelease,
    onPanResponderTerminate: onPanResponderRelease,
  });
  const floatingRef = React.useRef<any>(null); // <any> should rather be e.g. <HTMLInputElement> (= random example of html element)
  const scrollViewRef = useRef<ScrollView>(null);

  let lastScrollY = useRef(0);
  const swipeCloseAnimation = useRef(new Animated.Value(0));
  const cardAnimation = useRef(new Animated.Value(0));
  const contentAnimation = useRef(new Animated.Value(0));
  let isHiding = useRef(false);
  let swipeMode = useRef(SWIPE_MODE.HORIZONTAL); // Should be uninitialized but don't know how, probably doesn't hurt
  let floatingDownloadScrollThreshold = useRef(0);

  useEffect(() => {
    // componentDidMount
    let backkHandlerEvent: NativeEventSubscription | null = null;
    if (platform.isAndroid) {
      backkHandlerEvent = BackHandler.addEventListener('hardwareBackPress', onPressHardwareBack);
    }

    return () => {
      // componentWillUnmount
      backkHandlerEvent && backkHandlerEvent.remove();
    };
  }, []);

  function onPressHardwareBack () {
    if (state.postDetail.item) {
      hide();
      return true;
    }

    return false;
  }

  function onMoveShouldSetPanResponder (event: GestureResponderEvent, gestureState: PanResponderGestureState) {
    if (isHiding.current) {
      return false;
    }

    const {
      moveX,
      dx,
      dy,
    } = gestureState;

    if (moveX < 44 && dx > 4) {
      swipeMode.current = SWIPE_MODE.HORIZONTAL;
      return true;
    } if (lastScrollY.current === 0 && dy > 4) {
      swipeMode.current = SWIPE_MODE.VERTICAL;
      return true;
    }

    return false;
  }

  function onPanResponderGrant () {
    swipeCloseAnimation.current.setValue(0);
    // setHasSwipeCloseAnimation(true);
  }
  
  function onPanResponderMove (event: GestureResponderEvent, gestureState: PanResponderGestureState) {
    const move = swipeMode.current === SWIPE_MODE.HORIZONTAL ? gestureState.dx : gestureState.dy;

    if (move > SWIPE_CLOSE_THRESHOLD) {
      swipeHide();
      return;
    }

    swipeCloseAnimation.current.setValue(move / SWIPE_CLOSE_THRESHOLD);
  }

  function onPanResponderRelease (event: GestureResponderEvent, gestureState: PanResponderGestureState) {
    if (isHiding.current) return;

    const move = swipeMode.current === SWIPE_MODE.HORIZONTAL ? gestureState.dx : gestureState.dy;

    if (move >= SWIPE_CLOSE_THRESHOLD / 2) {
      swipeHide();
      return;
    }

    Animated.timing(swipeCloseAnimation.current, {
      toValue: 0,
      duration: 150,
      useNativeDriver: false
    }).start(() => {
      setHasSwipeCloseAnimation(false);
    });
  }

  useEffect(() => {
    if (state.postDetail.isImageLoaded) {
      // if (state.postDetail.info?.imageHeight) {
      //   floatingDownloadScrollThreshold.current = state.postDetail.info?.imageHeight + floatingFollowScrolltreshold;
      // } else {
        floatingDownloadScrollThreshold.current = floatingFollowScrolltreshold; // fallback if image isn't loaded yet
      // }
      startShowAnimation();
    }
  }, [state.postDetail.isImageLoaded]);

  function onLoadImage (event: NativeSyntheticEvent<ImageLoadEventData>|null) {
    dispatch({
      type: PostDetailActionType.POST_SET_IS_IMAGE_LOADED,
      payload: true,
    });
    // Moved these action to useEffect below to make synchronous
    // if (state.postDetail.info?.imageHeight) {
    //   floatingDownloadScrollThreshold.current = state.postDetail.info?.imageHeight + 100;
    // } else {
    //   floatingDownloadScrollThreshold.current = 100; // fallback if image isn't loaded yet
    // }
    // startShowAnimation();
  }
  useEffect(() => {
    if (state.postDetail.isImageLoaded) {
      // if (state.postDetail.info?.imageHeight) {
      //   floatingDownloadScrollThreshold.current = state.postDetail.info?.imageHeight + floatingFollowScrolltreshold;
      // } else {
        floatingDownloadScrollThreshold.current = floatingFollowScrolltreshold; // fallback if image isn't loaded yet
      // }
      startShowAnimation();
    }
  }, [state.postDetail.isImageLoaded]);

  function startShowAnimation () {

    if (!state.postDetail.info) {
      return;
    }

    setStatusBarHidden(true, true ? 'slide' : 'none');

    cardAnimation.current.setValue(0);
    contentAnimation.current.setValue(0);

    Animated.sequence([
      Animated.timing(cardAnimation.current, {
        toValue: 1,
        duration: state.postDetail.info.duration,
        useNativeDriver: false,
      }),
      Animated.timing(contentAnimation.current, {
        toValue: 1,
        duration: state.postDetail.info.duration,
        useNativeDriver: false,
      }),
    ]).start();
  }

  function onPressClose () {
    hide();
  }

  function swipeHide() {
    if (isHiding.current) return;
    isHiding.current = true;

    Animated.timing(swipeCloseAnimation.current, {
      toValue: 2,
      duration: 250,
      // overshootClamping: true,
      useNativeDriver: false,
    }).start(() => {
      setStatusBarHidden(false, true ? 'slide' : 'none'); // show

      navigation.navigate('Feed');
      dispatch({
        type: PostDetailActionType.POST_HIDE,
        payload: null,
      });

      isHiding.current = true;

      setHasSwipeCloseAnimation(false);
    });
  }

  function hide () {
    if (floatingRef.current) {
      floatingRef.current.hide(false)
    }

    if (lastScrollY.current > 0) {
      scrollViewRef.current && scrollViewRef.current.scrollTo({ y: 0, animated: true });
    }

    Animated.parallel([
      Animated.timing(contentAnimation.current, {
        toValue: 0,
        duration: state.postDetail.info?.duration,
        useNativeDriver: false,
      }),
      Animated.spring(cardAnimation.current, {
        toValue: 0,
        tension: 25,
        overshootClamping: true,
        useNativeDriver: false
      }),
    ]).start(() => {
      setStatusBarHidden(false, true ? 'slide' : 'none'); // show

      navigation.navigate('Feed');
      dispatch({
        type: PostDetailActionType.POST_HIDE,
        payload: null,
      });
    });
  }
  
  const headerHeight = useHeaderHeight();
  const bottomTabBarHeight = useBottomTabBarHeight(); // not in getRenderVariables because or wrong order rerenderings

  function getRenderVariables () {

    const containerStyle = [styles.container];
    
    if (state.postDetail.info && state.postDetail.isImageLoaded) {
      if (hasSwipeCloseAnimation) {
        // containerStyle.push({
        //   left: 0,
        //   top: swipeCloseAnimation.current.interpolate({
        //     inputRange: [0, 1, 2],
        //     outputRange: [0, 0, (Number(state.postDetail.info.pageY) - 20)],
        //   }),
        //   width: platform.windowWidth,
        //   height: platform.windowHeight - useBottomTabBarHeight(),
        //   borderRadius: swipeCloseAnimation.current.interpolate({
        //     inputRange: [0, 1],
        //     outputRange: [0, 16],
        //   }),
        //   transform: [{
        //     scale: swipeCloseAnimation.current.interpolate({
        //       inputRange: [0, 1, 2],
        //       outputRange: [1, 0.9, 0.95],
        //     }),
        //   }],
        // });
      } else {
        containerStyle.push({
          // left: cardAnimation.current.interpolate({
          //   inputRange: [0, 0.7, 1],
          //   outputRange: [state.postDetail.info.pageX, -20, 0],
          // }),
          top: cardAnimation.current.interpolate({
            inputRange: [0, 0.7, 1],
            outputRange: [state.postDetail.info.pageY-headerHeight, -20, 0],
          }),
          width: cardAnimation.current.interpolate({
            inputRange: [0, 0.7, 1],
            outputRange: [state.postDetail.info.width, state.postDetail.info.windowWidth + 40, state.postDetail.info.windowWidth],
          }),
          height: cardAnimation.current.interpolate({
            inputRange: [0, 1],
            outputRange: [state.postDetail.info.height, state.postDetail.info.windowHeight-bottomTabBarHeight],
          }),
          borderRadius: cardAnimation.current.interpolate({
            inputRange: [0, 1],
            outputRange: [16, 0],
          }),
        });
      }
    }

    return {
      containerStyle,
    };
  }

  function onScroll (event: NativeSyntheticEvent<NativeScrollEvent>) {
    lastScrollY.current = event.nativeEvent.contentOffset.y;

    const hasFloatingDownload = state.postDetail.item?.payload ? state.postDetail.item?.payload?.type === POST_CARD_CONTENT_TYPE.DOWNLOAD : false;
    const hasFloatingPost = state.postDetail.item?.payload ? state.postDetail.item?.payload?.type === POST_CARD_CONTENT_TYPE.POST : false;
    if (!(hasFloatingDownload || hasFloatingPost) || !floatingRef.current) {
      return;
    }
    if (lastScrollY.current > floatingDownloadScrollThreshold.current) {
      floatingRef.current.show();
    } else {
      floatingRef.current.hide();
    }
  }
  
  const hasFloatingDownload = state.postDetail.item?.payload ? state.postDetail.item?.payload?.type === POST_CARD_CONTENT_TYPE.DOWNLOAD : false;
  const hasFloatingPost = state.postDetail.item?.payload ? state.postDetail.item?.payload?.type === POST_CARD_CONTENT_TYPE.POST : false;

  if (!state.postDetail.item) {
    return null;
  }

  const {
    containerStyle,
  } = getRenderVariables();
  
  if (shouldStartShowAnimation) {
    startShowAnimation();
    setShouldStartShowAnimation(false);
  }

  // Prep meta content
  const imageUuid = state.postDetail.item.imageUuids.find((imageUuid) => imageUuid.mainImage);
  const descriptionLength = Math.min(state.postDetail.item.payload.detailText.length, 200);
  const meta = {
    title: state.postDetail.item.payload.mainText,
    author: state.postDetail.item.author,
    description: state.postDetail.item.payload.detailText.substring(0, descriptionLength),
    image: imageUuid ? grillImageServerUrl+'/'+imageUuid.uuid+'/fl_ls' : '',
    url: hostnameFrontEnd+state.postDetail.item.uuid,
    type: 'summary_large_image',
    // keywords: 'KEYWORDS_PLACEHOLDER',
    // slug: 'SLUG_PLACEHOLDER'
  };

  return (
    <Animated.View style={containerStyle} {...panResponder.panHandlers}>
      <Helmet>
        <title>{meta.title}</title>
        <meta  name="description" content={meta.description} />
        {/* <meta name="keywords" content={meta.keywords} /> */}

        <meta property="og:title" content={meta.title} />
        <meta name="description" content={meta.description} />
        <meta property="og:description" content={meta.description} />
        <meta property="og:image" content={meta.image} />
        <meta property="og:url" content={meta.url} />
        <meta property="og:type" content={meta.type} />

        <meta name="twitter:card" content={meta.type} />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
        <meta name="twitter:image" content={meta.image} />
        
        <meta name="author" content={meta.author} />
        {/* <link rel="canonical" href={slug} /> */}
      </Helmet>
      <ScrollView
        ref={scrollViewRef}
        contentContainerStyle={styles.contentContainer}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        pinchGestureEnabled={false}
        bounces={false}
        onScroll={onScroll}
        scrollEventThrottle={16}
      >
        <PostDetailCardImage
          uuid={state.postDetail.item.uuid}
          info={state.postDetail.info}
          cardAnimation={cardAnimation.current}
          onLoad={onLoadImage}
        />
        <PostDetailCardContent
          item={state.postDetail.item}
          contentAnimation={contentAnimation.current}
        />
      </ScrollView>

      <PostDetailCardClose
        cardAnimation={cardAnimation.current}
        onPress={onPressClose}
      />

      {hasFloatingDownload && <PostDetailCardFloatingDownload ref={floatingRef} item={state.postDetail.item} />}
      {hasFloatingPost && <PostDetailCardFloatingFollow ref={floatingRef} item={state.postDetail.item} />}
    </Animated.View>
  );
};
