import { StyleSheet, Text, Image, View } from 'react-native';
import React, { useEffect, useState } from 'react';
import { FontAwesome } from '@expo/vector-icons';

import usePlatform from '../../hooks/usePlatform';
import { useThemeColor } from './../Themed';
import { HeeboBoldText, HeeboBoldTextSmall, HeeboRegularTextSmall } from './../StyledText';
import { BusinessStateType } from '../../contexts/reducers/businessListReducer';
import useCardSize from '../../hooks/useCardSize';
import { ImageMetadata } from '../../types/types';
import { emptyImageInstance, fetchImageInstance } from '../../util/imageHelper';

export function BusinessCardContent (props: {
  item: BusinessStateType,
  detail?: boolean;
}) {
  if (!props.item) return null;

  const platform  = usePlatform();
  
  const [ imageInstance, setImageInstance] = useState<ImageMetadata>(emptyImageInstance);
  
  useEffect(() => {
    fetchImageInstance(setImageInstance, props.item.imageUuids, 'th', true, '');
  }, []);

  const padding = 8;

  const styles = StyleSheet.create({
    container: {
      borderRadius: 8,
      padding: padding,
      flexDirection: 'row',
      alignItems: 'center',
    },
    innerContainer: {
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
    },
    smallContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    content: {
      justifyContent: 'center',
      paddingLeft: 12,
    },
    mainText: {
      fontSize: platform.normalize(18),
      color: useThemeColor({}, 'text'),
    },
    tagline: {
      fontSize: platform.normalize(13),
      color: useThemeColor({}, 'text'),
    },
    subText: {
      fontSize: platform.normalize(14),
      color: useThemeColor({}, 'text'),
    },
    profileImage: {
      width: 40,
      height: 40,
      borderRadius: 6,
    },
    following: {
      borderRadius: 20,
      paddingVertical: 4,
      paddingHorizontal: padding,
    },
    arrow: {
      fontSize: platform.normalize(24),
      color: useThemeColor({}, 'bluedark'),
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <View style={styles.smallContainer}>
          <Image source={imageInstance.uri!='' ? {uri: imageInstance.uri} : require('../../assets/images/empty_image.jpg')} style={styles.profileImage} />
          <View style={styles.content}>
            <HeeboBoldText style={styles.mainText}>{props.item.payload.mainText}</HeeboBoldText>
            <HeeboRegularTextSmall style={styles.tagline}>{props.item.payload.tagline}</HeeboRegularTextSmall>
          </View>
        </View>
        {!props.detail && <View style={styles.following}>
          <FontAwesome style={styles.arrow} name='arrow-circle-right' />
        </View>}
      </View>
    </View>
  );
};

export default BusinessCardContent;
