import {StyleSheet } from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';

import {useThemeColor} from './Themed';

interface Props {
  width: number,
  height: number,
  borderRadius: number,
}

export function ImageOverlay(props: Props) {

  const styles = StyleSheet.create({
    overlay: {
      position: 'absolute',
      height: props.height,
      width: props.width,
      borderRadius: props.borderRadius,
    },
  });

  return (
    <LinearGradient
      style={styles.overlay}
      start={{x: 0, y: 0}}
      end={{x: 0, y: 1}}
      colors={['rgba(0,0,0,0)', useThemeColor({}, 'textinverse')]}
    />
  );
};
