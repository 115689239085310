import { StyleSheet } from 'react-native';
import React from 'react';
import { FontAwesome } from '@expo/vector-icons';

import { useThemeColor } from './Themed';
import { Menu, MenuOption, MenuOptions, MenuTrigger } from 'react-native-popup-menu';
import { HeeboRegularText } from './StyledText';

export default function PostListCardMenuBullets (props:{
  hidePost: () => void,
  muteUser: () => void,
  blockUser: () => void,
  reportPost: () => void,
}) {

  const styles = StyleSheet.create({
    bulletsPosition: {
      position: 'absolute',
      right: 30,
      top: 30,
      zIndex: 1,
    },
    bulletsText: {
      fontSize: 16,
      textShadowRadius: 1,
      textShadowColor: useThemeColor({}, 'alt2lightest'),
    },
  });

  const menuStyles = { // not a stylesheet for customStyles property of menu!
    menu: {
      optionsContainer: {
        backgroundColor: useThemeColor({}, 'bluelight'),
        borderRadius: 10,
      },
    },
  };

  return (
    <Menu style={styles.bulletsPosition}>
      <MenuTrigger>
        <FontAwesome style={styles.bulletsText} name='ellipsis-h' />
      </MenuTrigger>
      <MenuOptions customStyles={menuStyles.menu}>
        <MenuOption onSelect={props.hidePost} >
          <HeeboRegularText>&nbsp;<FontAwesome name='eye-slash' />&nbsp;Hide post</HeeboRegularText>
        </MenuOption>
        <MenuOption onSelect={props.muteUser} >
          <HeeboRegularText>&nbsp;&nbsp;<FontAwesome name='volume-off' />&nbsp;&nbsp;Mute user</HeeboRegularText>
        </MenuOption>
        <MenuOption onSelect={props.blockUser} >
          <HeeboRegularText>&nbsp;<FontAwesome name='ban' />&nbsp;Block user</HeeboRegularText>
        </MenuOption>
        <MenuOption onSelect={props.reportPost} >
          <HeeboRegularText><FontAwesome name='flag' />&nbsp;&nbsp;Report post</HeeboRegularText>
        </MenuOption>
      </MenuOptions>
    </Menu>
    // <TouchableWithoutFeedback onPress={props.onPress} hitSlop={CLOSE_HITSLOP}>
    //   <FontAwesome style={styles.bulletsText} name='ellipsis-h' />
    // </TouchableWithoutFeedback>
  );
};
