/**
 * Learn more about Light and Dark modes:
 * https://docs.expo.io/guides/color-schemes/
 */

import React from 'react';
import { Text as DefaultText, TextInput, View as DefaultView } from 'react-native';
import { StyleSheet } from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import usePlatform, { imagePadding } from '../hooks/usePlatform';

export function useThemeColor(
  props: { light?: string; dark?: string },
  colorName: keyof typeof Colors.light & keyof typeof Colors.dark
) {
  const theme = useColorScheme();
  const colorFromProps = props[theme];

  if (colorFromProps) {
    return colorFromProps;
  } else {
    return Colors[theme][colorName];
  }
}

type ThemeProps = {
  lightColor?: string;
  darkColor?: string;
};

export type TextProps = ThemeProps & DefaultText['props'];
export type TextInputProps = ThemeProps & TextInput['props'];
export type ViewProps = ThemeProps & DefaultView['props'];

export function Text(props: TextProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const color = useThemeColor({ light: lightColor, dark: darkColor }, 'text');

  return <DefaultText style={[{ color }, style]} {...otherProps} />;
};

export function TextMuted(props: TextProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const color = useThemeColor({ light: lightColor, dark: darkColor }, 'textmuted');

  return <DefaultText style={[{ color }, style]} {...otherProps} />;
};

export function TextError(props: TextProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const color = useThemeColor({ light: lightColor, dark: darkColor }, 'texterror');

  return <DefaultText style={[{ color }, style]} {...otherProps} />;
};

// to support multiline
// multiline = {true}
// numberOfLines = {4}
export function ThemedTextInput(props: TextInputProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const color = useThemeColor({ light: lightColor, dark: darkColor }, 'text');
  const placeholderTextColor=useThemeColor({}, 'textmuted');
  const borderColor = useThemeColor({ light: lightColor, dark: darkColor }, 'text');
  const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, 'bluelight');

  const platform = usePlatform();
  const textinputStyle = StyleSheet.create({
    textInput: {
      fontSize: 16,
      fontFamily: 'Heebo Regular',
      textAlign: 'center',
      paddingVertical: 5,
      // borderWidth: 1, // causes scrollbars to appear in multi-line
      borderRadius: 10,
      marginVertical: 10,
      width: platform.windowWidth*0.9-2*imagePadding,
      maxWidth: 600,
    },
  });

  return <TextInput style={[textinputStyle.textInput, {color}, {borderColor}, {backgroundColor}, style]} placeholderTextColor={placeholderTextColor} {...otherProps} />;
};

export function DefaultReactNativeView(props: ViewProps) {
  return <DefaultView {...props} />;
};

// Usage: <View lightColor='red' darkColor='green' />
export function View(props: ViewProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  // const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, 'background'); // We don't use this

  // return <DefaultView style={[{ backgroundColor }, style]} {...otherProps} />;
  return <DefaultView style={[{  }, style]} {...otherProps} />;
};

// Usage: <WrapperView lightColor='red' darkColor='green' />
export function WrapperView(props: ViewProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, 'background');

  return <DefaultView style={[{ backgroundColor }, styles.wrapper, style]} {...otherProps} />;
};

export function Paragraph(props: ViewProps) {
  const { style, ...otherProps } = props;

  return <DefaultView style={[styles.marginVertical, style]} {...otherProps} />;
};

export function BackgroundGradient(props: ViewProps) {
  const colorScheme = useColorScheme();

  return <LinearGradient
  style={styles.background}
  start={{x: 0, y: 0}} // = 0% & 0%
  end={{x: 1, y: 0.6}} // = 100% & 60%
  colors={[useThemeColor({}, 'greennormal'), useThemeColor({}, 'bluedark')]}
  // colors={[Colors[colorScheme].greennormal, Colors[colorScheme].bluedark]}
  />;
};

export function LogoColors() {
  return {
    left: useThemeColor({ light: '', dark: '' }, 'greennormal'),
    right: useThemeColor({ light: '', dark: '' }, 'bluedark'),
  };
};

const styles = StyleSheet.create({
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  marginVertical: {
    marginVertical: 10,
  },
  wrapper : {
    display: 'flex',
    flex: 1,
    flexDirection: 'column', // column
    //justifyContent: "flex-start", // so this is vertical default value
    alignItems: 'center', // and this is horizontal
    width: '100%',
  },
});

// inspired by https://ethercreative.github.io/react-native-shadow-generator/ level 10, but improved
export const shadowStyle = StyleSheet.create({
  shadow: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.2,
    shadowRadius: 12,
    elevation: 10,
  },
});
