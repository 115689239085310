import { BusinessStateType} from "./businessListReducer";
import { PostDetailCardInfoType } from "./postListReducer";

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      }
};

export type BusinessDetailStateType = {
  item: BusinessStateType | null,
  info: PostDetailCardInfoType | null | undefined,
};

export type BusinessDetailShowedType = {
  item: BusinessStateType | null,
  info: PostDetailCardInfoType | null | undefined,
};

export const initialBusinessDetailState: BusinessDetailStateType = {
  item: null,
  info: null,
};

export enum BusinessDetailActionType {
  BUSINESS_HIDE = 'BUSINESS_HIDE',
  BUSINESS_SHOWED = 'BUSINESS_SHOWED',
}

type BusinessDetailActionPayload = {
  [BusinessDetailActionType.BUSINESS_HIDE]: null;
  [BusinessDetailActionType.BUSINESS_SHOWED]: BusinessDetailShowedType;
};

export type BusinessDetailAction = ActionMap<BusinessDetailActionPayload>[keyof ActionMap<
  BusinessDetailActionPayload
>];

export const businessDetailReducer = (
  state: BusinessDetailStateType,
  action: BusinessDetailAction
) => {
  switch (action.type) {
    case BusinessDetailActionType.BUSINESS_HIDE:
      return {...state, hasDimmedLayer: false, item: null};
    case BusinessDetailActionType.BUSINESS_SHOWED:
      return {...state, item: action.payload.item, info: action.payload.info };
    default:
      return state;
  }
};
