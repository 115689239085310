import { StyleSheet } from 'react-native';
import React, { useContext, useState } from 'react';
import { View } from '../components/Themed';
import {RootTabScreenProps} from '../types';
import BusinessList from '../components/business/BusinessList';
import BusinessDetailCard from '../components/business/BusinessDetailCard';
import { AppContext } from '../contexts';
import { useFocusEffect } from '@react-navigation/native';
import { BusinessDetailActionType } from '../contexts/reducers/businessDetailReducer';

export default function ExpertsScreen({ navigation, route }: RootTabScreenProps<'Experts'>) {
  const { state, dispatch } = useContext(AppContext);
  
  const [ focusOnUuid, setFocusOnUuid ] = useState('');

  useFocusEffect(
    React.useCallback(() => {
      setFocusOnUuid(route.params && route.params.uuid ? route.params.uuid : '');
      return () => {
        setFocusOnUuid('');
        dispatch({
          type: BusinessDetailActionType.BUSINESS_HIDE,
          payload: null,
        });
      }
    }, [route.params?.uuid])
  );

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
    },
  });

  return (
    <View style={styles.container}>
      <BusinessList focusOnUuid={focusOnUuid} />
      <BusinessDetailCard />
    </View>
  );
};
