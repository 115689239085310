import axios from "axios";
import { useEffect, useState } from "react";
import { Platform, StyleSheet } from "react-native";
import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js';
import { Method } from "../env/env";
import { imagePadding } from "../hooks/usePlatform";
import { View, useThemeColor } from "./Themed";
import { format, parseISO } from 'date-fns';
import { DateRange, DayPicker } from 'react-day-picker';
import { HeeboRegularText } from "./StyledText";

export default function SmartDateInput (props:{
  startDate: Date, // ends up being a string here, that's why i add parseISO()
  endDate: Date, // ends up being a string here, that's why i add parseISO()
  dateUpdated: Function,
  savePath: string,
  savedCallback: Function, // not used because is always saved 
}) {

  if (Platform.OS === 'web') { // This doesn't work for mobile, so copying it below in the component itself
    const styles = require('react-day-picker/dist/style.css');
  }

  const [range, setRange] = useState<DateRange | undefined>({
    from: parseISO(props.startDate),
    to: parseISO(props.endDate)
  });

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      width: '100%',
      paddingHorizontal: imagePadding,
      flexDirection: 'column',
      justifyContent: 'center',
    },
  });

  useEffect(() => {
    if (!range) return;

    const tempStartDate = range && range.from ? range.from.toISOString() : props.startDate;
    const tempEndDate = range.to ? range.to.toISOString() : tempStartDate;

    props.dateUpdated({
      startDate: tempStartDate,
      endDate: tempEndDate
    });

    axios({
      method: Method.PUT,
      url: props.savePath,
      data: {
        startDate: tempStartDate,
        endDate: tempEndDate,
      },
    })
    .then(function (response) {
      // props.savedCallback(true); // not used because is always saved
    })
    .catch(function (error) {
      console.log(error.response.data.message);
    })
    .then(function () {
      // always executed
    });
  },[range]);

  let footer = <HeeboRegularText>Please pick a day.</HeeboRegularText>;
  if (range?.from) {
    if (!range.to) {
      footer = <HeeboRegularText>{format(range.from, 'PPP')}</HeeboRegularText>;
    } else if (range.to) {
      footer = (
        <HeeboRegularText>
          {format(range.from, 'PPP')}–{format(range.to, 'PPP')}
        </HeeboRegularText>
      );
    }
  }

  return (
    <View style={styles.container}>
      <DayPicker
        styles={{
          months: { fontFamily: 'Heebo Regular', justifyContent: 'center', color: useThemeColor({}, 'text') },
        }}
        mode="range"
        selected={range}
        // footer={footer}
        onSelect={setRange}
      />
    </View>
  );
};

// usage: {i18n.t('loginorregister1')}
const i18n = new I18n({
  nl: {
    name: 'Naam',
  },
  fr: {
  },
  en: {
    name: 'Name',
  },
});
i18n.locale = Localization.locale; // this should happen only once
i18n.enableFallback = true;
// => causes fallback to default below
i18n.locale = 'nl';
