import { PostCorePayload, PostLikeUpdate, PostCommentAdded, PostCommentDeleted, PostCoreStateType } from './postListReducer';

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      }
};

export interface EventPayload extends PostCorePayload {
  startDate: Date,
  endDate: Date,
  locationUuids: string[],
}

export interface EventStateType extends PostCoreStateType {
  payload: EventPayload,
};

export type EventListStateType = EventStateType[];

export const initialEventListState: EventListStateType = [];

export enum EventActionType {
  EVENT_FETCHED_ON_MOUNT = 'EVENT_FETCHED_ON_MOUNT',
  EVENT_FETCHED = 'EVENT_FETCHED',
  EVENT_PUBLISHED = 'EVENT_PUBLISHED',
  EVENT_REMOVED = 'EVENT_REMOVED',
  EVENT_CLEAR = 'EVENT_CLEAR',
  EVENT_LIKED = 'EVENT_POST_LIKED',
  EVENT_COMMENT_ADDED = 'EVENT_COMMENT_ADDED',
  EVENT_COMMENT_DELETED = 'EVENT_COMMENT_DELETED',
}

type EventActionPayload = {
  [EventActionType.EVENT_FETCHED_ON_MOUNT]: EventListStateType;
  [EventActionType.EVENT_FETCHED]: EventListStateType;
  [EventActionType.EVENT_PUBLISHED]: EventStateType;
  [EventActionType.EVENT_REMOVED]: string;
  [EventActionType.EVENT_CLEAR]: EventListStateType;
  [EventActionType.EVENT_LIKED]: PostLikeUpdate;
  [EventActionType.EVENT_COMMENT_ADDED]: PostCommentAdded;
  [EventActionType.EVENT_COMMENT_DELETED]: PostCommentDeleted;
};

export type EventAction = ActionMap<EventActionPayload>[keyof ActionMap<
  EventActionPayload
>];

export const eventListReducer = (
  state: EventListStateType,
  action: EventAction
) => {
  switch (action.type) {
    case EventActionType.EVENT_FETCHED_ON_MOUNT:
      return action.payload;
    case EventActionType.EVENT_FETCHED:
      return [...state, ...action.payload];
    case EventActionType.EVENT_PUBLISHED:
      return [action.payload, ...state];
    case EventActionType.EVENT_REMOVED:
      return state.filter(event => event.uuid !== action.payload);
    case EventActionType.EVENT_CLEAR:
      return [...initialEventListState];
    case EventActionType.EVENT_LIKED:
      if (action.payload.liked) {
        let newState = state.map(item => {
          var returnItem = {...item};
          if (item.uuid == action.payload.postId) {
            returnItem.social?.likes.push({
              userFirstName: action.payload.userFirstName,
              userLastName: action.payload.userLastName,
              userUuid: action.payload.userUuid,
            });
          }
          return returnItem;
        })
        return newState;
      } else {
        let newState = state.map(item => {
          var returnItem = {...item};
          if (item.uuid == action.payload.postId) {
            if (item.social && returnItem.social){
              returnItem.social.likes = item.social?.likes.filter(like => like.userUuid !== action.payload.userUuid);
            }
          }
          return returnItem;
        })
        return newState;
      }
    case EventActionType.EVENT_COMMENT_ADDED:
      {
        let newState = state.map(item => {
          var returnItem = {...item};
          if (item.uuid == action.payload.postId) {
            returnItem.social?.comments.push({
              _id: action.payload._id,
              userFirstName: action.payload.userFirstName,
              userLastName: action.payload.userLastName,
              userUuid: action.payload.userUuid,
              value: action.payload.value,
              createdAt: action.payload.createdAt,
            });
          }
          return returnItem;
        })
        return newState;
      }
    case EventActionType.EVENT_COMMENT_DELETED:
      {
        let newState = state.map(item => {
          var returnItem = {...item};
          if (item.uuid == action.payload.postId) {
            if (item.social && returnItem.social){
              returnItem.social.comments = item.social?.comments.filter(comment => comment._id !== action.payload._id);
            }
          }
          return returnItem;
        })
        return newState;
      }
    default:
      return state;
  }
};
