
export interface Address {
  uuid: string,
  name: string,
  phone: string,
  email: string,
  street: string,
  number: string,
  zip: string,
  city: string,
  country: string,
  longitude: string,
  latitude: string,
};

export interface ImageSize {
  height: number,
  param: string,
  width: number,
};

export interface ImageMetadata {
  createdAt: Date,
  uuid: string,
  uri: string,
  orientation: string,
  owner: string,
  requestSize: string,
  size: string,
  width: number,
  height: number,
  dominantColor?: {
    r: Number,
    g: Number,
    b: Number,
  },
};

export interface ServerImage {
  uuid: string,
  owner: string,
  orientation: string,
  sizes: ImageSize[],
};

export interface Service {
  uuid: string,
  iconLibrary: string,
  iconName: string,
  fontSize: number,
  name: any,
};

export interface NotificationToken {
  _id: string,
  user: string,
  token: string,
  platform: string,
};

export enum PostType {
  UNDEFINED = 'UNDEFINED',
  POST = 'POST',
  EVENT = 'EVENT',
  CAR = 'CAR',
  CLUB = 'CLUB',
  BUSINESS = 'BUSINESS',
  TEXT = 'TEXT',
  DOWNLOAD = 'DOWNLOAD',
}
 