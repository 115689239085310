/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { FontAwesome } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { ColorSchemeName } from 'react-native';

import AuthScreen from '../screens/AuthScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import PostsScreen from '../screens/PostsScreen';
import PostScreen from '../screens/PostScreen';
import { RootStackParamList, RootTabParamList, RootTabScreenProps } from '../types';
import LinkingConfiguration from './LinkingConfiguration';
import UserComponent from '../components/UserComponent';
import ActivateAccountScreen from '../screens/ActivateAccountScreen';
import ResetPasswordScreen from '../screens/ResetPasswordScreen';
import PrivacyPolicy from '../screens/PrivacyPolicy';
import TermsOfService from '../screens/TermsOfService';
import ClubsScreen from '../screens/ClubsScreen';
import EventsScreen from '../screens/EventsScreen';
import ExpertsScreen from '../screens/ExpertsScreen';
import { useThemeColor } from '../components/Themed';
import AdminScreen from '../screens/AdminScreen';


export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  const theme = colorScheme === 'dark' ? DarkTheme : DefaultTheme;
  if (colorScheme === 'dark') theme.colors.background= 'rgb(32, 32, 32)';
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={theme}>
      <RootNavigator />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  return (
    <Stack.Navigator initialRouteName="GRILL">
      <Stack.Screen name="GRILL" component={BottomTabNavigator} options={{ headerShown: false }} />
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
      <Stack.Screen name="Activate" component={ActivateAccountScreen} options={{ title: 'Activate account' }} />
      <Stack.Screen name="Reset" component={ResetPasswordScreen} options={{ title: 'Reset password' }} />
      <Stack.Screen name="Admin" component={AdminScreen} options={{ title: 'Admin' }} />
      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen name="Profile" component={AuthScreen} />
      </Stack.Group>
      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicy} options={{ title: 'Privacy policy' }} />
      </Stack.Group>
      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen name="TermsOfService" component={TermsOfService} options={{ title: 'Terms of service' }} />
      </Stack.Group>
    </Stack.Navigator>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigator() {

  return (
    <BottomTab.Navigator
      initialRouteName="Feed"
      screenOptions={{
        tabBarActiveTintColor: useThemeColor({}, 'expoicon'),
      }}>
      <BottomTab.Screen
        name="Feed"
        component={PostsScreen}
        options={({ navigation }: RootTabScreenProps<'Feed'>) => ({
          title: 'GRILL',
          headerTintColor: useThemeColor({}, 'expoicon'),
          headerTitleStyle: {
            fontFamily:'Heebo Black',
            fontWeight:'900',
          },
          tabBarIcon: ({ color }) => <TabBarIcon name="road" color={color} />,
          headerRight: () => (<UserComponent navigate={navigation.navigate} />
          ),
        })}
      />
      <BottomTab.Screen
        name="Events"
        component={EventsScreen}
        options={({ navigation }: RootTabScreenProps<'Events'>) => ({
          title: 'Events',
          headerTintColor: useThemeColor({}, 'expoicon'),
          headerTitleStyle: {
            fontFamily:'Heebo Black',
            fontWeight:'900',
          },
          tabBarIcon: ({ color }) => <TabBarIcon name="calendar" color={color} />,
          headerRight: () => (<UserComponent navigate={navigation.navigate} />
          ),
        })}
      />
      <BottomTab.Screen
        name="Post"
        component={PostScreen}
        options={({ navigation }: RootTabScreenProps<'Post'>) => ({
          title: 'Share',
          headerTintColor: useThemeColor({}, 'expoicon'),
          headerTitleStyle: {
            fontFamily:'Heebo Black',
            fontWeight:'900',
          },
          tabBarIcon: ({ color }) => <TabBarIcon name="plus-circle" color={color} />,
          headerRight: () => (<UserComponent navigate={navigation.navigate} />
          ),
        })}
      />
      {/* <BottomTab.Screen
        name="Clubs"
        component={ClubsScreen}
        options={({ navigation }: RootTabScreenProps<'Clubs'>) => ({
          title: 'Clubs',
          headerTintColor: useThemeColor({}, 'expoicon'),
          headerTitleStyle: {
            fontFamily:'Heebo Black',
            fontWeight:'900',
          },
          tabBarIcon: ({ color }) => <TabBarIcon name="id-card-o" color={color} />,
          headerRight: () => (<UserComponent navigate={navigation.navigate} />
          ),
        })}
      /> */}
      <BottomTab.Screen
        name="Experts"
        component={ExpertsScreen}
        options={({ navigation }: RootTabScreenProps<'Experts'>) => ({
          title: 'Experts',
          headerTintColor: useThemeColor({}, 'expoicon'),
          headerTitleStyle: {
            fontFamily:'Heebo Black',
            fontWeight:'900',
          },
          tabBarIcon: ({ color }) => <TabBarIcon name="wrench" color={color} />,
          headerRight: () => (<UserComponent navigate={navigation.navigate} />
          ),
        })}
      />
    </BottomTab.Navigator>
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome>['name'];
  color: string;
}) {
  return <FontAwesome size={25} style={{ marginBottom: -3 }} {...props} />; // used to be font-size 30
}
