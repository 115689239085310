import { StyleSheet, Text, Animated, Image, View } from 'react-native';
import React, { useEffect, useImperativeHandle } from 'react';
import { useState } from 'react';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';

import { PostStateType } from "../contexts/reducers/postListReducer";
import usePlatform, { maxWidth } from '../hooks/usePlatform';
import { useThemeColor } from './Themed';

const PostDetailCardFloatingDownload = React.forwardRef((props:{
  ref: React.RefObject<unknown>,
  item: PostStateType | null,
}, ref) => {

  const platform = usePlatform();

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      left: 16,
      right: 16,
      bottom: platform.isOveriPhoneX ? 30 : 12,
      backgroundColor: useThemeColor({}, 'alt1lightest'),
      borderRadius: 8,
      padding: 8,
      flexDirection: 'row',
      alignItems: 'center',
      // Shadow generator: https://ethercreative.github.io/react-native-shadow-generator/
      shadowColor: useThemeColor({}, 'shadow'),
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowOpacity: 0.36,
      shadowRadius: 6.68,
      elevation: 11,
    },
    content: {
      flex: 1,
      justifyContent: 'center',
      paddingLeft: 12,
    },
    categoryText: {
      fontSize: platform.normalize(13),
      color: useThemeColor({}, 'alt1lightest'),
    },
    appNameText: {
      fontSize: platform.normalize(18),
      color: useThemeColor({}, 'alt1lightest'),
    },
    subText: {
      fontSize: platform.normalize(14),
      color: useThemeColor({}, 'alt1lightest'),
    },
    appImage: {
      width: 40,
      height: 40,
      borderRadius: 6,
    },
    downloadButton: {
      borderRadius: 20,
      paddingVertical: 4,
      paddingHorizontal: 8,
      backgroundColor: useThemeColor({}, 'alt1lightest'),
    },
    downloadText: {
      fontSize: platform.normalize(12),
      fontWeight: 'bold',
      color: useThemeColor({}, 'textinverse'),
    },
  });

  const [ isVisible, setIsVisible ] = useState(false);
  const [ showAnimation, setShowAnimation ] = useState(new Animated.Value(0));
  const [containerStyle, setContainerStyle] = useState([
    styles.container,
    {
      transform: [
        {
          translateY: showAnimation.interpolate({
            inputRange: [0, 1],
            outputRange: [80,  - 15 -useBottomTabBarHeight() -64], // header height instead of 64?
          }),
        },
      ],
    },
  ]);

  useImperativeHandle(ref, () => ({
     hide (animated: boolean = true) {
      if (!isVisible) {
        return;
      }
  
      setIsVisible(false);
  
      if (!animated) {
        showAnimation.setValue(0);
        return;
      }
  
      Animated.spring(showAnimation, {
        toValue: 0,
        useNativeDriver: true,
      }).start();
    },
    show () {
      if (isVisible) {
        return;
      }
      setIsVisible(true);
  
      Animated.spring(showAnimation, {
        toValue: 1,
        useNativeDriver: true,
      }).start();
    },
  }));

  if (!props.item) return null;

  const { itemPayloadCardContentDownload } = props.item.payload;
  if (!itemPayloadCardContentDownload) return null;

  const {
    appName,
    category,
    appImage,
  } = itemPayloadCardContentDownload;

  return (
    <Animated.View ref={ref} style={containerStyle}>
      <Image source={appImage} style={styles.appImage} />
      <View style={styles.content}>
        <Text style={styles.categoryText}>{category}</Text>
        <Text style={styles.appNameText}>{appName}</Text>
      </View>
      <View>
        <View style={styles.downloadButton}>
          <Text style={styles.downloadText}>Download</Text>
        </View>
      </View>
    </Animated.View>
  );
});

export default PostDetailCardFloatingDownload;
