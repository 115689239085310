
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { TextInput } from 'react-native';
import { StyleSheet } from 'react-native';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import usePlatform, { imagePadding, minimumTextInputHeightLarge, minimumTextInputHeightRegular } from '../hooks/usePlatform';

interface FunctionalProps {
  required: boolean;
  large?: boolean;
  limited?: boolean;
  savePath: string;
  saveAttribute: string;
  singleLine?: boolean;
  savedCallback: Function;
  text?: string;
  setText: Function;
}

export function useThemeColor(
  props: { light?: string; dark?: string },
  colorName: keyof typeof Colors.light & keyof typeof Colors.dark
) {
  const theme = useColorScheme();
  const colorFromProps = props[theme];

  if (colorFromProps) {
    return colorFromProps;
  } else {
    return Colors[theme][colorName];
  }
}

type ThemeProps = {
  lightColor?: string;
  darkColor?: string;
};

export type TextInputProps = ThemeProps & TextInput['props'] & FunctionalProps;

// to support multiline
// multiline = {true}
// numberOfLines = {4}
export function SmartTextInput(props: TextInputProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const color = useThemeColor({ light: lightColor, dark: darkColor }, 'text');
  const placeholderTextColor=useThemeColor({}, 'textmuted');
  const borderColor = useThemeColor({ light: lightColor, dark: darkColor }, 'text');
  const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, 'bluelight');
  const [timer, setTimer] = useState<NodeJS.Timeout|null>(null);

  const platform = usePlatform();
  const textinputStyle = StyleSheet.create({
    textInput: {
      fontSize: 16,
      fontFamily: 'Heebo Regular',
      textAlign: 'center',
      paddingVertical: 5,
      // borderWidth: 1, // causes scrollbars to appear in multi-line
      borderRadius: 10,
      marginVertical: 10,
      width: platform.windowWidth*0.9-2*imagePadding,
      maxWidth: 600,
    },
  });

  const minimumTextInputHeight = props.large ? minimumTextInputHeightLarge : minimumTextInputHeightRegular;
  const [heigth, setHeigth] = useState(minimumTextInputHeight);
  const maximumLimitedTextInputHeight = props.large ? 
    (props.singleLine ? 45 : 80)
    :
    (props.singleLine ? 37 : 63);

  const dynamicTextStyle = StyleSheet.create(
    {
      textHeight: {
        height: heigth,
      },
      large: {
        fontFamily: 'Heebo Regular',
        fontSize: minimumTextInputHeight,
      },
    }
  );

  // Save draft post each time editing mainText has ended
  const onEndEditingText = (updatedText: string) => {
    axios({
      method: 'PUT',
      url: props.savePath,
      data: {[props.saveAttribute]: updatedText}, // With ES6, you can now directly use a variable as your property key in your object literal using brackets
    })
    .then(function (response) {
      if (!props.required || updatedText!='') {
        props.savedCallback(true);
      } else {
        props.savedCallback(false);
      }
    })
    .catch(function (error) {
      console.log(error.response.data.message);
    })
    .then(function () {
      // always executed
    });
  };

  function inputChanged (updatedText:string) {
    props.setText(updatedText);
    props.savedCallback(false);

    if (timer) clearTimeout(timer);

    const newTimer = setTimeout(() => {
      onEndEditingText(updatedText);
    }, 500)

    setTimer(newTimer);
  };

  return <TextInput
    style={[textinputStyle.textInput, dynamicTextStyle.textHeight, dynamicTextStyle.large, {color}, {borderColor}, {backgroundColor}, style]}
    placeholderTextColor={placeholderTextColor}
    onContentSizeChange={(event) => {
      // only fires when enlarging, not when becoming smaller
      let newHeight = event.nativeEvent.contentSize.height>0 ? event.nativeEvent.contentSize.height : maximumLimitedTextInputHeight;
      newHeight = props.limited && newHeight>maximumLimitedTextInputHeight ? maximumLimitedTextInputHeight : newHeight;
      setHeigth(newHeight);
    }}
    editable={true}
    multiline={props.multiline ? props.multiline : true}
    value={props.text}
    onChangeText={(updatedText: string) => inputChanged(updatedText)}
    // onFocus={() => props.savedCallback(false)} replaced with inputChanged with timer
    // onBlur={onEndEditingText} replaced with inputChanged with timer
    {...otherProps}
  />;
};
