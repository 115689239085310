import React, { useContext } from "react";
import AwesomeAlert from "react-native-awesome-alerts";
import { AppContext } from "../contexts";
import { AlertActionType, initialAlertState } from "../contexts/reducers/alertReducer";

export function AlertWeb() {
  const { state, dispatch } = useContext(AppContext);
  
  return (
    <AwesomeAlert
      show={state.alert.show}
      showProgress={false}
      title={state.alert.title}
      message={state.alert.message}
      closeOnTouchOutside={true}
      closeOnHardwareBackPress={false}
      showCancelButton={true}
      showConfirmButton={true}
      cancelText='No'
      confirmText='Yes'
      confirmButtonColor='darkred'
      onCancelPressed={() => {
        dispatch({type: AlertActionType.ALERTHIDDEN, payload: initialAlertState});
      }}
      onConfirmPressed={() => {
        dispatch({type: AlertActionType.ALERTHIDDEN, payload: initialAlertState});
        if (state.alert.action) state.alert.action();
      }}
    />
  );
};
