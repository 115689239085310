import { ActivityIndicator, ScrollView, StyleSheet } from 'react-native';
import React, { useContext, useEffect, useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js';
import usePlatform, { maxWidth, imagePadding } from '../hooks/usePlatform';
import { shadowStyle, useThemeColor, View } from '../components/Themed';
import { PostActionType, PostAs, PostImage, PostPayload, PostStateType } from '../contexts/reducers/postListReducer';
import { AppContext } from '../contexts';
import PostImagePicker from '../components/PostImagePicker';
import { ColoredButton } from '../components/ColoredButton';
import { WhiteButton } from '../components/WhiteButton';
import {RootTabScreenProps} from '../types';
import { HeeboBoldText, HeeboBoldTextLarge, HeeboRegularText, HeeboRegularTextMuted } from '../components/StyledText';
import { getPath, grillImageServerUrl, Method, path } from '../env/env';
import { SmartTextInput } from '../components/SmartTextInput';
import { SmartTagInput } from '../components/SmartTagInput';
import { PostType, Service } from '../types/types';
import { AlertModal } from "../components/AlertModal";
import { BusinessActionType, BusinessPayload, BusinessStateType } from '../contexts/reducers/businessListReducer';
import SmartServiceIcon from '../components/SmartServiceIcon';
import SmartAddressInput from '../components/SmartAddressInput';
import Spinner from '../components/Spinner';
import { EventActionType, EventPayload, EventStateType } from '../contexts/reducers/eventListReducer';
import SmartDateInput from '../components/SmartDateInput';
import { ClubActionType, ClubPayload, ClubStateType } from '../contexts/reducers/clubListReducer';

export default function PostScreen({ navigation, route }: RootTabScreenProps<'Post'>) {
  const { state, dispatch } = useContext(AppContext);
  const [ fetchingServices, setFetchingServices ] = useState(false);
  const [ services, setServices ] = useState<Service[]|null>(null);
  const [ businessesChecked, setBusinessesChecked ] = useState(false);
  const [ businessesOwned, setBusinessesOwned ] = useState<BusinessStateType[]>([]);
  const [ clubsChecked, setClubsChecked ] = useState(false);
  const [ clubsOwned, setClubsOwned ] = useState<ClubStateType[]>([]);
  const emptyPostAs: PostAs = {
    postType: PostType.UNDEFINED,
    uuid: '',
    name: '',
  };
  const [ postAs, setPostAs ] = useState<PostAs>(emptyPostAs);

  const platform = usePlatform();
  const styles = StyleSheet.create({
    simpleContainer: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      padding: imagePadding,
      textAlign: 'center',
    },
    container: {
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 40,
      borderRadius: 16,
      backgroundColor: useThemeColor({}, 'subtlebackground'),
      width: platform.windowWidth > maxWidth ? maxWidth-imagePadding : platform.windowWidth-imagePadding, // this is the other way round for PostList / PostListCard
      margin: imagePadding,
      padding: imagePadding,
    },
    scrollView: {
      flex: 1,
    },
    contentContainer: {
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
    },
    separator: {
      marginVertical: 30,
      height: 1,
      width: '80%',
    },
    otherImages: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    link: {
      color: useThemeColor({}, 'link'),
      textDecorationLine: 'underline',
    },
  });

  // custom typeguards
  function isPostStateType(item: PostStateType | BusinessStateType | EventStateType | ClubStateType): item is PostStateType {
    return (item as PostStateType).payload.type == PostType.POST;
  }
  function isBusinessStateType(item: PostStateType | BusinessStateType | EventStateType | ClubStateType): item is BusinessStateType {
    return (item as BusinessStateType).payload.type == PostType.BUSINESS;
  }
  function isEventStateType(item: PostStateType | BusinessStateType | EventStateType | ClubStateType): item is EventStateType {
    return (item as EventStateType).payload.type == PostType.EVENT;
  }
  function isClubStateType(item: PostStateType | BusinessStateType | EventStateType | ClubStateType): item is ClubStateType {
    return (item as ClubStateType).payload.type == PostType.CLUB;
  }
  function isPostPayloadType(item: PostPayload | BusinessPayload | EventPayload | ClubPayload): item is PostPayload {
    return (item as PostPayload).type == PostType.POST;
  }
  function isBusinessPayloadType(item: PostPayload | BusinessPayload | EventPayload | ClubPayload): item is BusinessPayload {
    return (item as BusinessPayload).type == PostType.BUSINESS;
  }
  function isEventPayloadType(item: PostPayload | BusinessPayload | EventPayload): item is EventPayload {
    return (item as EventPayload).type == PostType.EVENT;
  }
  function isClubPayloadType(item: PostPayload | BusinessPayload | EventPayload | ClubPayload): item is ClubPayload {
    return (item as ClubPayload).type == PostType.CLUB;
  }

  type formItem = {
    name: string,
    type: string,
    placeholder: string,
    required: boolean,
    large?: boolean,
    limited?: boolean,
    saved: boolean,
    pathAttribute: string,
    attributeName: string,
  };
  type formPage = formItem[];
  type formState = formPage[];

  const emptyPostFormState:formState = [
    [
      {name: 'mainText', type: 'text', placeholder: 'whattoshare', required: true, large: true, limited: true, saved: true, pathAttribute: 'maintext', attributeName: 'mainText'},
      {name: 'externalLink', type: 'text', placeholder: 'optionallink', required: false, large: false, limited: true, saved: true, pathAttribute: 'externallink', attributeName: 'externalLink'},
      {name: 'tags', type: 'tags', placeholder: '', required: false, saved: true, pathAttribute: '', attributeName: ''},
    ],
    [
      {name: 'mainImage', type: 'mainImage', placeholder: 'mainImageTitle', required: true, saved: true, pathAttribute: '', attributeName: ''},
      {name: 'otherImages', type: 'otherImages', placeholder: '', required: false, saved: true, pathAttribute: '', attributeName: ''},
    ],
    [
      {name: 'detailText', type: 'text', required: false, placeholder: 'writeanarticle', large: false, limited: false, saved: true, pathAttribute: 'detailtext', attributeName: 'detailText'},
    ],
  ];

  const emptyBusinessFormState:formState = [
    [
      {name: 'mainText', type: 'text', placeholder: 'businessName', required: true, large: true, limited: true, saved: true, pathAttribute: 'maintext', attributeName: 'mainText'},
      {name: 'externalLink', type: 'text', placeholder: 'businessSite', required: false, large: false, limited: true, saved: true, pathAttribute: 'externallink', attributeName: 'externalLink'},
      {name: 'tagline', type: 'text', placeholder: 'tagline', required: false, large: false, limited: true, saved: true, pathAttribute: 'tagline', attributeName: 'tagline'},
    ],
    [
      {name: 'mainImage', type: 'mainImage', placeholder: 'chooseLogo', required: true, saved: true, pathAttribute: '', attributeName: ''},
      {name: 'otherImages', type: 'otherImages', placeholder: 'organizationPictures', required: false, saved: true, pathAttribute: '', attributeName: ''},
    ],
    [
      {name: 'services', type: 'services', placeholder: 'chooseservices', required: true, saved: true, pathAttribute: 'services', attributeName: 'services'},
    ],
    [
      {name: 'detailText', type: 'text', required: false, placeholder: 'orgDescription', large: false, limited: false, saved: true, pathAttribute: 'detailtext', attributeName: 'detailText'},
    ],
    [
      {name: 'address', type: 'address', placeholder: 'manageaddresses', required: true, saved: true, pathAttribute: 'address', attributeName: ''},
    ],
  ];

  const emptyEventFormState:formState = [
    [
      {name: 'mainText', type: 'text', placeholder: 'eventName', required: true, large: true, limited: true, saved: true, pathAttribute: 'maintext', attributeName: 'mainText'},
      {name: 'externalLink', type: 'text', placeholder: 'businessSite', required: false, large: false, limited: true, saved: true, pathAttribute: 'externallink', attributeName: 'externalLink'},
    ],
    [
      {name: 'date', type: 'date', placeholder: 'eventDate', required: true, large: false, limited: true, saved: true, pathAttribute: 'date', attributeName: 'date'},
    ],
    [
      {name: 'mainImage', type: 'mainImage', placeholder: 'eventPoster', required: true, saved: true, pathAttribute: '', attributeName: ''},
      {name: 'otherImages', type: 'otherImages', placeholder: 'eventPictures', required: false, saved: true, pathAttribute: '', attributeName: ''},
    ],
    [
      {name: 'detailText', type: 'text', required: false, placeholder: 'eventDescription', large: false, limited: false, saved: true, pathAttribute: 'detailtext', attributeName: 'detailText'},
    ],
    [
      {name: 'address', type: 'address', placeholder: 'eventLocation', required: true, saved: true, pathAttribute: 'address', attributeName: ''},
    ],
  ];

  const emptyClubFormState:formState = [
    [
      {name: 'mainText', type: 'text', placeholder: 'clubName', required: true, large: true, limited: true, saved: true, pathAttribute: 'maintext', attributeName: 'mainText'},
      {name: 'externalLink', type: 'text', placeholder: 'clubSite', required: false, large: false, limited: true, saved: true, pathAttribute: 'externallink', attributeName: 'externalLink'},
      {name: 'tagline', type: 'text', placeholder: 'tagline', required: false, large: false, limited: true, saved: true, pathAttribute: 'tagline', attributeName: 'tagline'},
    ],
    [
      {name: 'mainImage', type: 'mainImage', placeholder: 'chooseLogo', required: true, saved: true, pathAttribute: '', attributeName: ''},
      {name: 'otherImages', type: 'otherImages', placeholder: 'clubPictures', required: false, saved: true, pathAttribute: '', attributeName: ''},
    ],
    [
      {name: 'detailText', type: 'text', required: false, placeholder: 'clubDescription', large: false, limited: false, saved: true, pathAttribute: 'detailtext', attributeName: 'detailText'},
    ],
    [
      {name: 'address', type: 'address', placeholder: 'manageaddresses', required: true, saved: true, pathAttribute: 'address', attributeName: ''},
    ],
  ];

  const [type, setType] = useState<PostType>(route.params? route.params.type : PostType.UNDEFINED);
  const [draft, setDraft] = useState<PostStateType|BusinessStateType|EventStateType|ClubStateType|null>(null); // todo
  const [formState, setFormState] = useState<formState|null>(null);
  const [page, setPage] = useState<number|null>(null);
  const [pageDone, setPageDone] = useState(false);
  const [pageLast, setPageLast] = useState(false);
  const [pageFirst, setPageFirst] = useState(true);
  const [imageReactKey, setImageReactKey] = useState('newImageReactKey-'+new Date().getTime());

  function updateImageReactKey() {
    setImageReactKey('newImageReactKey-'+new Date().getTime());
  };

  function savedCallback (page: number, name: string, saved: boolean) {
    if (!formState) return () => {};
    
    const tempFormState = [...formState];
    const index = tempFormState[page].findIndex(input => input.name==name)
    if (index>=0) {
      tempFormState[page][index].saved = saved;
      if (!saved) setPageDone(false);
      setFormState(tempFormState);
    }
  };
  
  /* ALL STATE EFFECT FUNCTIONS HERE */

  // Check if logged in
  useEffect(() => {
    if (!state.auth.uuid) {
      setType(PostType.UNDEFINED);
      setPostAs(emptyPostAs);
      setDraft(null);
      setPage(null);
      setFormState(null);
    } else {

      // Get owned businesses
      axios({
        method: 'GET',
        url: getPath(PostType.BUSINESS)+'/owned',
      })
      .then(function (response) {
        if (response.data.items) {
          setBusinessesOwned(response.data.items);
        }
        setBusinessesChecked(true);
      })
      .catch(function (error) {
        setDraft(null);
      })
      .then(function () {
        // always executed
      });
      
      // Get owned clubs
      axios({
        method: 'GET',
        url: getPath(PostType.CLUB)+'/owned',
      })
      .then(function (response) {
        if (response.data.items) {
          setClubsOwned(response.data.items);
        }
        setClubsChecked(true);
      })
      .catch(function (error) {
        setDraft(null);
      })
      .then(function () {
        // always executed
      });
    }
  },[state.auth.uuid]);
  
  // Check type and fetch / create draft
  useEffect(() => {
    if (type==PostType.POST && !draft) {
      fetchOrCreateDraft();
      setPage(null);
      setFormState(emptyPostFormState);
    } else if (type==PostType.EVENT && !draft) {
      fetchOrCreateDraft();
      setPage(null);
      setFormState(emptyEventFormState);
    } else if (type==PostType.CLUB && !draft) {
      fetchOrCreateDraft();
      setPage(null);
      setFormState(emptyClubFormState);
    } else if (type==PostType.BUSINESS && !draft) {
      fetchOrCreateDraft();
      setPage(null);
      setFormState(emptyBusinessFormState);
    }
  },[type]);
  
  // Check which page and if next page is allowed
  useEffect(() => {
    if (!formState) return;
    
    // set first page if no is found with formState
    if (!page) {
      setPage(0);
      setPageDone(false);
    }

    // set page done if all saved
    const i = page ? page : 0;
    if (formState[i].filter(input => input.saved==false).length == 0) {
      setPageDone(true);
    }
  },[formState]);
  
  // Manage the last page flag
  useEffect(() => {
    if (!formState) return;
    if (page == formState.length-1) setPageLast(true);
    else if (pageLast) setPageLast(false);
    if (page == 0) setPageFirst(true);
    else setPageFirst(false);
  },[page]);

/* ALL RENDER FUNCTIONS HERE */

  // Show not logged in screen
  if (!state.auth.uuid) {
    return (
      <View style={styles.simpleContainer}>
        <HeeboRegularText onPress={() => navigation.navigate('Profile')}>{i18n.t('loginorregister1')}<HeeboRegularText style={styles.link}>{i18n.t('loginorregister2')}</HeeboRegularText>{i18n.t('loginorregister3')}</HeeboRegularText>
      </View>
    );
  }

  if (!businessesChecked) {
    return <View style={styles.simpleContainer}>
      <Spinner />
    </View>;
  }

  // Choose type screen
  // todo
  if (type==PostType.UNDEFINED) { // Already checked for state.auth.uuid above
    return (
      <ScrollView contentContainerStyle={styles.contentContainer}>
        <View style={[styles.container, shadowStyle.shadow]}>
          <ColoredButton onPress={() => setType(PostType.POST)} text={i18n.t('choosePost')} icon='pencil-square-o' />
          <ColoredButton onPress={() => setType(PostType.EVENT)} text={i18n.t('chooseEvent')} icon='flag-checkered' />
          <ColoredButton onPress={() => setType(PostType.CLUB)} text={i18n.t('chooseClub')} icon='users' />
          <ColoredButton onPress={() => setType(PostType.BUSINESS)} text={i18n.t('chooseBusiness')} icon='building' />
          {businessesOwned.map((businessOwned)=>{
            return <ColoredButton key={'postas-'+businessOwned.uuid} onPress={() => {
              setPostAs({
                postType: PostType.BUSINESS,
                uuid: businessOwned.uuid,
                name: businessOwned.payload.mainText,
              });
              setType(PostType.POST)
            }} text={i18n.t('postas')+' '+businessOwned.payload.mainText} icon='pencil-square-o' />
          })}
          {clubsOwned.map((clubOwned)=>{
            return <ColoredButton key={'postas-'+clubOwned.uuid} onPress={() => {
              setPostAs({
                postType: PostType.BUSINESS,
                uuid: clubOwned.uuid,
                name: clubOwned.payload.mainText,
              });
              setType(PostType.CLUB)
            }} text={i18n.t('postas')+' '+clubOwned.payload.mainText} icon='pencil-square-o' />
          })}
        </View>
      </ScrollView>
    );
  }

  // Input-screens
  const inputFields : JSX.Element[] = [];
  if (type==PostType.POST) inputFields.push(<HeeboBoldTextLarge key="toptitle">{i18n.t('hi')+' '+state.user.firstName}</HeeboBoldTextLarge>);
  if (type==PostType.BUSINESS) inputFields.push(<HeeboBoldTextLarge key="toptitle">{i18n.t('yourOrg')}</HeeboBoldTextLarge>);
  if (type==PostType.EVENT) inputFields.push(<HeeboBoldTextLarge key="toptitle">{i18n.t('yourEvent')}</HeeboBoldTextLarge>);
  if (type==PostType.CLUB) inputFields.push(<HeeboBoldTextLarge key="toptitle">{i18n.t('yourClub')}</HeeboBoldTextLarge>);
  if (!draft || !formState || page==null) {
    return <View style={[styles.container, shadowStyle.shadow]}>
      <ActivityIndicator />
    </View>;
  } else { // Already checked for state.auth.uuid above
    formState[page].map((input) => {
      if (input.type=='text') {
        if (!input.placeholder) return;
        const key = input.name as string;
        let text = 'empty';
        if (isPostPayloadType(draft.payload)) text = String(draft.payload[key as keyof PostPayload]);
        if (isBusinessPayloadType(draft.payload)) text = String(draft.payload[key as keyof BusinessPayload]);
        if (isEventPayloadType(draft.payload)) text = String(draft.payload[key as keyof EventPayload]);
        if (isClubPayloadType(draft.payload)) text = String(draft.payload[key as keyof ClubPayload]);
        // todo
        inputFields.push(
          <SmartTextInput 
            key={input.name}
            placeholder={i18n.t(input.placeholder)}
            text={text}
            setText={(text:string) => setDraft({...draft,  payload: {...draft.payload, [input.name]: text}})}
            required={input.required}
            large={input.large}
            limited={input.limited}
            savePath={getPath(type)+'/'+draft.uuid+'/'+input.pathAttribute}
            saveAttribute={input.attributeName}
            savedCallback={(saved: boolean) => savedCallback(page, input.name, saved)}
          />
        );
      } else if (input.type=='tags') {
        inputFields.push(
          <View key={input.name}>
            <SmartTagInput 
              savedCallback={(saved: boolean) => savedCallback(page, input.name, saved)}
            />
          </View>
        );
      } else if (input.type=='mainImage') {
        inputFields.push(<HeeboRegularTextMuted key={input.placeholder}>{i18n.t(input.placeholder)}</HeeboRegularTextMuted>);
        inputFields.push(<PostImagePicker key={getMainImageUri()} imageUri={getMainImageUri()} main={true} imagePicked={imagePicked} imageRemoved={imageRemoved} imagePage={page} inputName={input.name} savedCallback={(saved: boolean) => savedCallback(page, input.name, saved)} />);
      } else if (input.type=='otherImages') {
        if (getMainImageUri()) {
          if (input.placeholder != '') inputFields.push(<HeeboRegularTextMuted key={input.placeholder}>{i18n.t(input.placeholder)}</HeeboRegularTextMuted>);
          inputFields.push(<View key={'otherImagesRow'} style={styles.otherImages}>
            {getOtherImageUris().map(otherImageUri => {
              return <PostImagePicker key={otherImageUri} imageUri={otherImageUri} main={false} imagePicked={imagePicked} imageRemoved={imageRemoved} imagePage={page} inputName={input.name} savedCallback={(saved: boolean) => savedCallback(page, input.name, saved)} />
            })}
            <PostImagePicker key={imageReactKey} imageUri={''} main={false} imagePicked={imagePicked} imageRemoved={imageRemoved} imagePage={page} inputName={input.name} savedCallback={(saved: boolean) => savedCallback(page, input.name, saved)} />
          </View>);
        }
      } else if (input.type=='services') {
        if (!services) fetchServices();
        else if (isBusinessStateType(draft)) {
          inputFields.push(<HeeboBoldText key={input.placeholder}>{i18n.t(input.placeholder)}</HeeboBoldText>);
          inputFields.push(
            <SmartServiceIcon
              key={input.name}
              pathAttribute={input.pathAttribute}
              services={services}
              activeServices={draft.payload.serviceUuids}
              setService={setService}
            />
          );
        }
      } else if (input.type=='address') {
        if (isBusinessStateType(draft)||isEventStateType(draft)||isClubStateType(draft)) {
          inputFields.push(<HeeboBoldText key={input.placeholder}>{i18n.t(input.placeholder)}</HeeboBoldText>);
          inputFields.push(
            <SmartAddressInput
              key={input.name}
              pathAttribute={input.pathAttribute}
              locationUuids={draft.payload.locationUuids}
              addressAddedRemoved={addressAddedRemoved}
              savedCallback={(saved: boolean) => savedCallback(page, input.name, saved)}
            />
          );
        }
      } else if (input.type=='date') {
        if (isEventStateType(draft)) {
          inputFields.push(<HeeboBoldText key={input.placeholder}>{i18n.t(input.placeholder)}</HeeboBoldText>);
          inputFields.push(
            <SmartDateInput
              key={input.name}
              startDate={draft.payload.startDate}
              endDate={draft.payload.endDate}
              dateUpdated={(startDate: Date, endDate: Date) => setDraft({...draft, payload: {...draft.payload, startDate: startDate, endDate: endDate}})}
              savePath={getPath(type)+'/'+draft.uuid+'/'+input.pathAttribute}
              savedCallback={(saved: boolean) => savedCallback(page, input.name, saved)}
            />
          );
        }
      }
    });

    return (
      <ScrollView  contentContainerStyle={styles.contentContainer}>
        <View>
          <View style={[styles.container, shadowStyle.shadow]}>
            {inputFields}
            <ColoredButton disabled={!pageDone} onPress={pageLast ? publish : nextPage} text={pageLast ? i18n.t('publish') : i18n.t('next')} icon={pageLast ? 'arrow-circle-up' : 'arrow-circle-right'} />
            {!pageFirst && <WhiteButton disabled={!pageDone} onPress={prevPage} text={i18n.t('prev')} icon={'arrow-circle-left'} />}
            <WhiteButton onPress={()=>AlertModal({
            title: i18n.t('areyousure'),
            message: i18n.t('willberemoved'),
            action: deleteDraft,
            dispatch: dispatch,
          })} text={i18n.t('cancel')} icon='trash-o' />
          </View>
        </View>
      </ScrollView>
    );
  }

  /* ALL SUPPORT FUNCTIONS HERE */

  function fetchOrCreateDraft() {
    // if there is a draftpost in state, stop
    if (draft) return;
    
    // if there is no draftpost in state, try to fetch one
    axios({
      method: 'GET',
      url: getPath(type),
      params: {
        draft: true,
      },
    })
    .then(function (response) {
      if (response.data.item) {
        if ( (!response.data.item.postAs && postAs.postType==PostType.UNDEFINED) || (response.data.item.postAs && response.data.item.postAs==postAs)) {
          setDraft(response.data.item);
          setType(response.data.item.payload.type);
        } else {
          createDraft();
        }
      } else {
        createDraft();
      }
    })
    .catch(function (error) {
      setDraft(null);
    })
    .then(function () {
      // always executed
    });
  };

  function createDraft() {
    axios({
      method: 'POST',
      url: getPath(type),
      data: {postAs: postAs},
    })
    .then(function (response) {
      if (response.data.item) {
        setType(response.data.item.payload.type);
        setDraft(response.data.item);
      } else {
        setType(PostType.UNDEFINED);
        setPostAs(emptyPostAs);
        setDraft(null);
      }
    })
    .catch(function (error) {
      console.log(error.response.data.message);
      setDraft(null);
    })
    .then(function () {
      // always executed
    });
  };

  function deleteDraft() {
    if (!draft) return;

    // get info to delete and delete locally
    const imageUuids = draft.imageUuids;
    const uuid = draft?.uuid;

    // Delete images on GrillImageServer
    imageUuids.map(imageUuid => {
      if (!imageUuid) return;
      axios({
        method: 'DELETE',
        url: grillImageServerUrl+'/'+imageUuid.uuid,
      })
      .catch(function (error) {
        console.log(error.response.data.message);
      })
    });

    // Delete post on GrillServer
    if (uuid) axios({
      method: 'DELETE',
      url: getPath(type)+'/'+uuid,
    })
    .then(function (response) {
      setType(PostType.UNDEFINED);
      setPostAs(emptyPostAs);
      setDraft(null);
      setPage(null);
      setFormState(null);
    })
    .catch(function (error) {
      console.log(error.response.data.message);
    })
    .then(function () {
      // always executed
    });
  };

  function getMainImageUri() {
    if (!draft || !draft.imageUuids) return '';
    const uuid = draft.imageUuids.find(imageUuid => {if (!imageUuid) {return null} else {return imageUuid.mainImage}})?.uuid;
    if (uuid) {
      return uuid;
    } else {
      return '';
    }
  };

  function getOtherImageUris() {
    if (!draft) return [''];

    const otherImageUris : string[] = [];
    draft.imageUuids.filter(imageUuid => !imageUuid.mainImage).map(image => {
      if (image.uuid) otherImageUris.push(image.uuid);
    });
    return otherImageUris;
  };

  // Function for other image to be added by image picker
  function imagePicked(image: PostImage, imagePage: number, inputName: string) {
    if (!draft || page==null) return;
    
    updateImageReactKey();
    axios({
      method: 'POST',
      url: getPath(type)+'/'+draft?.uuid+'/imageUuids',
      data: {image},
    })
    .then(function (response) {
      setDraft({...draft,  imageUuids: [...draft.imageUuids, image]});
      savedCallback(imagePage, inputName, true);
    })
    .catch(function (error) {
      console.log(error.response.data.message);
    })
    .then(function () {
      // always executed
    });
  };

  // Function for image to be added by image picker
  function imageRemoved(imageUuidRemoved: string, imagePage: number, inputName: string) {
    if (!draft || page==null) return;
    
    updateImageReactKey();
    axios({
      method: 'DELETE',
      url: getPath(type)+'/'+draft?.uuid+'/imageuuids/'+imageUuidRemoved,
    })
    .then(function (response) {
      setDraft({...draft,  imageUuids: draft.imageUuids.filter(image => image.uuid!=imageUuidRemoved)});
      savedCallback(imagePage, inputName, true);
    })
    .catch(function (error) {
      console.log(error.response.data.message);
    })
    .then(function () {
      // always executed
    });
  };

  function fetchServices() {
    if (fetchingServices) return;

    setFetchingServices(true);
    axios({
      method: 'GET',
      url: path.services,
    })
    .then(function (response) {
      setServices(response.data.items ? response.data.items : []);
      setFetchingServices(false);
    })
    .catch(function (error) {
      console.log(error.response.data.message);
      setFetchingServices(false);
    })
    .then(function () {
      // always executed
    });
  };

  function setService(serviceUuid: string, active: boolean) {
    if (!draft || !isBusinessStateType(draft)) return;
    if (active) setDraft({...draft, payload: {...draft.payload, serviceUuids: [...draft.payload.serviceUuids, serviceUuid]}});
    else setDraft({...draft, payload: {...draft.payload, serviceUuids: draft.payload.serviceUuids.filter(uuid => uuid!=serviceUuid)}});

    axios({
      method: active? 'POST' : 'DELETE',
      url: path.business+'/'+draft.uuid+'/services/'+serviceUuid
    })
    .then(function (response) {
    })
    .catch(function (error) {
      console.log(error.response.data.message);
    })
    .then(function () {
      // always executed
    });
  };

  function addressAddedRemoved(method: Method, locationUuid: string) {
    if (!draft) return;

    let collectionPath = '';
    if (isBusinessStateType(draft)) collectionPath = path.business;
    else if (isEventStateType(draft)) collectionPath = path.events;
    else if (isClubStateType(draft)) collectionPath = path.clubs;
    else return;
    
    let addressPath = collectionPath+'/'+draft.uuid+'/'+path.locationuuids;
    if (method===Method.DELETE) addressPath=addressPath+'/'+locationUuid;
    const config: AxiosRequestConfig = {
      method: method,
      url: addressPath,
    };
    if (method===Method.POST) config.data = {_locationUuid: locationUuid}

    axios(config)
    .then(function (response) {
      if (method===Method.POST) setDraft({...draft, payload: {...draft.payload, locationUuids: [...draft.payload.locationUuids, locationUuid]}});
      else if (method===Method.DELETE) setDraft({...draft, payload: {...draft.payload, locationUuids: draft.payload.locationUuids.filter(uuid => uuid!=locationUuid)}});
    })
    .catch(function (error) {
      console.log(error.response.data.message);
    })
    .then(function () {
      // always executed
    });
  };

  function nextPage() {
    if (page==null) return;
    setPage(page+1);
  };

  function prevPage() {
    if (page==null) return;
    setPage(page-1);
  };

  function publish() {
    if (!draft || !formState) return;
    
    // Check if everything is saved
    let everythingSaved=true;
    const tempFormState = [...formState];
    tempFormState.map(
      page => page.map(
        input => {
          if (!input.saved) everythingSaved=false;
        }
      )
    );
    if (!everythingSaved) return;

    axios({
      method: 'PUT',
      url: getPath(type)+'/'+draft.uuid+'/draft/true',
    })
    .then(function (response) {
      // do a temporary local update of the posts
      draft.draft=false;
      if (type==PostType.POST) dispatch({type: PostActionType.POST_PUBLISHED,payload: draft});
      else if (type==PostType.BUSINESS && isBusinessStateType(draft)) dispatch({type: BusinessActionType.BUSINESS_PUBLISHED, payload: draft});
      else if (type==PostType.EVENT && isEventStateType(draft)) dispatch({type: EventActionType.EVENT_PUBLISHED, payload: draft});
      else if (type==PostType.CLUB && isClubStateType(draft)) dispatch({type: ClubActionType.CLUB_PUBLISHED, payload: draft});
      // todo
      setType(PostType.UNDEFINED);
      setPostAs(emptyPostAs);
      setDraft(null);
      setPage(null);
      setFormState(null);
      if (type==PostType.POST) navigation.navigate('Feed');
      else if (type==PostType.BUSINESS) navigation.navigate('Experts');
      else if (type==PostType.EVENT) navigation.navigate('Events');
      else if (type==PostType.CLUB) navigation.navigate('Clubs');
      // todo
    })
    .catch(function (error) {
      console.log(error.response.data.message);
    })
    .then(function () {
    });
  };

};

// usage: {i18n.t('loginorregister1')}
const i18n = new I18n({
  nl: {
    loginorregister1: '',
    loginorregister2: 'Log in of registreer',
    loginorregister3: ' een GRILL account om je post te delen!',
    choosePost: 'Deel een post',
    postas: 'Post als',
    chooseEvent: 'Plan evenement',
    chooseClub: 'Uw club',
    chooseBusiness: 'Organisatie',
    hi: 'Dag',
    yourOrg: 'Uw organisatie',
    yourEvent: 'Uw evenement',
    yourClub: 'Uw club',
    whattoshare: 'Wat wil je delen?',
    optionallink: 'Link (optioneel)',
    businessName: 'Naam organisatie',
    businessSite: 'Website',
    tagline: 'Uw slogan',
    chooseservices: 'Wat doet uw organisatie?',
    manageaddresses: 'Contactgegevens',
    next: 'Volgende',
    prev: 'Terug',
    publish: 'Publiceren',
    cancel: 'Verwijderen',
    mainImageTitle: 'Kies een foto voor jouw post',
    writeanarticle: 'Wil je een artikel schrijven?',
    chooseLogo: 'Upload uw logo',
    organizationPictures: 'Deel foto\'s van uw organisatie',
    orgDescription: 'Vertel over uw organisatie',
    areyousure: 'Ben je zeker?',
    willberemoved: 'Jouw post wordt permanent verwijderd',
    eventName: 'Jouw event',
    eventDate: 'Event datum',
    eventPoster: 'Event poster',
    eventPictures: 'Event foto\'s',
    eventDescription: 'Beschrijving event',
    eventLocation: 'Event locatie',
    clubName: 'Naam club',
    clubSite: 'Website',
    clubPictures: 'Deel foto\'s van uw club',
    clubDescription: 'Vertel over uw club',
  },
  fr: {
  },
  en: {
    loginorregister1: 'Please, ',
    loginorregister2: 'log in or register',
    loginorregister3: ' a GRILL account to share your post!',
    choosePost: 'Share a post',
    postas: 'Post as',
    chooseEvent: 'Organize event',
    chooseClub: 'Add your club',
    chooseBusiness: 'Organization',
    hi: 'Hi',
    yourOrg: 'Your organization',
    yourEvent: 'Your event',
    yourClub: 'Your club',
    whattoshare: 'What do you want to share?',
    optionallink: 'Link (optional)',
    businessName: 'Organization name',
    businessSite: 'Website',
    tagline: 'Slogan or tagline',
    chooseservices: 'What does your organization do?',
    manageaddresses: 'Contact details',
    next: 'Next',
    prev: 'Back',
    publish: 'Publish',
    cancel: 'Remove',
    mainImageTitle: 'Pick an image for your post',
    writeanarticle: 'Feel free to write an article',
    chooseLogo: 'Upload your logo',
    organizationPictures: 'Add pictures of your organization',
    orgDescription: 'Describe your organization',
    areyousure: 'Are you sure?',
    willberemoved: 'Your post will be removed permanently',
    eventName: 'Your event',
    eventDate: 'Event date',
    eventPoster: 'Event poster',
    eventPictures: 'Event photos',
    eventDescription: 'Event description',
    eventLocation: 'Event location',
    clubName: 'Club name',
    clubSite: 'Website',
    clubPictures: 'Add pictures of your club',
    clubDescription: 'Describe your club',
  },
});
i18n.locale = Localization.locale; // this should happen only once
i18n.enableFallback = true;
// => causes fallback to default below
i18n.locale = 'nl';
