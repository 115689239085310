import React, { useRef } from 'react';
import { useEffect, useState,useContext } from 'react';
import { View, TouchableWithoutFeedback, Animated, PixelRatio } from 'react-native';
import {StyleSheet} from 'react-native';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import axios from 'axios';
import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js';

import { AppContext } from "../contexts";
import { AlertModal } from "../components/AlertModal";
import { PostStateType, PostDetailCardInfoType, PostActionType, POST_CARD_CONTENT_TYPE } from '../contexts/reducers/postListReducer';
import PostCardImage from './PostCardImage';
import PostCardContent from './PostCardContent';
import usePlatform, { maxWidth } from '../hooks/usePlatform';
import useCardSize, { CardSize, emptyCardSize } from '../hooks/useCardSize';
import { imagePadding } from '../hooks/usePlatform';
import { PostDetailActionType } from '../contexts/reducers/postDetailReducer';
import { emptyImageInstance, fetchImageInstance } from '../util/imageHelper';
import { ImageMetadata, PostType } from '../types/types';
import { useNavigation } from '@react-navigation/native';
import { RootTabScreenProps } from '../types';
import { shadowStyle, useThemeColor } from './Themed';
import PostListCardMenuBullets from './PostListCardMenuBullets';
import { getPath, path } from '../env/env';
import { setStatusBarHidden } from 'expo-status-bar';

export function PostListCard (props: {
  post: PostStateType,
  focus: boolean,
}) {
  const platform = usePlatform();
  const containerViewRef = useRef<View>(null);

  const navigation = useNavigation<RootTabScreenProps<'Feed'>['navigation']>();

  const styles = StyleSheet.create({
    container: {
      marginVertical: 10,
      backgroundColor: useThemeColor({}, 'textinverse'),
      borderRadius: 16,
      maxWidth: maxWidth,
    },
    containerIsHidden: {
      opacity: 0,
    },
    shadow: {
      overflow: 'hidden',
      borderRadius: 16,
    },
  });
  
  const { state, dispatch } = useContext(AppContext);
  const [ isHidden, setIsHidden ] = useState(false);
  const [ imageInstance, setImageInstance] = useState<ImageMetadata>(emptyImageInstance);

  useEffect(() => {
    fetchImageInstance(setImageInstance, props.post.imageUuids, 'lg', true, '');
  }, []);

  const cardSize = useCardSize(imageInstance.width, imageInstance.height, true);

  const bottomTabBarHeight = useBottomTabBarHeight();

  const [ focussed, setFocussed ] = useState(false);

  useEffect(() => {
    if (!props.focus && focussed) {
      setFocussed(false);
    } else if (props.focus && !focussed) {
      setFocussed(true); // We do this because on iOS a double tap triggers this twice, ending up with an empty screen
      onPress();
    }
  }, [props.focus]);

  const onPress = () => {
    if (focussed) return;

    containerViewRef.current && containerViewRef.current.measure((
      x, y, width, height, pageX, pageY,
    ) => {
      const position = {
        pageX,
        pageY,
        width,
        height,
        scale: 1,
      };
      
      const {
        width: imageWidth,
      } = position;
      
      const duration = 250; // 180 + (position.pageY / platform.windowHeight - bottomTabBarHeight) * 140;
      const imageHeight = PixelRatio.roundToNearestPixel((cardSize.cardHeight * imageWidth) / cardSize.cardWidth);
      const imageMarginLeft = -1 * (imagePadding + ((cardSize.cardWidth - imageWidth) / 2));
      const imageMarginTop = -1 * (imagePadding + ((cardSize.cardHeight - imageHeight) / 2));
      
      const info: PostDetailCardInfoType = {
      ...position,
      imageWidth,
      imageHeight,
      imageMarginLeft,
      imageMarginTop,
      windowWidth: platform.windowWidth,
      windowHeight: platform.windowHeight - bottomTabBarHeight + (platform.isAndroid ? (platform.statusBarHeight? platform.statusBarHeight : 0) : 0),
      duration,
      };
      
      navigation.navigate('Feed', {
        uuid: props.post.uuid,
      });
      dispatch({
        type: PostDetailActionType.POST_SHOWED,
        payload: {
          isImageLoaded: false,
          item: props.post,
          info: info,
        },
      });

      // Ok, so the idea here is that these values (isImageLoaded & isVisible) are monitored
      // if the internal condition is met, the monitoring stops
      // Moved to the two useEffects below, not perfect, but only with the dispatch above these two are set
    });
  };
  useEffect(() => {
    if (state.postDetail.isImageLoaded) {
      setStatusBarHidden(true, true ? 'slide' : 'none'); // show
      setIsHidden(true);
    }
  }, [state.postDetail.isImageLoaded]);
  
  useEffect(() => {
    if (!state.postDetail.item) {
      setIsHidden(false);
    }
  }, [state.postDetail.item]);
  
  function hidePost () {
    if (!props.post) return;
    
    navigation.navigate('Feed');
    dispatch({
      type: PostDetailActionType.POST_HIDE,
      payload: null,
    });

    // Hide post
    axios({
      method: 'POST',
      url: getPath(PostType.POST)+'/'+props.post.uuid+'/hide',
    })
    .then(function (response) {
      dispatch({
        type: PostActionType.POST_REMOVED,
        payload: props.post.uuid,
      });
    })
    .catch(function (error) {
      console.log(error.response.data.message);
    })
    .then(function () {
      // always executed
    });
  };

  function muteOrBlockUser (block: boolean) {
    if (!props.post) return;
    
    navigation.navigate('Feed');
    dispatch({
      type: PostDetailActionType.POST_HIDE,
      payload: null,
    });

    // Must user
    const pathMuteOrBlock = !block ? 'mute' : 'block';
    axios({
      method: 'POST',
      url: path.users+'/'+props.post.author+'/'+pathMuteOrBlock,
    })
    .then(function (response) {
      console.log('I still need to hide this user.');
      // dispatch({
      //   type: PostActionType.POST_REMOVED,
      //   payload: props.post.uuid,
      // });
    })
    .catch(function (error) {
      console.log(error.response.data.message);
    })
    .then(function () {
      // always executed
    });
  };

  function reportPost () {
    if (!props.post) return;
    
    navigation.navigate('Feed');
    dispatch({
      type: PostDetailActionType.POST_HIDE,
      payload: null,
    });

    // Report post
    axios({
      method: 'POST',
      url: getPath(PostType.POST)+'/'+props.post.uuid+'/report',
    })
    .then(function (response) {
      dispatch({
        type: PostActionType.POST_REMOVED,
        payload: props.post.uuid,
      });
    })
    .catch(function (error) {
      console.log(error.response.data.message);
    })
    .then(function () {
      // always executed
    });
  };

  const getRenderVariables = () => {

    const containerStyle = [
      styles.container,
      isHidden && styles.containerIsHidden,
    ];

    const components = [];

    components.push(
      <PostCardImage
        key="image"
        imageInstance={imageInstance}
        uuid={props.post.uuid}
      />
    );
    
    // if (props.post.payload.itemPayloadCardContentText) {
    //   components.push(<PostCardContent uuid={props.post.uuid} key={props.post.uuid} cardTextContent={props.post.payload.itemPayloadCardContentText} social={props.post.social} />);
    // }
    
    // if (props.post.payload.itemPayloadCardContentDownload) {
    //   components.push(<PostCardContent uuid={props.post.uuid} key={props.post.uuid} cardDownloadContent={props.post.payload.itemPayloadCardContentDownload} social={props.post.social} />);
    // }

    if (props.post.payload.type==POST_CARD_CONTENT_TYPE.POST) {
      components.push(<PostCardContent uuid={props.post.uuid} key={props.post.uuid} social={props.post.social} />);
    }

    if (state.auth.uuid && state.auth.uuid!=props.post.author) {
      components.push(
        <PostListCardMenuBullets
          key={'bullets_'+props.post.uuid}
          hidePost={()=>AlertModal({
            title: i18n.t('areyousuretohide'),
            message: i18n.t('willbehidden'),
            action: hidePost,
            dispatch: dispatch,
          })}
          muteUser={()=>AlertModal({
            title: i18n.t('areyousuretomute'),
            message: i18n.t('willbemuted'),
            action: ()=>muteOrBlockUser(false),
            dispatch: dispatch,
          })}
          blockUser={()=>AlertModal({
            title: i18n.t('areyousuretoblock'),
            message: i18n.t('willbeblocked'),
            action: ()=>muteOrBlockUser(true),
            dispatch: dispatch,
          })}
          reportPost={()=>AlertModal({
            title: i18n.t('areyousuretoreport'),
            message: i18n.t('willbereported'),
            action: reportPost,
            dispatch: dispatch,
          })}
        />
      );
    }

    return {
      containerStyle,
      components,
    };
  };

  const {
    containerStyle,
    components,
  } = getRenderVariables();
  
  const reported = !props.post.social.reported || props.post.social.reported.find(reportingUser => state.auth.uuid == reportingUser);
  const hidden = !props.post.social.hidden || props.post.social.hidden.find(hidingUser => state.auth.uuid == hidingUser);
  const muted = state.user.mutedUsers ? state.user.mutedUsers.find(mutedUser => props.post.author == mutedUser) : false;
  const blocked = state.user.blockedUsers ? state.user.blockedUsers.find(blockedUser => props.post.author == blockedUser) : false;

  if (hidden || muted || blocked || reported) return null;

  const dominantColor = imageInstance.dominantColor ? 'rgb('+imageInstance.dominantColor.r+','+imageInstance.dominantColor.g+','+imageInstance.dominantColor.b+')' : null;
  const coloredContainerShadowStyle = dominantColor ? [containerStyle, {
    shadowColor: dominantColor,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.5,
    shadowRadius: 15,
    elevation: 10,
  }] : [containerStyle, shadowStyle.shadow];

  return (
    <TouchableWithoutFeedback
      onPress={onPress}
    >
      <Animated.View style={coloredContainerShadowStyle}>
        <View ref={containerViewRef} style={styles.shadow}>
          {components}
        </View>
      </Animated.View>
    </TouchableWithoutFeedback>
  );
};

// usage: {i18n.t('loginorregister1')}
const i18n = new I18n({
  nl: {
    areyousuretohide: 'Ben je zeker?',
    willbehidden: 'Je zal deze post niet meer zien',
    areyousuretomute: 'Ben je zeker?',
    willbemuted: 'Je zal geen posts meer zien van deze gebruiker',
    areyousuretoblock: 'Deze gebruiker blokkeren?',
    willbeblocked: 'Aanstootgevende berichten kunnen gerapporteerd worden',
    areyousuretoreport: 'Deze post rapporteren?',
    willbereported: 'The GRILL administrators zullen op aanstootgevende berichten handelen binnen 24 uur door de content te verwijderen en de gebruiker te verwijderen',
  },
  fr: {
  },
  en: {
    areyousuretohide: 'Are you sure?',
    willbehidden: 'You will no longer see this post',
    areyousuretomute: 'Are you sure?',
    willbemuted: 'You will no longer see any posts from this user',
    areyousuretoblock: 'Block this user?',
    willbeblocked: 'Objectionable content can be reported by selecting "Report post"',
    areyousuretoreport: 'Report this post?',
    willbereported: 'The GRILL administrators will act on objectionable content reports within 24 hours by removing the content and eject the user who provided the offending content.',
  },
});
i18n.locale = Localization.locale; // this should happen only once
i18n.enableFallback = true;
// => causes fallback to default below
i18n.locale = 'nl';
