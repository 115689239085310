import {Dimensions, StyleSheet, TouchableWithoutFeedback} from 'react-native';
import React, { useContext, useState } from 'react';
import { View, Image } from 'react-native';

import { AppContext } from '../contexts';
import CarouselClose from './CarouselClose';
import { CarouselActionType } from '../contexts/reducers/carouselReducer';
import CarouselArrow from './CarouselArrow';

export default function Carousel () {
  const [touch, setTouch] = useState(0);
  const { state, dispatch } = useContext(AppContext);
  const window = Dimensions.get('window');

  if (state.carousel.index==-1) return null;
  if (!state.carousel.imageMetadatas) return null;

  const imageMetadata = state.carousel.imageMetadatas[state.carousel.index];

  const scaleFactor = 0.97;
  const resizeVertical =  (window.width/window.height) > (imageMetadata.width/imageMetadata.height)? true : false;
  const resizeFactorWidth = resizeVertical ? imageMetadata.width/imageMetadata.height : 1;
  const resizeFactorHeight = !resizeVertical ? imageMetadata.height/imageMetadata.width : 1;
  const viewPortSize = resizeVertical ? window.height * scaleFactor : window.width * scaleFactor;
  const imageWidth = viewPortSize*resizeFactorWidth*scaleFactor;
  const imageHeight = viewPortSize*resizeFactorHeight*scaleFactor;
  
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      width: window.width,
      height: window.height,
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0.5, 0.5, 0.5, 0.8)',
    },
    image: {
      borderRadius: 16,
      width: imageWidth,
      height: imageHeight,
    },
  });

  function closeCarousel() {
    dispatch({
      type: CarouselActionType.CAROUSELHIDDEN,
      payload: null
    });
  };

  function nextImage() {
    dispatch({
      type: CarouselActionType.CAROUSELNEXT,
      payload: null
    });
  };

  function prevImage() {
    dispatch({
      type: CarouselActionType.CAROUSELPREV,
      payload: null
    });
  };

  return (
    <TouchableWithoutFeedback onPress={closeCarousel}>
      <View style={styles.container}>
        <TouchableWithoutFeedback onPress={() => {}}
          onPressIn={event => setTouch(event.nativeEvent.pageX)}
          onPressOut={(event) => {
            if (touch - event.nativeEvent.pageX > 20) {
              nextImage();
            } else if (event.nativeEvent.pageX - touch> 20) {
              prevImage();
            }
          }
        }
        >
          <View style={styles.image}>
            <Image
              key="image"
              source={{uri: imageMetadata.uri}}
              style={styles.image}
              resizeMode='contain'
              // onTouchStart={e=> this.touchY = e.nativeEvent.pageY}
              // onTouchEnd={e => {
              //   if (this.touchY - e.nativeEvent.pageY > 20)
              //     console.log('Swiped up')
              // }}
            />
            <CarouselClose onPress={closeCarousel}/>
            <CarouselArrow orientation='left' onPress={prevImage}/>
            <CarouselArrow orientation='right' onPress={nextImage}/>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </TouchableWithoutFeedback>
  );
};
