export default function timeAgo(date: Date) {

  const seconds: number = Math.floor((new Date().getTime() - date.getTime()) / 1000);

  let interval: number;

  interval = seconds / 31536000;
  if (interval >= 1) {
    const returnValue = Math.floor(interval);
    if (returnValue > 1) return returnValue + "y ago"
    else return returnValue + "y ago";
  }

  interval = seconds / 2592000;
  if (interval >= 1) {
    const returnValue = Math.floor(interval);
    if (returnValue > 1) return returnValue + "mo ago"
    else return returnValue + "mo ago";
  }

  interval = seconds / 86400;
  if (interval >= 1) {
    const returnValue = Math.floor(interval);
    if (returnValue > 1) return returnValue + "d ago"
    else return returnValue + "d ago";
  }
  
  interval = seconds / 3600;
  if (interval >= 1) {
    const returnValue = Math.floor(interval);
    if (returnValue > 1) return returnValue + "h ago"
    else return returnValue + "h ago";
  }
  
  interval = seconds / 60;
  if (interval >= 1) {
    const returnValue = Math.floor(interval);
    if (returnValue > 1) return returnValue + "m ago"
    else return returnValue + "m ago";
  }

  interval = seconds;
  const returnValue = Math.floor(interval);
  if (returnValue > 1) return returnValue + "s ago"
  else return returnValue + "s ago";
}
