
import { Platform } from "react-native";
import { PostType } from "../types/types";

export enum environmentType {
  LOCAL= 'LOCAL',
  STG= 'STG',
  PRD= 'PRD'
};

export enum Method {
  POST= 'POST',
  GET= 'GET',
  PUT= 'PUT',
  DELETE= 'DELETE',
};

export const environment : environmentType = environmentType.PRD; // DEFINE ENVIRONMENT HERE!

// EXPORT API PATHS
const prefix = String(environment) == environmentType.LOCAL ? '' : 'app/';
export const path = {
  login: prefix+'auth/login',
  register: prefix+'auth/register',
  resetpasswordrequest: prefix+'auth/resetpasswordrequest',
  resetpassword: prefix+'auth/resetpassword',
  refresh: prefix+'auth/refresh',
  sendactivationmail: prefix+'auth/sendactivationmail',
  users: prefix+'users',
  posts: prefix+'posts',
  events: prefix+'event',
  cars: prefix+'car',
  clubs: prefix+'club',
  business: prefix+'business',
  locationuuids: 'locationuuids',
  image: 'image',
  services: prefix+'services',
  addresses: prefix+'addresses',
  notification: prefix+'notification',
};

let tempHostname = '';
let temphostnameFrontEnd = '';
let tempGrillImageServerUrl = '';

switch(String(environment)) { 
  case environmentType.LOCAL: {
    tempHostname = Platform.OS === 'android' ? 'http://10.0.2.2:3000' : 'http://localhost:3000';
    temphostnameFrontEnd = 'http://localhost:19006/';
    tempGrillImageServerUrl= Platform.OS === 'android' ? 'http://10.0.2.2:3002' : 'http://localhost:3002';
    break;
  }
  case environmentType.STG: {
    tempHostname = 'https://stg.grill-community.com/';
    temphostnameFrontEnd = tempHostname;
    tempGrillImageServerUrl = tempHostname+path.image;
    break;
  }
  case environmentType.PRD: {
    tempHostname = 'https://grill-community.com/';
    temphostnameFrontEnd = tempHostname;
    tempGrillImageServerUrl = tempHostname+path.image;
    break;
  }
  default: {
    // not used
    break;
  }
};

export const hostname = tempHostname;
export const hostnameFrontEnd = temphostnameFrontEnd;
export const grillImageServerUrl = tempGrillImageServerUrl;

export function getPath (type: PostType) {
  switch (type) {
    case PostType.POST:
      return path.posts;
    case PostType.EVENT:
      return path.events;
    case PostType.CAR:
      return path.cars;
    case PostType.CLUB:
      return path.clubs;
    case PostType.BUSINESS:
      return path.business;
  }
};