import React, { useContext, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';

import { PostActionType, PostCommentAdded, PostSocial } from '../contexts/reducers/postListReducer';
import { FontAwesome } from '@expo/vector-icons';
import { AppContext } from '../contexts';

import { HeeboBoldTextMuted, HeeboRegularTextMuted } from './StyledText';
import { ThemedTextInput, useThemeColor, View } from './Themed';
import timeAgo from '../util/TimeAgo';
import axios from 'axios';
import { getPath, path } from '../env/env';
import { PostType } from '../types/types';

export default function SocialBarComment (props: {
  social: PostSocial,
  postId: string,
  detailMode: Boolean,
}) {

  const { state, dispatch } = useContext(AppContext);
  const [ comment, setComment ] = useState('');

  const {
    social,
    postId,
    detailMode,
  } = props;

  function handleComment () {
    if (!state.auth.uuid || comment=='') return;

    axios({
      method: 'POST',
      url: getPath(PostType.POST)+'/'+postId+'/comment/',
      data: {comment},
    })
    .then(function (response) {
      const payload : PostCommentAdded = {
        _id: response.data._id,
        postId: postId,
        userUuid: state.auth.uuid,
        userFirstName: state.user.firstName,
        userLastName: state.user.lastName,
        value: comment,
        createdAt: response.data.createdAt,
      }
      dispatch({
        type: PostActionType.POST_COMMENT_ADDED,
        payload: payload,
      });
      setComment('');
    })
    .catch(function (error) {
      console.log('Comment post failed');
    })
    .then(function () {
      // always executed
    });
  };

  function removeComment (_id: string) {
    if (!state.auth.uuid) return;

    axios({
      method: 'DELETE',
      url: getPath(PostType.POST)+'/'+postId+'/comment/'+_id,
    })
    .then(function (response) {
      dispatch({
        type: PostActionType.POST_COMMENT_DELETED,
        payload: {
          _id: _id,
          postId: props.postId,
        },
      });
    })
    .catch(function (error) {
      console.log('Comment delete failed');
    })
    .then(function () {
      // always executed
    });
  };

  const styles = StyleSheet.create({
    horizontal: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
    },
    socialBarBubble: {
      color: useThemeColor({}, 'darkText'),
      paddingHorizontal: 10,
      maxWidth: '100%',
    },
    socialBarComment: {
      color: useThemeColor({}, 'textmuted'),
      paddingHorizontal: 10,
    },
    socialBarAuthor: {
      marginHorizontal: 10,
    },
    commentInput: {
      textAlign: 'left',
      backgroundColor: useThemeColor({}, 'bluelight'),
      color: useThemeColor({}, 'text'),
      marginVertical: 10,
      paddingVertical: 2,
      paddingHorizontal: 10,
    },
    removeComment: {
      color: useThemeColor({}, 'textmuted'),
    }
  });

  const maxCommentsInListMode = detailMode ? 500 : 2;
  const comments = social.comments.length;

  return (
    <View>
      {social?.comments.slice(0, maxCommentsInListMode).map((comment, index) => {
        const timeAgoString = comment.createdAt ? timeAgo(new Date(comment.createdAt)) : ''; // type Date turned into String somehow
        return <View key={'PostComment'+props.postId+'-'+index} style={styles.horizontal}>
          <HeeboBoldTextMuted style={styles.socialBarBubble}>{comment.value}</HeeboBoldTextMuted>
          <HeeboRegularTextMuted style={styles.socialBarAuthor}>{comment.userFirstName} {comment.userLastName} - {timeAgoString}&nbsp;
            {comment.userUuid==state.auth.uuid && <HeeboRegularTextMuted style={styles.socialBarAuthor}>
              <TouchableOpacity onPress={() => removeComment(comment._id)}>
                <FontAwesome style={styles.removeComment} name='remove' />
              </TouchableOpacity></HeeboRegularTextMuted>
            }
          </HeeboRegularTextMuted>
        </View>;
      })}
      {!detailMode && comments>2 && <Text style={styles.socialBarComment}>Read all {comments} comments</Text>}
      <View style={styles.horizontal}>
        <ThemedTextInput style={styles.commentInput} editable={state.auth.uuid==''? false : true} onSubmitEditing={handleComment} onChangeText={comment => setComment(comment)} placeholder={state.auth.uuid=='' ? 'Log in to comment' : comments==0 ? 'Be the first to comment' : 'Add a comment'} value={comment} />
      </View>
    </View>
  );
};
