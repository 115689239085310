type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      }
};

export enum PostListRefreshActionType {
  POSTS_REFRESH = 'POSTS_REFRESH',
  POSTS_REFRESHED = 'POSTS_REFRESHED',
}

type PostListRefreshActionPayload = {
  [PostListRefreshActionType.POSTS_REFRESH]: null;
  [PostListRefreshActionType.POSTS_REFRESHED]: null;
};

export type PostListRefreshAction = ActionMap<PostListRefreshActionPayload>[keyof ActionMap<
  PostListRefreshActionPayload
>];

export type PostListRefreshStateType = {
  refreshing: boolean,
}

export const initialPostListRefreshState = {
  refreshing: false,
};

export const postListRefreshReducer = (
  state: PostListRefreshStateType,
  action: PostListRefreshAction
) => {
  switch (action.type) {
    case PostListRefreshActionType.POSTS_REFRESH:
      return {refreshing: true};
    case PostListRefreshActionType.POSTS_REFRESHED:
      return {refreshing: false};
    default:
      return state;
  }
};
