import { StyleSheet, Animated, TouchableWithoutFeedback, Image, GestureResponderEvent } from 'react-native';
import { useEffect, useState } from 'react';
import React from 'react';

import { useThemeColor } from './Themed';
import { FontAwesome } from '@expo/vector-icons';

const CLOSE_HITSLOP = {
  left: 10,
  top: 10,
  right: 10,
  bottom: 10,
};

export default function PostDetailCardClose (props:{
  cardAnimation: Animated.Value,
  onPress: (event: GestureResponderEvent) => void,
}) {

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      right: 10,
      top: 10,
      justifyContent: 'center',
      alignItems: 'center',
      // opacity: 0.5,
      // backgroundColor: useThemeColor({}, 'text'),
      borderRadius: 14,
      width: 24,
      height: 24,
    },
    buttonIcon: {
      color: useThemeColor({}, 'alt2dark'),
      fontSize: 24,
    },
  });

  const [containerStyle, setContainerStyle] = useState([
    styles.container,
    {},
  ]);

  useEffect(() => {
    setContainerStyle([
      styles.container,
      {
        opacity: props.cardAnimation,
      },
    ]);
  }, []);
  
  return (
    <TouchableWithoutFeedback onPress={props.onPress} hitSlop={CLOSE_HITSLOP}>
      <Animated.View style={containerStyle}>
        <FontAwesome style={styles.buttonIcon} name='times-circle' />
      </Animated.View>
    </TouchableWithoutFeedback>
  );
};
