import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, TextInput, TouchableOpacity } from 'react-native';

import { useThemeColor, View } from '../components/Themed';
import { RootStackScreenProps } from '../types';
import { HeeboBoldText, HeeboBoldTextTiny, HeeboRegularText } from '../components/StyledText';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import axios from 'axios';
import { path } from '../env/env';
import { NotificationToken, Service } from '../types/types';
import ServiceIcon from '../components/ServiceIcon';

export default function AdminScreen({ navigation, route }: RootStackScreenProps<'Admin'>) {

  const styles = StyleSheet.create({
    container: {
      paddingHorizontal: 20,
    },
    content: {
      flex: 1,
      gap: 10,
    },
    button: {
      borderColor: 'gray',
      borderWidth: 2,
      borderRadius: 34,
      textAlign: 'center',
    },
    textinput: {
      color: useThemeColor({}, 'text'),
      borderColor: 'gray',
      borderWidth: 1,
      textAlign: 'center',
    },
    horizontal: {
      flexDirection: 'row',
      gap: 5,
    },
  });

  const [ services, setServices ] = useState<Service[]>([]);
  const [ tempService, setTempService ] = useState<Service|null>(null);
  const [ notificationTokens, setNotificationTokens ] = useState<NotificationToken[]>([]);
  const [ alert, setAlert ] = useState({
    title: '',
    body: '',
  });
  const supportedLanguages=['nl', 'fr', 'en'];

  useEffect(() => {
    if (notificationTokens.length==0) fetchNotificationTokes();
  }, [notificationTokens]);

  useEffect(() => {
    fetchServices();
  }, []);

  function fetchNotificationTokes () {
    axios({
      method: 'GET',
      url: path.notification,
    })
    .then(function (response) {
      setNotificationTokens(response.data.tokens);
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  };

  function deleteToken (_id: string) {
    axios({
      method: 'DELETE',
      url: path.notification+'/'+_id,
    })
    .then(function (response) {
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {
      // always executed
    });
    setNotificationTokens([]);
  };

  function notifyOne (token: NotificationToken) {
    axios({
      method: 'POST',
      data: {
        notificationToken: token,
        alert: alert,
      },
      url: path.notification+'/notifyone',
    })
    .then(function (response) {
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  };

  function notifyAll () {
    axios({
      method: 'POST',
      data: {
        alert: alert,
      },
      url: path.notification+'/notifyall',
    })
    .then(function (response) {
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  };

  function fetchServices () {
    axios({
      method: 'GET',
      url: path.services,
    })
    .then(function (response) {
      setServices(response.data.items);
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  };

  function addService () {
    const newService : Service = {
      uuid: '',
      iconLibrary: '',
      iconName: '',
      fontSize: 0,
      name: {},
    };
    supportedLanguages.map(language => {
      newService.name.push({language: language, name: ''});
    })
    setTempService(newService);
  };

  function saveService () {
    axios({
      method: 'POST',
      url: path.services,
      data: tempService,
    })
    .then(function (response) {
      setServices(response.data.items);
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {
      // always executed
    });
    setTempService(null);
  };

  function deleteService (uuid: string) {
    axios({
      method: 'DELETE',
      url: path.services+'/'+uuid,
    })
    .then(function (response) {
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {
      // always executed
    });
    setTempService(null);
  };

  function getComponents() {
    const components: JSX.Element[] = [];

    components.push(<View key={'notificationForm'}>
      <HeeboRegularText key={'notificationFormTitle'}>Send notification</HeeboRegularText>
      <TextInput
        key={'notificationFormTitle'}
        style={styles.textinput}
        onChangeText={text => setAlert({...alert,  title: text})}
        value={alert.title}
        placeholder={'title'}
      />
      <TextInput
        key={'notificationFormBody'}
        style={styles.textinput}
        onChangeText={text => setAlert({...alert,  body: text})}
        value={alert.body}
        placeholder={'body'}
      />
      <TouchableOpacity key='notifyAll' onPress={notifyAll}>
        <HeeboBoldText style={styles.button}>Notify All</HeeboBoldText>
      </TouchableOpacity>
    </View>);

    components.push(<HeeboBoldText key='notification-title'>Notification tokens</HeeboBoldText>);
    notificationTokens.map(token => {
      components.push(<HeeboBoldTextTiny key={'notification-token-'+token.token}>{token.user} {token.platform} {token.token}</HeeboBoldTextTiny>);
      components.push(<TouchableOpacity key={'del-not-tok'+token.token} onPress={()=>deleteToken(token._id)}><HeeboBoldTextTiny>Delete</HeeboBoldTextTiny></TouchableOpacity>);
      components.push(<TouchableOpacity key={'notify'+token.token} onPress={()=>notifyOne(token)}><HeeboBoldTextTiny>Notify</HeeboBoldTextTiny></TouchableOpacity>);
    });

    components.push(<HeeboBoldText key='service-title'>Services</HeeboBoldText>);
    services.map(item => {
      components.push(<ServiceIcon
        key={'service-icon-'+item.uuid}
        active={true}
        setService={()=>{}}
        service={item}
        serviceUuid={item.uuid}
      />);
      components.push(<TouchableOpacity key={'service-'+item.uuid+'delete'} onPress={()=>deleteService(item.uuid)}>
        <HeeboBoldText style={styles.button}>Delete</HeeboBoldText>
      </TouchableOpacity>);
    })

    components.push(<TouchableOpacity key='service-add' onPress={addService}>
      <HeeboBoldText style={styles.button}>Add doesn't work yet</HeeboBoldText>
    </TouchableOpacity>);

    if (tempService) {
      tempService.name.map(language=>{
        components.push(<View key={'service-name-language'+language.language} style={styles.horizontal}>
          <HeeboRegularText key={'service-name-language-label'+language.language}>{'Name '+language.language}</HeeboRegularText>
          <TextInput
            key={'service-name-language-input'+language.language}
            style={styles.textinput}
            onChangeText={text => setTempService({...tempService,  name: {...tempService.name, [language.language]: text}})}
            value={tempService.name.find(item => item.language==language.language)?.name}
            placeholder={'name '+language.language}
          />
        </View>);
      });

      components.push(<TouchableOpacity key='save-service' onPress={saveService}>
        <HeeboBoldText style={styles.button}>Save</HeeboBoldText>
      </TouchableOpacity>);

      components.push(<TouchableOpacity key='cancel-service' onPress={()=>setTempService(null)}>
        <HeeboBoldText style={styles.button}>Cancel</HeeboBoldText>
      </TouchableOpacity>);
    }
    
    return components;
  };

  const components = getComponents();
  
  return (
    <SafeAreaProvider>
      <ScrollView contentContainerStyle={styles.container}>
        <View style={styles.content}>
          {components}
        </View>
      </ScrollView>
    </SafeAreaProvider>
  );
}
