import { Platform, StyleSheet, Image, TouchableOpacity } from "react-native";
import { View } from "./Themed";
import * as WebBrowser from 'expo-web-browser';
import { HeeboRegularTextMuted } from "./StyledText";

export function PostListHeader() {
  
  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      paddingBottom: 15,
    },
    topText: {
      paddingTop: 5,
      textAlign: 'center',
    },
  });
  
  if (Platform.OS === 'web') {
    return (
      <View>
        <View>
          <HeeboRegularTextMuted style={styles.topText}>GRILL is optimized for mobile</HeeboRegularTextMuted>
        </View>
        <View style={styles.container}>
          <TouchableOpacity onPress={() => WebBrowser.openBrowserAsync('https://apps.apple.com/be/app/grill/id1642967441')}>
            <Image
              source={require('../assets/images/app-store.png')}
              style={{
                marginHorizontal: 5,
                width: 138,
                height: 46,
              }}
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => WebBrowser.openBrowserAsync('https://play.google.com/store/apps/details?id=com.defreynepeter.Grill')}>
            <Image
              source={require('../assets/images/play-store.png')}
              style={{
                marginHorizontal: 5,
                width: 154,
                height: 46,
              }}
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  } else {
    return null;
  };
};
