import React, { createContext, useReducer, Dispatch } from "react";

import {
  CarouselStateType,
  initialCarouselState,
  CarouselAction,
  carouselReducer,
} from "./reducers/carouselReducer";

import {
  AlertStateType,
  initialAlertState,
  AlertAction,
  alertReducer,
} from "./reducers/alertReducer";

import {
  AuthStateType,
  initialAuthState,
  AuthAction,
  authReducer,
} from "./reducers/authReducer";

import {
  UserStateType,
  initialUserState,
  UserAction,
  userReducer,
} from "./reducers/userReducer";

import {
  PostListStateType,
  initialPostListState,
  PostAction,
  postListReducer,
} from "./reducers/postListReducer";

import {
  BusinessListStateType,
  initialBusinessListState,
  BusinessAction,
  businessListReducer,
} from "./reducers/businessListReducer";

import {
  EventListStateType,
  initialEventListState,
  EventAction,
  eventListReducer,
} from "./reducers/eventListReducer";

import {
  ClubListStateType,
  initialClubListState,
  ClubAction,
  clubListReducer,
} from "./reducers/clubListReducer";

import {
  PostListRefreshStateType,
  initialPostListRefreshState,
  PostListRefreshAction,
  postListRefreshReducer,
} from "./reducers/postListRefreshReducer";

import {
  BusinessListRefreshStateType,
  initialBusinessListRefreshState,
  BusinessListRefreshAction,
  businessListRefreshReducer,
} from "./reducers/businessListRefreshReducer";

import {
  EventListRefreshStateType,
  initialEventListRefreshState,
  EventListRefreshAction,
  eventListRefreshReducer,
} from "./reducers/eventListRefreshReducer";

import {
  ClubListRefreshStateType,
  initialClubListRefreshState,
  ClubListRefreshAction,
  clubListRefreshReducer,
} from "./reducers/clubListRefreshReducer";

import {
  PostDetailStateType,
  initialPostDetailState,
  PostDetailAction,
  postDetailReducer,
} from "./reducers/postDetailReducer";

import {
  BusinessDetailStateType,
  initialBusinessDetailState,
  BusinessDetailAction,
  businessDetailReducer,
} from "./reducers/businessDetailReducer";

import {
  EventDetailStateType,
  initialEventDetailState,
  EventDetailAction,
  eventDetailReducer,
} from "./reducers/eventDetailReducer";

import {
  ClubDetailStateType,
  initialClubDetailState,
  ClubDetailAction,
  clubDetailReducer,
} from "./reducers/clubDetailReducer";

type InitialStateType = {
  carousel: CarouselStateType,
  alert: AlertStateType,
  auth: AuthStateType,
  user: UserStateType,
  postList: PostListStateType,
  businessList: BusinessListStateType,
  eventList: EventListStateType,
  clubList: ClubListStateType,
  postListRefresh: PostListRefreshStateType,
  businessListRefresh: BusinessListRefreshStateType,
  eventListRefresh: ClubListRefreshStateType,
  clubListRefresh: ClubListRefreshStateType,
  postDetail: PostDetailStateType,
  businessDetail: BusinessDetailStateType,
  eventDetail: EventDetailStateType,
  clubDetail: ClubDetailStateType,
};

const initialState = {
  carousel: initialCarouselState,
  alert: initialAlertState,
  auth: initialAuthState,
  user: initialUserState,
  postList: initialPostListState,
  businessList: initialBusinessListState,
  eventList: initialEventListState,
  clubList: initialClubListState,
  postListRefresh: initialPostListRefreshState,
  businessListRefresh: initialBusinessListRefreshState,
  eventListRefresh: initialEventListRefreshState,
  clubListRefresh: initialClubListRefreshState,
  postDetail: initialPostDetailState,
  businessDetail: initialBusinessDetailState,
  eventDetail: initialEventDetailState,
  clubDetail: initialClubDetailState,
};

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<CarouselAction | AlertAction | AuthAction | UserAction | PostAction | BusinessAction | EventAction | ClubAction | PostListRefreshAction | BusinessListRefreshAction | EventListRefreshAction | ClubListRefreshAction | PostDetailAction | BusinessDetailAction | EventDetailAction | ClubDetailAction >;
}>({
  state: initialState,
  dispatch: () => null,
});

const mainReducer = (
  { carousel, alert, auth, user, postList, businessList, eventList, clubList, postListRefresh, businessListRefresh, eventListRefresh, clubListRefresh, postDetail, businessDetail, eventDetail, clubDetail }: InitialStateType,
  action: CarouselAction | AlertAction | AuthAction | UserAction | PostAction | BusinessAction | EventAction | ClubAction | PostListRefreshAction | BusinessListRefreshAction | EventListRefreshAction | ClubListRefreshAction | PostDetailAction | BusinessDetailAction | EventDetailAction | ClubDetailAction,
) => ({
  carousel: carouselReducer(carousel, action),
  alert: alertReducer(alert, action),
  auth: authReducer(auth, action),
  user: userReducer(user, action),
  postList: postListReducer(postList, action),
  businessList: businessListReducer(businessList, action),
  eventList: eventListReducer(eventList, action),
  clubList: clubListReducer(clubList, action),
  postListRefresh: postListRefreshReducer(postListRefresh, action),
  businessListRefresh: businessListRefreshReducer(businessListRefresh, action),
  eventListRefresh: eventListRefreshReducer(eventListRefresh, action),
  clubListRefresh: clubListRefreshReducer(clubListRefresh, action),
  postDetail: postDetailReducer(postDetail, action),
  businessDetail: businessDetailReducer(businessDetail, action),
  eventDetail: eventDetailReducer(eventDetail, action),
  clubDetail: clubDetailReducer(clubDetail, action),
});

// Before React 18 children props were automatically included in the FC interface. Now it seems I have to manually add children: ReactNode. https://stackoverflow.com/questions/71788254/react-18-typescript-children-fc
// Old version: const AppProvider: React.FC = ({ children }) => {
type Props = {
  children?: React.ReactNode
};
const AppProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };
