import { PixelRatio } from 'react-native';
import usePlatform, { imagePadding, PlatformStateType } from './usePlatform';

export type CardSize = {
  cardWidth: number,
  cardHeight: number,
};

export const emptyCardSize : CardSize = {
  cardWidth: 0,
  cardHeight: 0,
};

export default function useCardSize(width: number, height: number, padding: boolean) {

  const platform = usePlatform();

  return useCardSizeNotAHookVersion(platform, width, height, padding);
}

export function useCardSizeNotAHookVersion(platform: PlatformStateType, width: number, height: number, padding: boolean) {

  const paddingCard = !padding || platform.maxWidthReached ? 0 : (imagePadding * 2);
  const cardWidth = platform.windowWidth - paddingCard;
  const cardHeight = width==0 ? 0 : PixelRatio.roundToNearestPixel((height * cardWidth) / width); // avoid dividing by zero

  return {
    cardWidth,
    cardHeight,
  };
}
