import { StyleSheet } from 'react-native';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { BackgroundGradient, View } from '../components/Themed';
import { HeeboBoldText, HeeboBoldTextLarge, HeeboRegularTextMuted } from '../components/StyledText';
import { WhiteButton } from '../components/WhiteButton';
import { RootStackScreenProps } from '../types';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { CommonActions } from '@react-navigation/native';
import { path } from '../env/env';


export default function ActivateAccountScreen({ navigation, route }: RootStackScreenProps<'Activate'>) {
  const [ activated, setActivated ] = useState(false);
  const [ error, setError ] = useState(false);

  // Add back button when coming here directly (empty navigation history)
  useEffect(
    () => {
      if (navigation.getState().routes.length>1) return;

      navigation.dispatch(navigationState => {
        const currentNavigationState = navigationState; // This can also directly be accessed with navigation.getState()
    
        const routes = [{name: 'GRILL'}, ...currentNavigationState.routes];
    
        const newNavigationState = {
          ...navigationState,
          routes,
          index: routes.length - 1,
        };
      
        return CommonActions.reset(newNavigationState);
      });
    }, [],
  );

  useEffect(
    () => {
      if (!route.params || !route.params.uuid || !route.params.verifyString) {
        setError(true);
        return;
      }

      axios({
        method: 'GET',
        url: path.users+'/'+route.params.uuid+'/verify/'+route.params.verifyString,
      })
      .then(function (response) {
        setActivated(true);
        setError(false);
      })
      .catch(function (error) {
        setError(true);
        console.log(error);
      })
      .then(function () {
        // always executed
      });
    }, [],
  );

  return (
    <SafeAreaProvider>
      <BackgroundGradient />
      <View style={styles.container}>
        {!activated && !error && <HeeboBoldTextLarge style={styles.center}>Activating account...</HeeboBoldTextLarge>}
        {activated && <HeeboBoldTextLarge style={styles.center}>Activated!</HeeboBoldTextLarge>}
        {activated && <HeeboBoldText style={styles.center}>You can now log in to GRILL</HeeboBoldText>}
        {activated && <WhiteButton onPress={() => navigation.navigate('GRILL')} text='To the feed!' />}
        {error && <HeeboBoldTextLarge style={styles.center}>Your account could not be activated</HeeboBoldTextLarge>}
        {error && <HeeboRegularTextMuted style={styles.center}>If this problem persists, please contact info@grill-community.com</HeeboRegularTextMuted>}
      </View>
    </SafeAreaProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  center: {
    textAlign: 'center',
  },
});
