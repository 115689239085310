type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      }
};

// todo
export enum BusinessListRefreshActionType {
  BUSINESS_REFRESH = 'BUSINESS_REFRESH',
  BUSINESS_REFRESHED = 'BUSINESS_REFRESHED',
}

type BusinessListRefreshActionPayload = {
  [BusinessListRefreshActionType.BUSINESS_REFRESH]: null;
  [BusinessListRefreshActionType.BUSINESS_REFRESHED]: null;
};

export type BusinessListRefreshAction = ActionMap<BusinessListRefreshActionPayload>[keyof ActionMap<
  BusinessListRefreshActionPayload
>];

export type BusinessListRefreshStateType = {
  refreshing: boolean,
}

export const initialBusinessListRefreshState = {
  refreshing: false,
};

export const businessListRefreshReducer = (
  state: BusinessListRefreshStateType,
  action: BusinessListRefreshAction
) => {
  switch (action.type) {
    case BusinessListRefreshActionType.BUSINESS_REFRESH:
      return {refreshing: true};
    case BusinessListRefreshActionType.BUSINESS_REFRESHED:
      return {refreshing: false};
    default:
      return state;
  }
};
