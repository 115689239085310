type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      }
};

export type AuthStateType = {
  __act: string,
  __rt: string,
  uuid: string,
};

export const initialAuthState : AuthStateType = {
  __act: '',
  __rt: '',
  uuid: '',
};

export enum AuthActionType {
  LOGGED_IN = 'LOGGED_IN',
  TOKENS_REFRESHED = 'TOKENS_REFRESHED',
  LOGGED_OUT = 'LOGGED_OUT',
  RESET_PW_SENT = 'RESET_PW_SENT',
  ACTIVATION_MAIL_SENT = 'ACTIVATION_MAIL_SENT',
}

type AuthActionPayload = {
  [AuthActionType.LOGGED_IN]: AuthStateType;
  [AuthActionType.TOKENS_REFRESHED]: AuthStateType;
  [AuthActionType.LOGGED_OUT]: AuthStateType;
  [AuthActionType.RESET_PW_SENT]: AuthStateType;
  [AuthActionType.ACTIVATION_MAIL_SENT]: AuthStateType;
};

export type AuthAction = ActionMap<AuthActionPayload>[keyof ActionMap<
  AuthActionPayload
>];

export const authReducer = (
  state: AuthStateType,
  action: AuthAction
) => {
  switch (action.type) {
    case AuthActionType.LOGGED_IN:
      return action.payload;
    case AuthActionType.TOKENS_REFRESHED:
      return action.payload;
    case AuthActionType.LOGGED_OUT:
      return {...initialAuthState}; // needs a copy, not the original reference spread operator creates a copy! cfr. immutable
    case AuthActionType.RESET_PW_SENT:
      return {...initialAuthState};
    case AuthActionType.ACTIVATION_MAIL_SENT:
      return {...initialAuthState};
    default:
      return state;
  }
};
