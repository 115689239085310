import { StyleSheet, Animated, TouchableWithoutFeedback } from 'react-native';
import React from 'react';

import { useThemeColor } from './Themed';
import { FontAwesome } from '@expo/vector-icons';

const CLOSE_HITSLOP = {
  left: 10,
  top: 10,
  right: 10,
  bottom: 10,
};

export default function CarouselClose (props:{
  onPress: () => void,
}) {

  const styles = StyleSheet.create({
    container: {
      position: 'absolute',
      right: 10,
      top: 10,
      justifyContent: 'center',
      alignItems: 'center',
      // opacity: 0.5,
      // backgroundColor: useThemeColor({}, 'text'),
      borderRadius: 14,
      width: 20,
      height: 20,
    },
    buttonIcon: {
      color: useThemeColor({}, 'alt2dark'),
      fontSize: 24,
    },
  });
  
  return (
    <TouchableWithoutFeedback onPress={props.onPress} hitSlop={CLOSE_HITSLOP}>
      <Animated.View style={styles.container}>
        <FontAwesome style={styles.buttonIcon} name='times-circle' />
      </Animated.View>
    </TouchableWithoutFeedback>
  );
};
